<template>
  <div>
    <Header></Header>
    <div class="news">
      帮助中心
      <AiPop @change="showDialog"></AiPop>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Footer from "@/components/Footer.vue";
import AiPop from "@/components/AiPop.vue";
import Header from "@/components/Header.vue";
import { shareUrl } from "@/utils/vxshare.js";

export default {
  name: "help",

  props: [],

  components: { Header, AiPop, Footer },

  data() {
    return {
      dialogVisible: false,
    };
  },

  methods: {
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
  },

  mounted() {
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
};
</script>


<style type="text/scss" lang="scss" scoped>
</style>
