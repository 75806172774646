<template>
  <div>
    <div v-if="isMobile === false">
      <div class="MySubscriptions wrapper">
        <keep-alive>
          <Header></Header>
        </keep-alive>

        <div class="top">
          <!--      问答-->
          <div class="flexBox">
            <div class="searchBox">

              <el-input
                v-model="seachInput"
                class="searchContent"
                @keyup.enter.native="Search"
                placeholder="请输入职位关键词进行搜索"
              ></el-input>
              <el-button class="searchTo" @click="Search">搜索 </el-button>
            </div>

            <div class="scanEr">
              <Erweima></Erweima>
            </div>
          </div>
          <!--      筛选条件-->
          <div class="filter">
            <!--        城市-->
            <div class="city">
              <div class="main">工作地点：</div>
              <div class="name">
                <li v-for="(citys, index) in cityName" :key="index">
                  <span
                    :class="[citys.selected ? 'active' : '', 'citys']"
                    class="cityName"
                    @click="selectCity(citys)"
                    >{{ citys.content }}</span
                  >
                </li>
                <span
                  class="cityName"
                  style="margin-top: 1px"
                  @click="cityOtherthis"
                >
                  其他</span
                >
              </div>
            </div>
            <div class="spanStyle"></div>
            <!--        薪资-->
            <div class="city">
              <div class="main">月薪范围：</div>
              <div class="name">
                <li v-for="(salarys, index) in salaryName" :key="index">
                  <span
                    :class="[salarys.selected ? 'active' : '', 'salarys']"
                    class="cityName"
                    @click="selectSalary(salarys)"
                  >
                    {{ salarys.content }}</span
                  >
                </li>
                <span
                  class="cityName"
                  style="margin-top: 1px"
                  v-if="otherSalary === false"
                  @click="otherSalarys"
                >
                  其他范围</span
                >
                <span class="cityName" v-else>
                  <input
                    type="text"
                    class="red-border-input"
                    v-model="positionSalary1"
                    placeholder="其他"
                  />
                  -
                  <input
                    type="text"
                    class="red-border-input"
                    v-model="positionSalary2"
                    placeholder="其他"
                    @input="Salary(positionSalary2)"
                  />&nbsp;&nbsp;k</span
                >
              </div>
            </div>
            <div class="spanStyle"></div>
            <!--        学历经验-->
<!--            <div class="city">-->
<!--              <div class="main">学历要求：</div>-->
<!--              <div class="name">-->
<!--                <li v-for="(educations, index) in educationName" :key="index">-->
<!--                  <span-->
<!--                    :class="[educations.selected ? 'active' : '', 'educations']"-->
<!--                    class="cityName"-->
<!--                    @click="selectEducation(educations)"-->
<!--                    >{{ educations.content }}</span-->
<!--                  >-->
<!--                </li>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="spanStyle"></div>-->
            <!--        工作经验-->
            <div class="city">
              <div class="main2">工作年限：</div>
              <div class="name2">
                <li v-for="(times, index) in timeName" :key="index">
                  <span
                    :class="[times.selected ? 'active' : '', 'times']"
                    class="cityName"
                    @click="selectTime(times)"
                    >{{ times.content }}</span
                  >
                </li>
              </div>
            </div>
            <div class="spanStyle"></div>
            <!--        其他-->
            <div class="city" style="margin-top: 25px">
              <div class="main2">其他要求：</div>

              <div class="name2">
<!--                学历-->
                <div class="titleInput" style="width: 135px">
                  <el-select
                      v-model="positionEducation"
                      placeholder="学历"
                      @change=" positionpositionEducation()"
                  >
                    <el-option
                        v-for="item in educationName"
                        :key="item.id"
                        :label="item.content"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
<!--                行业-->
                <div class="titleInput">
                    <el-cascader
                        :options="category"
                        v-model="positionCategory"
                        placeholder="行业"
                        @change=" positionpositionCategory()"
                        :show-all-levels="false"></el-cascader>

                </div>
                <!--                企业规模-->
                <div class="titleInput" style="width: 135px">
                  <el-select
                      v-model="companyScale"
                      placeholder="企业规模"
                      @change="positioncompanyScale()"
                  >
                    <el-option
                        v-for="item in scale"
                        :key="item.id"
                        :label="item.companyScale"
                        :value="item.companyScale"
                    >
                    </el-option>
                  </el-select>
                </div>
<!--                海外工作经历-->
                <div class="titleInput" style="width: 135px">
                  <el-select
                      v-model="positionOverseas"
                      placeholder="海外工作经历"
                      @change=" positionpositionOverseas()"
                  >
                    <el-option
                        v-for="item in workOut"
                        :key="item.outId"
                        :label="item.outContent"
                        :value="item.outId"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <!--          与选择条件-->
            <div v-if="tags.length > 0" class="select">
              <!--          <div class="select">-->
              <div class="name">已选条件：</div>
              <div style="width: 85%;margin-bottom: 5px">
                <el-tag
                  v-for="tag in tags"
                  :key="tag.name"
                  :disable-transitions="false"
                  :type="tag.type"
                  class="tagStyle"
                  closable
                  @close="handleClose(tag)"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
              <div class="clear" @click="clear()">清除筛选条件</div>
            </div>
          </div>
        </div>

        <div
          style="display: flex;width: 1200px;margin: 0 auto;position: relative;">
          <div class="middle">
            <div v-if="loading===false">
              <div style="min-height: 45vh">
                <li v-for="(lists, index) in list" :key="index">
                  <div class="listTotle" @click="personal(lists)">
                    <div class="detail">
                      <div>
                        <div>
                      <span class="positionName">
                        {{ lists.positionName }}【{{ lists.positionAddress }}】</span
                      >
                          <span class="salaryName">
                        {{ lists.positionSalary }}</span
                          >
                        </div>
                        <div class="jobStyle">
                          <span class="jobStyle1">{{ lists.positionTime }}</span>
                          <span class="jobStyle2">{{lists.positionEducation }}</span>
                          <span class="jobStyle2">{{lists.positionCategory}}</span>
                        </div>
                      </div>

                      <div class="comButton">
                        <div>
                          <el-avatar :size="45" :src="baseURL +lists.interviewAvatar"></el-avatar>
                        </div>
                        <el-button class="companyBut"
                        >立即沟通</el-button
                        >
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="comLogo">
                        <img :src="baseURL + lists.companyAvatar" alt="" />
                      </div>
                      <div class="companyName">
                        <div class="name1">{{ lists.companyName }}</div>
                        <div class="spanthis" style="margin-right: 15px"></div>
                        <div style="display: flex" v-for="(item, index) in lists.companyIndustry" :key="item.id">
                          <div class="name3">{{ item }}</div>
                          <div class="name3" style="margin:0;" v-if="index < lists.companyIndustry.length - 1">、</div>
                        </div>

                        <div class="name2">{{ lists.companyScale }}</div>
                      </div>
                    </div>
                  </div>
                </li>

                <div v-if="list.length === 0" class="pagination-container">
                  <div style="width: 150px;height: auto;font-size: 16px;margin: 0 auto;">
                    <img loading="lazy" src="../../assets/none.png" alt="" />
                  </div>
                  <div style="color: black; line-height: 36px; font-size: 18px">
                    暂时没有符合该搜索条件的职位
                  </div>
                  <div style="color: gray; line-height: 36px">
                    请重新修改搜索条件后再进行搜索
                  </div>
                </div>

              </div>
              <!--        分页-->
              <div v-if="list.length !== 0" class="pagination-container">
                <el-pagination
                    v-model="page"
                    :current-page="page"
                    :page-size="size"
                    :page-sizes="[5, 8, 10, 15]"
                    :total="total"
                    background
                    layout=" prev, pager, next"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
            <div v-else class="pagination-container">
              <div class="shake">
                <img  src="../../assets/loading.png" alt="" />
              </div>
              <div id="animatedText" style="line-height: 36px; font-size: 16px;text-align: center">
                {{ displayedText }}
              </div>
            </div>

          </div>
          <div>
            <div class="jianli" v-if="userId">
              <PersonalLittle></PersonalLittle>
            </div>
            <div class="recommend2" v-else>
              <div @click="showLoginIn1()" class="advertisement">
              </div>
              <div @click="showLoginIn()" class="advertisement2" >
              </div>
            </div>
          </div>
        </div>

        <LoginTip
          :dialogVisibleLogin="dialogVisible"
          @hideDialog="hideDialog"
        ></LoginTip>
        <InfoTip
          :dialogVisibleInfo="dialogVisibleInfo"
          @hideDialog="hideDialogInfo"
        ></InfoTip>
        <LoginIn
          :dialogVisible="loginIn"
          @hideDialog="hideLoginIn"
          @register="showRegister"
          @forget="forget"
          @logsuccess="logsuccess"
        ></LoginIn>
        <RegisterIn
          :dialogVisible="registerIn"
          @hideDialog="hideregisterIn"
          @login="login"
        ></RegisterIn>
        <forgetIn
          :dialogVisible="forgetIn"
          @hideDialog="hidereforgetIn"
          @login="login"
        ></forgetIn>
    <cityAddress  :dialogVisible="cityIn" @cityOther="cityOther"  @hideDialog="cityHiden"> </cityAddress>
      </div>
    </div>
    <div v-if="isMobile === true" class="mobile" >
      <div class="MySubscriptions wrapper">
        <div class="top1">
          <keep-alive>
            <Header></Header>
          </keep-alive>
          <!--      问答-->
          <div class="flexBox">

              <el-popover
                  placement="bottom"
                  width="100vw"
                  trigger="click">
                <div style="z-index: 9;width:90vw;background-color: rgba(102,102,102,0.9);min-height: fit-content;padding: 15px 20px 15px 25px">
                  <div  v-if="userSearch.length!==0">
                    <div  style="display: flex;color: white;justify-content: space-between;font-size: 14px">
                      历史记录
                      <el-popover
                          placement="bottom"
                          width="160"
                          style="z-index: 99999999999;font-size: 12px"
                          v-model="visible">
                        <p style="font-size: 12px">确定要删除所有历史记录吗？</p>
                        <div style="text-align: right; margin: 0;">
                          <el-button style="font-size: 12px" size="mini" type="text" @click="visible = false">取消</el-button>
                          <el-button  style="font-size: 12px" type="primary" size="mini" @click="searchDelete()">确定</el-button>
                        </div>
                        <i slot="reference" style="font-size: 17px" class="el-icon-delete"></i>
                      </el-popover>

                    </div>
                    <div style="list-style: none;margin-top: 15px;display: flex;height: fit-content;flex-wrap:wrap" >
                      <li v-for="(lists, index) in userSearch" :key="index">
                        <div style="font-size:12px;background: white;padding: 8px 10px;width: fit-content;margin:5px;border-radius: 8px" @click="changeKey(lists.content)">
                          {{lists.content}}
                        </div>
                      </li>
                    </div>

                  </div>


                  <div style="margin-top:15px;display: flex;color: white;justify-content: space-between;font-size: 14px">
                    热门搜索
                  </div>
                  <div style="list-style: none;margin-top: 15px;display: flex;flex-wrap:wrap" >
                    <li v-for="(lists, index) in hotSearch" :key="index">
                      <div style="font-size:12px;background: white;padding: 8px 10px;width: fit-content;margin:5px;border-radius: 8px" @click="changeKey(lists.content)">
                        {{lists.content}}
                      </div>
                    </li>
                  </div>
                </div>

                <div slot="reference" class="searchBox">
                <el-input
                    v-model="seachInput"
                    class="searchContent"
                    @focus="changeSearch"
                    @keyup.enter.native="Search"
                    placeholder="请输入职位关键词进行搜索"
                ></el-input>
                <el-button class="searchTo" @click="Search">搜索 </el-button>
                </div>

              </el-popover>


          </div>

          <!--      筛选条件-->
          <div class="filter">
            <!--               城市-->
            <div class="titleInput" style="width: 75px">
              <el-cascader
                  :options="provinces"
                  v-model="positionAddressShow"
                  style="width: 100%"
                  placeholder="城市"
                  @change="City(positionAddressShow)"
                  :show-all-levels="false"></el-cascader>
            </div>
            <!--               经验-->
            <div class="titleInput" style="width: 75px">
              <el-select
                  v-model="positionTimeshow"
                  placeholder="经验"
                  @change=" Time(positionTimeshow)"
              >
                <el-option
                    v-for="item in timeName"
                    :key="item.id"
                    :label="item.content"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!--                学历-->
            <div class="titleInput" style="width: 75px">
              <el-select
                  v-model="positionEducationshow"
                  placeholder="学历"
                  @change=" positionEducationMobile(positionEducationshow)"
              >
                <el-option
                    v-for="item in educationName"
                    :key="item.id"
                    :label="item.content"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!--                薪资-->
            <div class="titleInput" style="width: 75px">
              <el-select
                  v-model="positionSalary"
                  placeholder="薪资"
                  @change=" mobileSalary(positionSalary)"
              >
                <el-option
                    v-for="item in salaryName"
                    :key="item.id"
                    :label="item.content"
                    :value="item.content"
                >
                </el-option>
              </el-select>
            </div>

          </div>

        </div>
        <!--          历史查询记录-->
<!--        <div v-if="handleSearch===true" @click="changeKeyOther" style="z-index: 9999;position: absolute;top: 50px;background-color: rgba(102,102,102,0.9);min-height: 100vh;width: 100vw;padding: 15px 20px ">-->
              <div style="
            display: flex;
            width: 100%;
            margin: 0 auto;
            position: relative;
          ">
          <div class="middle1">
            <div style="min-height: 55vh">
              <li v-for="(lists, index) in list" :key="index">
                <div class="listTotle" @click="personalMobile(lists)">
                  <div class="detail">
                    <div>
                      <div>
                      <span class="positionName">
                        {{ lists.positionName }}</span
                      >
                        <span class="salaryName">
                        {{ lists.positionSalary }}</span
                        >
                      </div>
                      <div style="margin-top:6px">
                        <span class="companyName">
                        {{ lists.companyName }}</span>
                      </div>
                      <div class="jobStyle">
                        <span class="jobStyle1">{{ lists.positionAddress }}</span>
                        <span class="jobStyle2">{{lists.positionTime }}</span>
                        <span class="jobStyle2">{{lists.positionEducation}}</span>
                      </div>
                    </div>

                    <div class="comButton">
                      <el-button class="companyBut"
                      >立即沟通</el-button
                      >
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="comLogo">
                      <img style="border-radius: 50%" :src="baseURL + lists.interviewAvatar" alt="" />
                    </div>
                    <div style="margin:6px 8px;font-size: 12px;font-weight: 400;color: #7F7F7F;display: flex; ">
                      <span>{{lists.interviewName}}</span>
                      <span style="margin-left: 6px">{{lists.interviewDuties}}</span>
                    </div>

                  </div>
                </div>
              </li>

              <div v-if="list.length === 0" class="pagination-container" style="height: 60vh">
                <div
                    style="
                  width: 150px;
                  height: auto;
                  font-size: 16px;
                  margin: 0 auto;
                "
                >
                  <img loading="lazy" src="../../assets/none.png" alt="" />
                </div>
                <div style="color: black; line-height: 36px; font-size: 12px">
                  暂时没有符合该搜索条件的职位
                </div>
                <div style="color: gray;margin-bottom: 55px; line-height: 24px;font-size: 12px">
                  请重新修改搜索条件后再进行搜索
                </div>
              </div>

            </div>
            <!--        分页-->
            <div v-if="list.length !== 0" class="pagination-container">
              <el-pagination
                  v-model="page"
                  :current-page="page"
                  :page-size="size"
                  :page-sizes="[5, 8, 10, 15]"
                  :total="total"
                  background
                  layout=" prev, pager, next"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
            <bottomMobile></bottomMobile>
          </div>
        </div>

        <LoginTip
            :dialogVisibleLogin="dialogVisible"
            @hideDialog="hideDialog"
        ></LoginTip>
        <InfoTip
            :dialogVisibleInfo="dialogVisibleInfo"
            @hideDialog="hideDialogInfo"
        ></InfoTip>
        <LoginIn
            :dialogVisible="loginIn"
            @hideDialog="hideLoginIn"
            @register="showRegister"
            @forget="forget"
            @logsuccess="logsuccess"
        ></LoginIn>
        <RegisterIn
            :dialogVisible="registerIn"
            @hideDialog="hideregisterIn"
            @login="login"
        ></RegisterIn>
        <forgetIn
            :dialogVisible="forgetIn"
            @hideDialog="hidereforgetIn"
            @login="login"
        ></forgetIn>
        <cityAddress  :dialogVisible="cityIn" @cityOther="cityOther"  @hideDialog="cityHiden"> </cityAddress>
      </div>
    </div>
    <Footer v-if="isMobile === false"></Footer>
    <BottomBanner v-if="isMobile === true"></BottomBanner>
  </div>
</template>


<script>
import { getAuthorize } from "@/api/user";

import Header from "@/components/Header.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import Footer from "@/components/Footer.vue";
import Erweima from "@/views/candidate/Erweima.vue";
import PersonalLittle from "@/components/PersonalLittle.vue";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
import LoginTip from "@/components/LoginTip.vue";
import LoginIn from "@/views/userlog/LoginIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue";
import InfoTip from "@/components/InfoTip.vue";
import cityAddress from "@/components/cityAddress.vue";
import {proCityList} from "@/api/city";
import bottomMobile from "@/components/bottomMobile.vue";
import {load} from "@amap/amap-jsapi-loader";

export default {
  name: "",

  props: [],

  components: {
    bottomMobile,
    InfoTip,
    forgetIn,
    LoginIn,
    LoginTip,
    RegisterIn,
    Erweima,
    PersonalLittle,
    Footer,
    BottomBanner,
    Header,
    cityAddress
  },

  data() {
    return {
      cityIn:false,
      other: false,
      otherSalary: false,
      Address: "",
      positionSalary1: "",
      positionSalary2: "",
      dialogVisible: false,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      userId: localStorage.getItem("userId"),
      // userId:2,
      handleSearch:false,
      seachInput: "",
      isactive: 0,
      isMobile: false,
      token: localStorage.getItem("access_token"),
      cityName: [],
      salaryName: [],
      positionName: [],
      timeName: [],
      category:[],
      workOut:[
        {
          outId:1,
          outContent:"否"
        },
        {
          outId:0,
          outContent:"是"
        },
      ],
      educationName: [],
      tags: [],
      page: 1,
      size: 8,
      total: "",
      list: [],
      hotSearch:[],
      visible:false,
      userSearch:[],
      text: '海量职位，正在飞速来袭~',
      displayedText: '',
      index: 0,
      animationInterval: null,
      loading:true,
      baseURL: this.$utils.baseURL,
      keysWords: "",
      positionAddress:"",
      positionAddressShow:'',
      positionSalary: "",
      positionEducation: "",
      positionEducationshow:'',
      educationCategory:'',
      positionCategory: [],
      provinces: proCityList,
      companyScale:null,
      scale:[],
      positionOverseas:'',
      positionTime: "",
      positionTimeshow:'',
      loginIn: false,
    };
  },
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0); // 每次路由变化时滚动到页面顶部
    next();
  },
  async created() {
    // var key=sessionStorage.getItem("keysWords")
    // console.log(Object.keys(key).length === 0)


    this.city();
    this.salary();
    this.position();
    this.time();
    this.education();

    this.jobIndustry();
    this.positionScaleList()
    this.jobCity()
    this.getSearch()
    this.getuserSearch()
    // console.log("key",typeof (sessionStorage.getItem("keysWords")))
    if (sessionStorage.getItem("keysWords") === null) {
      this.keysWords = ""
    } else {
      this.keysWords = sessionStorage.getItem("keysWords")
      this.seachInput = this.keysWords
    }
    // console.log("!!!!!!!!!",this.keysWords)
    if (sessionStorage.getItem("positionAddress") === null) {
      this.positionAddress = ""
      this.positionAddressShow = ""
    } else {
      this.positionAddressShow = sessionStorage.getItem("positionAddress")
      this.positionAddress = sessionStorage.getItem("positionAddress")
    }
    if (sessionStorage.getItem("positionSalary") === null) {
      this.positionSalary = ""
    } else {

      this.positionSalary = sessionStorage.getItem("positionSalary")
    }
    if (sessionStorage.getItem("positionEducation") === null) {

      this.positionEducation = ""

    } else {
      this.positionEducation = sessionStorage.getItem("positionEducation")
      var educationResult = await this.getEducation(sessionStorage.getItem("positionTime"));
      this.positionEducationshow = educationResult

    }
    if (sessionStorage.getItem("positionTime") === null) {

      this.positionTime = ""

    } else {

      var timeResult = await this.getTime(sessionStorage.getItem("positionTime"));
      this.positionTimeshow = timeResult
      this.positionTime=sessionStorage.getItem("positionTime")

    }
    this.positionlist();

  },
  methods: {
    load,
    cityOtherthis(){
      this.cityName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.cityIn=true
    },
    startAnimation() {
      this.animationInterval = setInterval(() => {
        if (this.index < this.text.length) {
          this.displayedText += this.text[this.index];
          this.index++;
        } else {
          this.index = 0;
          this.displayedText = '';
        }
      }, 300); // 控制字符出现的速度，数值越小，速度越快
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideLoginIn() {
      this.loginIn = false;
    },
    logsuccess() {
      this.loginIn = false;
      this.userId = this.$store.state.userId;
      window.location.reload();
    },
    showRegister() {
      this.loginIn = false;
      this.registerIn = true;
    },
    forget() {
      this.loginIn = false;
      this.forgetIn = true;
    },
    hideregisterIn() {
      this.registerIn = false;
    },
    cityHiden(){
      this.cityIn = false;
    },
    cityOther(data){
      this.positionAddress=data
      console.log("城市",this.positionAddress)

      this.tags = this.tags.filter((tag) => tag.id !== "city");
      this.tags.push({ name: data, id: "city", type: "danger" });
       this.size=8
      this.page=1
      this.positionlist();

    },
    hidereforgetIn() {
      this.forgetIn = false;
    },
    login() {
      this.loginIn = true;
      this.registerIn = false;
    },

    showLoginIn() {
      // this.loginIn = true;
      window.open("http://54.222.201.29:18888/login")
    },
    showLoginIn1() {
      // this.$router.push("/login")
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        // console.log(res, "ress");
        window.location.replace(res.data.url);
      });
    },
    onKeyDown(event) {
      if (event.keyCode === 13) {
        // 如果按下的是回车键，执行搜索功能
        this.$refs.addressInput.blur();
        this.Search();
      }
    },
    // 多选
    selectCity(items) {

      this.cityName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      items.selected = true;
      this.positionAddress = items.content;
      // console.log(items)
      this.tags = this.tags.filter((tag) => tag.id !== "city");
      this.tags.push({ name: items.content, id: "city", type: "danger" });
       this.size=8
      this.page=1
      this.positionlist();


    },
    City(item) {

      this.tags.push({ name: item, id: "city", type: "danger" });
      if(item[0]==="热门城市"){
        this.positionAddress=item[1]
        sessionStorage.setItem("positionAddress",item[1])
      }else{
        const city=item[0]+"-"+item[1]
        sessionStorage.setItem("positionAddress",city)
        this.positionAddress=city
      }
      this.positionAddressShow=item[1]
      // sessionStorage.setItem("positionAddress",JSON.stringify(this.positionAddress))
      console.log(item);
      this.size=8
      this.page=1
      this.positionlist();
    },
    Salary() {
      this.salaryName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.positionSalary = "";
      this.tags = this.tags.filter((tag) => tag.id !== "salary");

      this.positionSalary =
        this.positionSalary1 + "-" + this.positionSalary2 + "k";
      this.tags.push({
        name: this.positionSalary,
        id: "salary",
        type: "danger",
      });
       this.size=8
      this.page=1
      this.positionlist()
    },
    // 单选
    selectSalary(items) {
      this.salaryName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.otherSalary = false
      this.positionSalary1=""
      this.positionSalary2=""
      items.selected = true;
      this.positionSalary = items.content;
      // 监察名为'salary'的标签并将其删除
      this.tags = this.tags.filter((tag) => tag.id !== "salary");
      this.tags.push({ name: items.content, id: "salary", type: "danger" })

       this.size=8
      this.page=1
      this.positionlist();
    },
    mobileSalary() {
      this.size=8
      this.page=1
      this.positionlist();
    },
    otherSalarys(){
      this.otherSalary = true
      this.salaryName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.tags = this.tags.filter((tag) => tag.id !== "salary");
    },
    // 多选
    selectEducation(items) {
      if (items.selected === false) {
        items.selected = true;
        this.positionEducation += items.content + ",";
        this.tags.push({
          name: items.content,
          id: "education",
          type: "danger",
        });
         this.size=8
      this.page=1
      this.positionlist();
      }
    },
    // 多选
    selectPosition(items) {
      if (items.selected === false) {
        items.selected = true;
        this.positionCategory += items.content + ",";
        this.tags.push({ name: items.content, id: "position", type: "danger" });
         this.size=8
      this.page=1
      this.positionlist()
      }
    },
    // 单
    selectTime(items) {
      this.timeName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      items.selected = true;
      this.positionTime = items.id;
      this.tags = this.tags.filter((tag) => tag.id !== "time");
      this.tags.push({ name: items.content, id: "time", type: "danger" });
       this.size=8
      this.page=1
      this.positionlist();
    },
    Time(time) {
      this.size = 8
      this.page = 1
      console.log("time", time)
      // var timeResult = await this.getTime(time);
      sessionStorage.setItem("positionTime", time)
      this.positionTime=time
      this.positionlist();
    },
    changeSearch(){
      this.handleSearch=true
      console.log("变化")
    },
    changeKey(item){
      this.seachInput=item
      this.keysWords =item
      this.Search()
    },
    changeKeyOther(){
      this.handleSearch=false
    },
    searchDelete(){
      this.$axios.delete(`/system/search/`+this.userId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            console.log(res.data);
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getuserSearch()
          })
          .catch(error => {
            console.log(error);
          });

    },
    Search() {
      this.handleSearch=false
      this.keysWords = this.seachInput;
      this.size=8
      this.page=1
      this.positionlist();
      this.postSearch()
    },
    postSearch(){
      this.$axios
          .post("/system/search", {
            userId: this.userId,
            content:this.keysWords
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getSearch(){
      this.$axios
          .get("/system/search/list", {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.hotSearch = res.data.rows;

          })
          .catch((error) => {
            console.log(error);
          });
    },
    getuserSearch(){
      this.$axios
          .get("/system/search/list", {
            params:{
              userId:this.userId,
              deleteTag:0
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.userSearch = res.data.rows;

          })
          .catch((error) => {
            console.log(error);
          });
    },
    // 详情
    personal(list) {
      // console.log(list);
      const routeInfo = this.$router.resolve({
        path: '/positionDetail',
        query: { positionId: list.positionId },
      });
      window.open(routeInfo.href, '_blank');
      // this.$router.push({
      //   path: '/positionDetail',
      //   query: { positionId: list.positionId },
      // })

    },
    personalMobile(list) {
      // console.log(list);
      // const routeInfo = this.$router.resolve({
      //   path: '/positionDetail',
      //   query: { positionId: list.positionId },
      // });
      // window.open(routeInfo.href, '_blank');
      this.$router.push({
        path: '/positionDetail',
        query: { positionId: list.positionId },
      })

    },
    // 分页
    handleSizeChange(val) {
      this.size = val;
      window.scrollTo(0, 0);
      this.positionlist();
    },
    handleCurrentChange(val) {
      this.page = val;
      window.scrollTo(0, 0);
      // this.handleScroll();
      // console.log(`当前页: ${val}`+this.page)
      this.$forceUpdate(); //强制重新渲染分页器组件

      this.positionlist();
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      // 当需要删除特定标签时

      if (tag.id === "city") {
        this.positionAddress = this.positionAddress.split(",");
        const index1 = this.cityName.findIndex(
          (item) => item.content === tag.name
        );
        if (index1 !== -1) {
          this.cityName[index1].selected = false;
        }
        const index = this.positionAddress.findIndex(
          (item) => item === tag.name
        );
        if (index !== -1) {
          this.positionAddress.splice(index, 1); // 从 positionCategory 数组中删除特定项目
          this.positionAddress = this.positionAddress.join(","); // 将数组转换回以逗号分隔的字符串
          if (this.positionAddress.length === 0) {
            this.positionAddress = [];
          }
           this.size=8
      this.page=1
      this.positionlist()
        }
      }
      // 将字符串转换为数组（假设 this.positionCategory 是以逗号分隔的字符串）

      if (tag.id === "position") {
        this.positionCategory = this.positionCategory.split(",");
        const index1 = this.positionName.findIndex(
          (item) => item.content === tag.name
        );
        if (index1 !== -1) {
          this.positionName[index1].selected = false;
        }
        const index = this.positionCategory.findIndex(
          (item) => item === tag.name
        );
        if (index !== -1) {
          this.positionCategory.splice(index, 1); // 从 positionCategory 数组中删除特定项目
          this.positionCategory = this.positionCategory.join(","); // 将数组转换回以逗号分隔的字符串
          if (this.positionCategory.length === 0) {
            this.positionCategory = [];
          }
           this.size=8
      this.page=1
      this.positionlist(); // 执行其他相关操作
        }
      }

      if (tag.id === "education") {
        this.positionEducation = this.positionEducation.split(",");
        const index1 = this.educationName.findIndex(
          (item) => item.content === tag.name
        );
        if (index1 !== -1) {
          this.educationName[index1].selected = false;
        }
        const index = this.positionEducation.findIndex(
          (item) => item === tag.name
        );
        if (index !== -1) {
          this.positionEducation.splice(index, 1); // 从 positionCategory 数组中删除特定项目
          this.positionEducation = this.positionEducation.join(","); // 将数组转换回以逗号分隔的字符串
          if (this.positionEducation.length === 0) {
            this.positionEducation = [];
          }

           this.size=8
      this.page=1
      this.positionlist()
        }
      }
      if (tag.id === "time") {
        this.timeName.forEach((item) => {
          item.selected = false; // 将所有项目的 selected 设置为 false
        });
        this.positionTime = "";
         this.size=8
      this.page=1
      this.positionlist();
      }
      if (tag.id === "salary") {
        this.salaryName.forEach((item) => {
          item.selected = false; // 将所有项目的 selected 设置为 false
        });
        this.positionSalary = "";
         this.size=8
      this.page=1
      this.positionlist();
      }
      if (tag.id === "positionEducation") {
        this.positionEducation = "";
         this.size=8
      this.page=1
      this.positionlist();
      }
      if (tag.id === "positionCategory") {

        this.positionCategory = "";
         this.size=8
      this.page=1
      this.positionlist();
      }
      if (tag.id === "positionOverseas") {
        this.positionOverseas = "";
         this.size=8
      this.page=1
      this.positionlist()
      }
      if (tag.id === "companyScale") {
        this.companyScale = null;
         this.size=8
      this.page=1
      this.positionlist()
      }
    },
    clear() {
      this.tags = [];
      this.cityName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.salaryName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.positionName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.timeName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.educationName.forEach((item) => {
        item.selected = false; // 将所有项目的 selected 设置为 false
      });
      this.keysWords = "";
      this.positionAddress = "";
      this.positionSalary = "";
      this.positionEducation = "";
      this.positionCategory = "";
      this.positionTime = "";
      this.seachInput = "";
      this.positionOverseas=""
       this.companyScale=null


       this.size=8
      this.page=1
      this.positionlist()
    },
    city() {
      this.$axios
        .get("/system/city/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.cityName = res.data.rows;
          this.cityName.forEach((item) => {
            item.selected = false; // 将所有项目的 selected 设置为 false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salary() {
      this.$axios
        .get("/system/salary/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.salaryName = res.data.rows;
          this.salaryName.forEach((item) => {
            item.selected = false; // 将所有项目的 selected 设置为 false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取行业
    jobIndustry() {
      this.$axios
          .get("/system/industry/column")
          .then((res) => {
            this.category = this.transformData(res.data.data);
            console.log(this.category)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    transformData(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContent,
          label: item.industryContent,
          children: item.child ? this.transformDataChild(item.child) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformDataChild(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.industryContent,
          label: item.industryContent,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    // 获取城市
    jobCity(){
      this.provinces=this.transformCityData(this.provinces);
    },
    transformCityData(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
          children: item.children ? this.transformCityDataChild(item.children) : []
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    transformCityDataChild(data) {
      const transformedData = [];
      data.forEach(item => {
        const cascaderItem = {
          value: item.name,
          label: item.name,
        };
        transformedData.push(cascaderItem);
      });
      return transformedData;
    },
    // 获取企业规模
    positionScaleList() {
      this.$axios
          .get("/system/scale/list")
          .then((res) => {
            this.scale = res.data.rows;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    position() {
      this.$axios
        .get("/system/position_category/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.positionName = res.data.rows;
          this.positionName.forEach((item) => {
            item.selected = false; // 将所有项目的 selected 设置为 false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    time() {
      this.$axios
        .get("/system/time/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.timeName = res.data.rows;
          this.timeName.forEach((item) => {
            item.selected = false; // 将所有项目的 selected 设置为 false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTime(item) {
      return this.$axios
          .get("/system/time/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getEducation(item) {
      return this.$axios
          .get("/system/education/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCategory(item) {
      return this.$axios
          .get("/system/position_category/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    education() {
     return this.$axios
        .get("/system/education/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.educationName = res.data.rows;
          this.educationName.forEach((item) => {
            item.selected = false; // 将所有项目的 selected 设置为 false
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async positionpositionEducation() {
      this.tags = this.tags.filter((tag) => tag.id !== "positionEducation");
      const result = await this.getEducation(this.positionEducation);
      this.tags.push({name: result, id: "positionEducation", type: "danger"});
      this.size = 8
      this.page = 1
      this.positionlist();
    },
    async positionEducationMobile(item) {
      this.tags = this.tags.filter((tag) => tag.id !== "positionEducation");
      const result = await this.getEducation(item);
      this.positionEducation=item
      sessionStorage.setItem("positionEducation",item)
      this.tags.push({name: result, id: "positionEducation", type: "danger"});
      this.size = 8
      this.page = 1
      this.positionlist();
    },
    positionpositionCategory(){
      // console.log(this.positionCategory)
      // console.log(this.positionCategory[1])
      this.tags = this.tags.filter((tag) => tag.id !== "positionCategory");
      this.tags.push({ name:this.positionCategory[1], id: "positionCategory", type: "danger" });
       this.size=8
      this.page=1
      this.positionlist();
    },
    positioncompanyScale(){
      this.tags = this.tags.filter((tag) => tag.id !== "companyScale");
      this.tags.push({ name: this.companyScale, id: "companyScale", type: "danger" });
       this.size=8
      this.page=1
      this.positionlist();
    },
    positionpositionOverseas(){
      this.tags = this.tags.filter((tag) => tag.id !== "positionOverseas");
      if(this.positionOverseas===0){
        this.tags.push({ name:"海外工作经历", id: "positionOverseas", type: "danger" });
      }
     else{
        this.tags.push({ name:"无需海外工作经历", id: "positionOverseas", type: "danger" });
      }
       this.size=8
      this.page=1
      this.positionlist();

    },
    handleSex(item){
      if(item.sex!==null){
        if(item.sex=='0'){
          item.interviewName=item.interviewName[0]+"女士"
        }
        else{
          item.interviewName=item.interviewName[0]+"先生"

        }
      }

    },
    async positionlist() {
      this.loading=true
      this.getuserSearch()
      this.$axios
        .get("/system/position/screen_list", {
          params: {
            pageNum: this.page,
            pageSize: this.size,
            positionName: this.keysWords,
            positionAddress: this.positionAddress,
            positionSalary: this.positionSalary,
            positionEducation: this.positionEducation,
            positionIndustry: this.positionCategory[1],
            positionTime: this.positionTime,
            positionOverseas:this.positionOverseas,
            companyScale:this.companyScale
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then(async (res) => {
          sessionStorage.setItem("keysWords",this.keysWords)
          // sessionStorage.setItem("positionAddress",this.positionAddress)
          sessionStorage.setItem("positionSalary",this.positionSalary)
          // sessionStorage.setItem("positionEducation",this.positionEducation)
          // sessionStorage.setItem("positionTime",this.positionTime)

          this.total = res.data.total;
          this.list = res.data.rows;
          for (var i = 0; i < this.list.length; i++) {
            // console.log(this.list[i].companyIndustry);
            this.list[i].companyIndustry = JSON.parse(this.list[i].companyIndustry)

            if(this.list[i].positionTime!==null){
              this.list[i].positionTime = await this.getTime(this.list[i].positionTime);
            }
            if(this.list[i].positionEducation!==null){
              this.list[i].positionEducation = await this.getEducation(this.list[i].positionEducation);
            }
            if(this.list[i].positionCategory!==null){
              this.list[i].positionCategory = await this.getCategory(this.list[i].positionCategory);
            }


            if (this.isMobile === true) {
              console.log(this.list[i]);
              this.handleSex(this.list[i])
            }
          }

          this.loading=false
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },

  beforeDestroy() {
    // 组件销毁前移除事件监听器
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    window.scrollTo(0, 0); // 在页面加载时滚动到页面顶部
    // Zheck if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    this.startAnimation();
  },
};
</script>


<style lang="scss" scoped type="text/scss">

.red-border-input {
  text-align: center;
  margin-top: -5px;
  width: 30px;
  border: 1px solid var(--main-color); /* 设置边框为红色 */
  border-radius: 4px; /* 设置边框圆角 */
  padding: 8px; /* 设置内边距 */
  outline: none; /* 去除默认的轮廓样式 */
}

/* 鼠标悬浮时，增加一些样式 */
.red-border-input:hover {
  border: 2px solid var(--main-color); /* 悬浮时增加边框宽度 */
}

.top {
  overflow-x: hidden;
  margin: 15px auto 0 auto;
  padding:  0 15px;
  width: 1200px;
  height: fit-content;
  background: #ffffff;
  border-radius: 5px;
  opacity: 1;
  position: relative;
  .flexBox {
    display: flex;
    border-radius: 4px;
    width: 1250px;
    margin: 2.9vh auto 0 auto;
    font-size: 14px;
    background: #ffffff;
    opacity: 1;
    height: 7.2vh;

    .spanStyle {
      width: 1px;
      font-size: 16px;
      height: 1.48vh;
      background: #d9d9d9;
    }

    .el-dropdown {
      width: 5.63vw;
      margin-left: 15px;
    }
    .el-dropdown-link {
      margin-left: 0.7vw;
      cursor: pointer;
      color: #333333;
    }
    .el-icon-arrow-down {
      font-size: 16px;
    }

    .searchBox {
      border: 1px solid #bf0022;
      border-radius: 105px;
      width: 75%;
      height: 40px;
      margin: -2px 0;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 1px solid var(--main-color);
      ::v-deep .el-button {
        padding: 0.8vh 0.7vw !important;
      }

      .searchContent {
        margin-left:15px;
        border-radius: 0;
        height: 30px;

        ::v-deep .el-input__inner {
          height: 100%;
        }

        ::v-deep .el-input__inner::placeholder {
          color: #999;
        }
      }

      .searchTo {
        width: 6.7vw;
        height: 30px;
        color: white;
        font-size: 14px;
        border: none;
        margin-right: 4px;
        background: #bf0022;
        border-radius:  105px;

        img {
          width: 1.1vw;
          height: 1.9vh;
        }
      }
    }

    .searchBox:hover {
      border: 1px solid #bf0022;}

    .scanEr {
      top:0;
      position: absolute;
      right: 25px;
      display: flex;
      background: #feffff;
    }
  }

  .filter {
    width: 1170px;
    margin: 15px auto 30px auto;
    .city {
      margin: 14px 0 0 15px;
      display: flex;

      .main {
        width:fit-content;
        height: 22px;
        font-size: 14px;
        //font-weight: bold;
        color: #333333;
        line-height: 19px;
      }

      .main2 {
        width:fit-content;
        height: 22px;
        font-size: 14px;
        //font-weight: bold;
        color: #333333;
        line-height: 19px;
      }

      .name {
        list-style: none;
        margin-left: 1.5vw;
        display: flex;
        cursor: pointer;

        .cityName {
          font-size: 14px;
          font-weight: 400;
          margin-right: 1.14vw;
          color: #333333;
          line-height: 16px;
        }

        .cityName.active {
          color: #bf0022;;
        }

        .cityName:hover {
          color:#bf0022;
          font-weight: 500;
        }
      }

      .name2 {
        list-style: none;
        margin-left: 1.5vw;
        display: flex;
        cursor: pointer;
        .titleInput {
          margin: -5px 15px auto 0;
          height:fit-content;;
          width:105px;

          border-radius: 5px;
          border:1px solid #f6eaea;
          ::v-deep .el-cascader{
            line-height: 30px;
          }
          ::v-deep .el-input__inner {
            border-radius: 5px;
            border: 1px solid #c9d6f1;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            height:30px;;
            line-height: 30px;;
            outline: 0;
            padding: 0 15px;
            transition: border-color .2s cubic-bezier(.645,.045,.355,1);
            width:100%;
          }
          ::v-deep .el-input__icon{
            line-height: 30px;
          }
          ::v-deep .el-cascader-panel{
            line-height: 35px;
            height: 30px !important;
          }
        }
        .cityName {
          font-size: 14px;
          font-weight: 400;
          margin-right: 1.14vw;
          color: #333333;
          line-height: 16px;
        }

        .cityName.active {
          color: #bf0022;;
        }

        .cityName:hover {
          color: #bf0022;;
          font-weight: 700;
        }
      }
    }

    .select {
      width: 100%;
      padding: 29px 15px 0 15px;
      position: relative;
      justify-content: flex-start; /* 将内部元素均匀分布 */
      display: flex;

      .name {
        width:fit-content;
        height: 16px;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }

      .tagStyle {
        width: auto;
        padding: 2px 12px;
        background:  #bf0022;
        margin-left: 16px;
        margin-top: -5px ;
        margin-bottom: 15px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
      }
      .el-tag.el-tag--danger {
        background-color: #bf0022;
        border-color: #bf0022;
        color: white;
      }
      ::v-deep .el-tag.el-tag--danger .el-tag__close {
        color: white !important;
      }
      .clear {
        width: 84px;
        height: 14px;
        right: 0;
        position: absolute;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        float: right;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }

      .clear:hover {
        color: #bf0022;
      }
    }

    .spanStyle {
      margin-top: 12px;
      width: 100%;
      height: 1px;
      background: #f5f5f5;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
}

.middle {
  overflow: hidden;
  width: 920px;
  opacity: 1;
  list-style: none;
  min-height: 75vh;

  .pagination-container {
    position: sticky;
    margin-top: 20px;
    padding: 25px;
    bottom: 15px;
    width: 99%;
    text-align: center;
    .shake {
      width: 150px;
      height: 150px;
      margin: 15px auto 0 auto;
      padding: 0;
      overflow: hidden; /* 确保超出部分被裁剪 */
      position: relative; /* 使图片的定位相对于其父元素 */
    }

    .shake img {
      position: absolute;
      top: 15px;
      left: 0;
      height: 90%;
      width: 90%;
      animation: shake 1s ease-in-out infinite; /* 调整时间、缓动函数和循环次数 */
    }

    @keyframes shake {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px); /* 图片上下移动的距离 */
      }
    }
    ::v-deep .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
    ::v-deep .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
    ::v-deep .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      border-radius: 8px;
      background-color: white;
      border: rgba(100, 98, 98, 0.2) 1px solid;
    }
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #bf0022 !important;
    }
  }

  .listTotle {
    margin-top: 20px;
    width: 100%;
    min-height: fit-content;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;

    .detail {
      padding: 20px 20px 5px 20px;
      display: flex;
      cursor: pointer;
      position: relative;

      .positionName {
        width: 64px;
        height: 16px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 600;
        color: #333333;
        line-height: 16px;
      }

      .positionName:hover {
        color: #bf0022;
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .salaryName {
        margin-left: 12px;
        width: 41px;
        height: 16px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        color: #bf0022;
        line-height: 16px;
      }

      .jobStyle {
        display: flex;
        margin-top: 16px;
        width: fit-content;

        .jobStyle1 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          border-radius: 6px;
          background-color: #f2f4f7;
          padding: 3px 10px;
        }

        .jobStyle1:hover {
          color: #bf0022;
          transform: scale(1.2);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }

        .jobStyle2 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          background-color: #f2f4f7;
          border-radius: 6px;
          padding: 3px 10px;
          margin-left: 12px;
        }

        .jobStyle2:hover {
          color: #bf0022;
          transform: scale(1.2);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }
      }



      .comButton {
        position: absolute;
        right: 45px;
        top: 20px;
        text-align: center;

        .companyBut {
          margin-top: 10px;
          width: 92px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          background: #fdfdfd;
          border-radius: 23px;
          opacity: 1;
          color: #416dfd  ;
          border:  #416dfd  1px solid;
        }

        .companyBut:hover {
          /* 此处是鼠标悬浮时的样式 */
          color: white;

          background: #416dfd;

          /* 你可以添加其他的样式以突出悬浮状态 */
        }

        ::v-deep .el-button {
          padding: 5px 10px;
        }
      }
    }

    .bottom {
      padding: 18px 20px;
      width: 100%;
      height: fit-content;
      opacity: 1;
      display: flex;
      cursor: pointer;
      .comLogo {
        width: 22px;
        height: 22px;
        border: rgba(100, 98, 98, 0.1) 1px solid;
        border-radius: 4px;
        opacity: 1;
      }

      .comLogo:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
      .companyName {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        margin-left: 8px;
        display: flex;

        .spanthis{
          width: 1px;
          margin: 3px 0 auto 16px;
          height: 16px;
          background-color: #e0dddd;
        }
        .name1 {
          width: fit-content;
          height: 22px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #050505;
          line-height: 22px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .name1:hover {
          color: #bf0022;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }


        .name2 {
          height: 22px;
          margin-left: 12px;
          font-size: 13px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .name2:hover {
          color: #bf0022;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }
        .name3 {
          height: 22px;
          font-size: 13px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .name3:hover {
          color: #bf0022;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }

      }

    }
  }
  .listTotle:hover {
    box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }
}

.advertisement {
  position: absolute;
  right: 0;
  top:20px;
  width: 260px;
  height: 170px;
  border-radius: 5px;
  cursor: pointer;
  background-image: url("../../assets/login.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
  //border: black 10px solid;
  .logo {
    opacity: 12;
    width: 50%;
    height: 100%;
    //background-image: url("../../assets/login.png");
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
  }
  .title {
    line-height: 30px;
    color: white;
    text-align: right;

    top: 15px;
    position: absolute;
    right: 15px;
    font-weight: bold;
    font-size: 22px;
  }
}
.advertisement2 {
  position: absolute;
  right: 0;
  margin-top: 210px;
  width: 260px;
  height: 170px;
  border-radius: 5px;
  cursor: pointer;
  background-image: url("../../assets/login2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
  //border: black 10px solid;
  .logo {
    opacity: 12;
    width: 50%;
    height: 100%;
    //background-image: url("../../assets/login.png");
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
  }
  .title {
    line-height: 30px;
    color: white;
    text-align: right;

    top: 15px;
    position: absolute;
    right: 15px;
    font-weight: bold;
    font-size: 22px;
  }
}
.jianli {
  margin: 20px 0 0 20px;
  width: 260px;
  height: 267px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.mobile{
  /* 或者 .scss 文件 */
  ::v-deep .el-select .el-input__inner {
    width: 100% !important; /* 控制输入框的宽度 */
  }
  .el-select-dropdown {
    width: 100%; /* 控制下拉框的宽度 */
    margin-top: 20px; /* 控制下拉框距离顶部的距离 */
  }
  .recommend2 {
    right: 0;
    position: absolute;
    width: 280px;
    height: 340px;
    top: 20px;
    //box-shadow: 0px 0px 1.4vh 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 2.3vh 1vw;
      font-size: 18px;
      color: #333333;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
  .top1 {
    overflow-x: hidden;
    margin:0 auto 10px auto;
    width: 100%;
    min-height: fit-content;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    position: relative;

    .flexBox {
      border-radius: 4px;
      width: 100%;
      font-size: 14px;
      background: #ffffff;
      opacity: 1;
      height: 50px;
      padding: 10px 95px 10px 95px;
      ::v-deep .el-popover__content {
        padding: 0 !important; /* 更改为你需要的padding值 */
      }
      ::v-deep .el-popover {
        padding: 0 !important; /* 更改为你需要的padding值 */
      }



      .spanStyle {
        width: 1px;
        font-size: 16px;
        height: 1.48vh;
        background: #d9d9d9;
      }

      .el-dropdown {
        width: 20.63vw;
      }

      .el-dropdown-link {
        margin-left: 0.7vw;
        cursor: pointer;
        color: #333333;
      }

      .el-icon-arrow-down {
        font-size: 16px;
      }

      .searchBox {
        border: 1px solid rgb(225, 227, 232);
        width:45%;
        position: absolute;
        right:100px;
        left:70px;
        margin:3px auto 0 auto;
        height: 28px;
        border-radius: 53px;
        background-color: rgb(225, 227, 232);
        display: flex;
        align-items: center;
        justify-content: center;
        //border: 1px solid var(--main-color);
        ::v-deep .el-button {
          padding: 5px  !important;
        }

        .searchContent {
          border-radius: 0;
          height: 28px;

          ::v-deep .el-input__inner {
            height: 100%;
            font-size: 12px;
            border-radius: 53px;
            background-color: rgb(225, 227, 232);
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }

        .searchTo {
          width: 55px;
          height:28px;
          color: rgb(84, 136, 245);
          font-size: 12px;
          border: none;
          background-color: rgb(225, 227, 232);
          border-radius: 53px;

          img {
            width: 1.1vw;
            height: 1.9vh;
          }
        }
      }

      .scanEr {
        position: absolute;
        right: 25px;
        display: flex;
        background: #feffff;
      }
    }

    .filter {
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.03);
      width: 100%;
      padding:0px 10px;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);

      .city {
        margin:0 14px;

        .main {
          width:fit-content;
          height: 22px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 19px;
        }

        .main2 {
          width:fit-content;
          height: 22px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 19px;
        }

        .name {
          list-style: none;
          margin-left: 3.23vw;
          display: flex;
          cursor: pointer;

          .cityName {
            font-size: 12px;
            font-weight: 400;
            margin-right: 1.14vw;
            color: #333333;
            line-height: 16px;
          }

          .cityName.active {
            color: #bf0022;
          }

          .cityName:hover {
            color: #bf0022;
            font-weight: 500;
          }
        }

        .name2 {
          list-style: none;
          margin-left: 1.5vw;
          display: flex;
          cursor: pointer;


          .cityName {
            font-size: 12px;
            font-weight: 400;
            margin-right: 1.14vw;
            color: #333333;
            line-height: 16px;
          }

          .cityName.active {
            color: #bf0022;
          }

          .cityName:hover {
            color: #bf0022;
            font-weight: 500;
          }
        }
      }

      .select {
        width: 66vw;
        margin: 29px 0;
        position: relative;
        justify-content: flex-start; /* 将内部元素均匀分布 */
        display: flex;

        .name {
          width: 70px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }

        .tagStyle {
          width: auto;
          padding: 2px 12px;
          background: #faebee;
          margin-left: 16px;
          margin-top: -5px;
          margin-bottom: 15px;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
        }

        .clear {
          width: 84px;
          height: 14px;
          right: -25vw;
          position: absolute;
          font-size: 12px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          float: right;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
        }

        .clear:hover {
          color: #bf0022;
        }
      }

      .spanStyle {
        margin-top: 12px;
        width: 100%;
        height: 1px;
        background: #f5f5f5;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
  .middle1 {
    overflow: hidden;
    padding:0 0 50px 0;
    width: 100%;
    margin: 0 auto;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    list-style: none;

    .pagination-container {
      position: sticky;
      margin-top:10px;
      padding: 5px;
      width: 100%;
      background-color: white;
      text-align: center;
      ::v-deep .el-pager li {
        border-radius: 8px;
        background-color: white;
        border: rgba(100, 98, 98, 0.2) 1px solid;
      }
      ::v-deep .el-pagination.is-background .btn-next,
      .el-pagination.is-background .btn-prev,
      .el-pagination.is-background .el-pager li {
        border-radius: 8px;
        background-color: white;
        border: rgba(100, 98, 98, 0.2) 1px solid;
      }
      ::v-deep .el-pagination.is-background .btn-prev,
      .el-pagination.is-background .el-pager li {
        border-radius: 8px;
        background-color: white;
        border: rgba(100, 98, 98, 0.2) 1px solid;
      }
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #bf0022 !important;
      }
    }

    .listTotle {
      width: 100%;
      margin-bottom: 10px;
      height: fit-content;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;

      .detail {
        padding: 18px 20px 16px 17px;
        display: flex;
        cursor: pointer;
        position: relative;

        .positionName {
          width: 64px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 600;
          color: #333333;
          line-height: 16px;
        }

        .salaryName {
          position: absolute;
          right:20px;
          width: fit-content;
          height: 14px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #bf0022;
          line-height: 16px;
        }

        .jobStyle {
          display: flex;
          margin-top: 10px;
          width: fit-content;

          .jobStyle1 {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            background-color: #f5f5f5;
            padding: 3px 10px;
          }

          .jobStyle1:hover {
            color: #bf0022;
            transform: scale(1.2);
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }

          .jobStyle2 {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            background-color: #f5f5f5;
            padding: 3px 10px;
            margin-left: 6px;
          }

          .jobStyle2:hover {
            color: #bf0022;
            transform: scale(1.2);
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }
        }

        .companyName {
          width: 224px;
          height: 16px;
          font-size: 12px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333333;
          line-height: 16px;
        }
        .comLogo:hover {
          transform: scale(1.1);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }


        .comButton {
          position: absolute;
          right: 15px;
          bottom: -28px;
          .companyBut {
            width: 100px;
            height: 30px;
            font-size: 12px;
            font-weight: 400;
            color: white;
            border: 1px solid rgb(84, 136, 245);
            background: rgb(84, 136, 245);
            border-radius: 15px 15px 15px 15px;
            opacity: 1;
          }

          .companyBut:hover {
            /* 此处是鼠标悬浮时的样式 */
            color: #ffffff;
            background: #bf0022;
            /* 你可以添加其他的样式以突出悬浮状态 */
          }

          ::v-deep .el-button {
            padding: 5px 10px;
          }
        }
      }

      .bottom {
        padding: 0 20px 10px 20px;
        width: 100%;
        height: fit-content;
        //background: linear-gradient(99deg, #faebee 0%, #fcfbfa 62%);
        opacity: 1;
        display: flex;
        cursor: pointer;
        .comLogo {
          border-radius: 50%;
          width: 30px;
          height:30px;
          border: rgba(100, 98, 98, 0.1) 1px solid;
          opacity: 1;
        }


        .address {
          width: 10px;
          height: 12px;
          background-image: url("../../assets/addressbg.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .positionAddress {
          height: 16px;
          font-size: 12px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          margin-left: 5px;
          margin-top: -2px;
        }
        .positionAddress:hover {
          color: #bf0022;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
        }
      }
    }
    .listTotle:hover {
      box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
    }
  }
  .advertisement2 {
    top: 180px;
    z-index: 999;
    margin-top: 8px;
    position: absolute;
    right: 0;
    width: 260px;
    height: 170px;
    border-radius: 5px;
    //background-color: #30539d;
    cursor: pointer;
    background-image: url("../../assets/login2.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: black 10px solid;

    .title {
      line-height: 30px;
      color: white;
      text-align: right;

      top: 15px;
      position: absolute;
      right: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }
}
</style>
