<template style="height: 100%">
  <div>
    <div v-if="isMobile === false" style="display: flex;flex-direction: column;position: relative;">
      <div style="flex: 1" class="wrapper">
        <div>
            <div v-if="isNEWs === 0">
              <div class="news">
                <div class="talk_con">
                  <div
                      style="
                 background:linear-gradient(to right,rgb(209,248,248) 0%,rgb(253,217,216) 100%);
                    color: black;
                    width: 100%;
                    text-align: left;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: bold;
                    padding:15px 25px ;
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                  "
                  >
                    AI简历小助手
                  </div>
                  <div class="content">
                    <div class="communicate" ref="messageContainer">
                      <div style="">
                        以下是我能提供的服务能力:
                      </div>
                      <div class="tip">
                        <div class="tips">
                          <div style="display: flex">
                            <div class="circle"></div>
                            <div class="highlight">简历整体分析：</div>
                          </div>
                          <div class="title">针对在线简历，提供整体优化建议</div>
                        </div>
                        <div class="tips">
                          <div style="display: flex">
                            <div class="circle"></div>
                            <div class="highlight">模块话问答：</div>
                          </div>
                          <div class="title">针对某一具体模块进行相应的对话问答</div>
                        </div>
                      </div>

                      <div class="talk-show">
                        <div
                            v-for="message in messages"
                            :key="message.id"
                            class="message"
                            :class="message.sender === 'user' ? 'user' : 'ai'"
                        >
                          <div v-if="message.sender === 'user'">
                            <div class="btalk">
                              <div style="display: flex;justify-content: flex-end">
                                <div>
                          <span><div class="arrow-content">{{ message.text }}</div>
                          </span>
                                </div>
                                <div>
                                  <img
                                      :src=" userInfo.avatar!==null
                                    ? baseURL + userInfo.avatar
                                    : avatar
                                "
                                      alt="User Avatar"
                                      class="userPhoto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="atalk">
                              <div v-if="message.ok===0" class="AIphotoMove"></div>
                              <div v-if="message.ok===1||message.ok===2" class="AIphoto"></div>
                              <div id="asay" class="answer">

                                <div id="whose1" class="talk">
                                  <div v-html="message.answer"></div>
                                </div>
                                <!--                              <div v-if="message.ok===1" class="afterAnswer">-->
                                <!--                                <div style="display: flex">-->
                                <!--                                  <div class="buttonCopy">-->
                                <!--                                    <button-->
                                <!--                                        v-if="isTextDisplayed && message.copy === 0"-->
                                <!--                                        id="copy"-->
                                <!--                                        class="copy"-->
                                <!--                                        @click="copy(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                    <button-->
                                <!--                                        v-if="isTextDisplayed && message.copy === 1"-->
                                <!--                                        id="copy"-->
                                <!--                                        class="copyReally"-->
                                <!--                                        @click="copy(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                  </div>-->
                                <!--                                  <div class="buttonCopy">-->

                                <!--                                    <button-->
                                <!--                                        v-if="isTextDisplayed && message.evaluationI === '2'"-->
                                <!--                                        id="deny"-->
                                <!--                                        class="denyReally"-->
                                <!--                                        @click="denyChange(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                    <button-->
                                <!--                                        v-else-->
                                <!--                                        id="deny"-->
                                <!--                                        class="deny"-->
                                <!--                                        @click="denyChange(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                  </div>-->
                                <!--                                  <div class="buttonCopy">-->

                                <!--                                    <button-->
                                <!--                                        v-if="isTextDisplayed && message.evaluationI === '1'"-->
                                <!--                                        id="gray"-->
                                <!--                                        class="grayReally"-->
                                <!--                                        @click="gray(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                    <button-->
                                <!--                                        v-else-->
                                <!--                                        id="gray"-->
                                <!--                                        class="gray"-->
                                <!--                                        @click="gray(message)"-->
                                <!--                                    >-->
                                <!--                              <span-->
                                <!--                                  class="hover-text"-->
                                <!--                                  style="display: none"-->
                                <!--                              ></span>-->
                                <!--                                    </button>-->
                                <!--                                  </div>-->
                                <!--                                </div>-->
                                <!--                              </div>-->

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="talk_input">
                      <div class="input_container">
                        <div>
                           <textarea
                               id="text"
                               v-model="newMessage"
                               :rows="1"
                               class="talk_word"
                               maxlength="3000"
                               placeholder="请输入需要咨询的问题"
                               @input="resizeTextarea"
                               @keydown.enter.prevent
                               @keyup.enter="sendMessage()"
                           ></textarea>
                        </div>
                        <div class="talk_button">
                          <!--                  <el-button-->
                          <!--                      v-if="net===1"-->
                          <!--                      class="talk_web"-->
                          <!--                      disabled-->
                          <!--                      type="primary"-->
                          <!--                      @click="sendNet()"-->
                          <!--                  ></el-button>-->
                          <!--                  <el-button-->
                          <!--                      v-if="net===0"-->
                          <!--                      class="talk_webNo"-->
                          <!--                      disabled-->
                          <!--                      type="primary"-->
                          <!--                      @click="sendNet()"-->
                          <!--                  ></el-button>-->
                          <el-button
                              v-if="identification==='0'"
                              :disabled="isSendingMessage"
                              class="talk_file"
                              type="primary"
                              @click="showUpload()"
                          ></el-button>
                          <el-button
                              :disabled="isSendingMessage"
                              class="talk_sub"
                              type="primary"
                              @click="sendMessage()"
                          ></el-button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="display: flex">
              <div style="width: 60%">
                <div class="news">
                  <div style="display: flex" class="talk_con1">
                    <!--  历史-->
                    <!--  主界面-->
                    <div class="content">
                      <div class="communicate" ref="messageContainer">
                        <div class="atalk">
                          <div class="AIphoto"></div>
                          <span id="asay" style="display: flex">
                          <div
                              class="talk"
                              id="whose1"
                          >
                            您好，我是您的知识小助手小安，请问有什么问题，我会尽力帮您解答！
                          </div>
                        </span>
                        </div>

                        <div class="talk-show">
                          <div
                              v-for="message in messages"
                              :key="message.id"
                              class="message"
                              :class="message.sender === 'user' ? 'user' : 'ai'"
                          >
                            <div v-if="message.sender === 'user'">
                              <div class="btalk">
                                <div style="display: flex; max-width: 80%">
                                  <div>
                                    <img
                                        class="userPhoto"
                                        :src= "userInfo.avatar!==null
                                    ? baseURL + userInfo.avatar
                                    : avatar
                                  "
                                        alt="User Avatar"
                                    />
                                  </div>
                                  <div style="width: 100%">
                                  <span
                                  ><div class="arrow-content">
                                      {{ message.text }}
                                    </div></span
                                  >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="atalk">
                                <div class="AIphoto"></div>
                                <span id="asay" style="">
                                <div class="talk" id="whose1">
                                  {{ message.answer }}
                                </div>
                                  <!--                      <div v-for="(item2, index) in message.recourse" :key="index">-->
                                  <!--                        <div class="con_que" @click="clickRobot(item2)"-->
                                  <!--                        >-->
                                  <!--                          <div class="czkj-question-msg">-->
                                  <!--                            {{ item2.index }}-->
                                  <!--                            {{ item2.title }}-->
                                  <!--                          </div>-->
                                  <!--                        </div>-->
                                  <!--                      </div>-->
                                <button
                                    v-if="isTextDisplayed && message.deny === 0"
                                    @click="denyChange(message)"
                                    data-info="info"
                                    class="deny"
                                    id="deny"
                                >
                                  <span
                                      class="hover-text"
                                      style="display: none"
                                  ></span>
                                </button>
                                <button
                                    v-if="isTextDisplayed && message.deny === 1"
                                    @click="denyChange(message)"
                                    data-info="{'change': 1}"
                                    class="denyReally"
                                    id="deny"
                                >
                                  <span
                                      class="hover-text"
                                      style="display: none"
                                  ></span>
                                </button>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="talk_input">
                        <div>
                        <textarea
                            v-if="languageSelect === 'zh'"
                            placeholder="请输入需要咨询的问题"
                            class="talk_word"
                            style="
                            border: none;
                            min-height: 80px;
                            padding: 5px 7vh 5px 5px;
                            overflow: auto;
                            font-size: 14px;
                          "
                            maxlength="3000"
                            id="text"
                            :rows="1"
                            v-model="newMessage"
                            @keyup.enter="sendMessage()"
                        ></textarea>
                          <el-button
                              class="talk_sub"
                              :disabled="isSendingMessage"
                              @keydown.enter="sendMessage()"
                              @click="sendMessage()"
                              type="primary"
                          ></el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fload" @click="isNEWs = 0"></div>
              <div
                  style="
                width: 40%;
                height: 75vh;
                overflow: auto;
                background-color: white;
              "
              >
                <!--      文章标题-->
                <div
                    v-if="languageSelect === 'zh'"
                    style="
                  text-align: center;
                  font-size: 16px;
                  margin-top: 4%;
                  font-weight: bold;
                "
                >
                  {{ articles.title }}
                  <!--        <button @click="change">{{ $t('change') }}</button>-->
                </div>
                <div
                    v-if="languageSelect === 'en'"
                    style="
                  text-align: center;
                  font-size: 16px;
                  margin-top: 4%;
                  font-weight: bold;
                "
                >
                  {{ titleEn }}
                  <!--        <button @click="change">{{ $t('change') }}</button>-->
                </div>
                <div
                    style="
                  width: 60%;
                  font-size: 16px;
                  color: gray;
                  margin: 1% 20% 1% 20%;
                  text-align: right;
                "
                >
                  {{ articles.dateTime }}
                </div>

                <!--      文章内容-->
                <div class="detailContent">
                  <div
                      v-html="articles.content"
                      v-if="languageSelect === 'zh'"
                      style="
                    line-height: 2;
                    font-size: 16px;
                    width: 90%;
                    margin: 1% 5% 1% 5%;
                    text-align: left;
                  "
                  ></div>
                  <div
                      v-html="contentEn"
                      v-if="languageSelect === 'en'"
                      style="
                    line-height: 2;
                    font-size: 16px;
                    width: 90%;
                    margin: 1% 5% 1% 5%;
                    text-align: left;
                  "
                  ></div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>
 </template>

<script>
import { getAuthorize } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";

export default {
  name: "AIAnswer",
  components: {},
  data() {
    return {
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      dialogVisible: false,
      isMobile: false,
      titleEn: "",
      contentEn: "",
      languageSelect: "",
      isClicked: false,
      userInfo: "",
      isLogin: "",
      isNEWs: 0,
      isTextDisplayed: false,
      isSendingMessage: false,
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        answer: "您好，我是您的AI简历小助手小安，请问有什么关于简历的问题，我会尽力帮您解答！🤔",
        resource: "",
      }],
      newMessage: "",
      Message: "",
      token: "",
      baseURL: this.$utils.baseURL,
      avatar: require("@/assets/avator.png"),
       userId: "",
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      textarea: "",
      deny: 0,
      count: 0,
      uniqueId: "",
      question: "",
      answer: "",
      options: [
        {
          value: "1",
          label: "新建对话",
        },
        {
          value: "2",
          label: "历史记录",
        },
      ],
      optionvalue: "1",
      showPopover: false,
      timer: null,
      articles: [],
    };
  },
  methods: {
    pushLogin() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
      // this.$router.push("/login")
      // this.$emit("pushLogin")
    },
    handleClose() {
      this.denyformShow = true;
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item) {
      console.log("Selected value:", item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    // 文章索引
    clickRobot(item) {
      this.isNEWs = 1;
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id,
        },
      });
      this.$axios
        .get("/api/news/" + item.id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.articles = res.data.data;
          const currentDate = new Date(this.articles.createTime);
          const year = currentDate.getFullYear();
          let month = this.dateIfAddZero(currentDate.getMonth() + 1);
          let day = this.dateIfAddZero(currentDate.getDate());
          const formattedDate = `${year}-${month}-${day}`;
          this.articles.dateTime = formattedDate;
        })
        .catch((error) => {
          console.log(error);
        });

      var vm = this;
      vm.$axios
        .post("/systemenes/trans", {
          // params: {
          newsId: item.id,
          // },
          headers: {
            Authorization: vm.token,
          },
        })
        .then((res) => {
          // console.log(res.data.data.enTitle)
          vm.titleEn = res.data.data.enTitle;
          vm.contentEn = res.data.data.enContent;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 新建对话
    newContent() {
      this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
      this.messages = [];
      this.count = 0;
      this.isSendingMessage = false;

      if (this.languageSelect === "zh") {
        this.$notify.warning({
          title: "提示",
          message: "新建对话成功，我们将重新开始新一轮对话。",
          showClose: false,
          customClass: "notify-success",
        });
      } else {
        this.$notify.warning({
          title: "Tips",
          message:
            "If the new dialogue is successful, we will start a new round of dialogue",
          showClose: false,
          customClass: "notify-success",
        });
      }
    },
    // // 历史记录轮次
    // historylist() {
    //   // console.log("ddd")
    //   // console.log(parseInt(this.userId))
    //   this.$axios
    //     .get("api/qa/selectAll", {
    //       params: {
    //         userId: this.userId,
    //       },
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: parseInt(this.userId),
    //       },
    //     })
    //     .then((res) => {
    //       this.communicateHistory = res.data;
    //       for (var i = 0; i < this.communicateHistory.length; i++) {
    //         const currentDate = new Date(this.communicateHistory[i].createTime);
    //         const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    //         const day = ("0" + currentDate.getDate()).slice(-2);
    //         const hours = ("0" + currentDate.getHours()).slice(-2);
    //         const min = ("0" + currentDate.getMinutes()).slice(-2);
    //         const formattedDate = `${month}-${day} ${hours}:${min}`;
    //         this.communicateHistory[i].createTime = formattedDate;
    //         this.communicateHistory[i].isClicked = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // 删除历史
    deleteHistory(item) {
      console.log(item.roundId);

      console.log("删除");
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false;
      }

      communicateHistory.isClicked = true;
      console.log(communicateHistory);
      this.$axios
        .get("/api/qa/selectByRoundId/" + communicateHistory.roundId, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isTextDisplayed = true;
          this.messages = [];
          for (var i = 0; i < res.data.length; i++) {
            this.uniqueId = communicateHistory.roundId;
            this.messages.push({
              id: res.data[i].id,
              sender: "user",
              text: res.data[i].qaQuery,
            });
            if (res.data[i].qaEvaluate) {
              res.data[i].qaEvaluate = 1;
            } else {
              res.data[i].qaEvaluate = 0;
            }
            this.messages.push({
              // 使用保存的vm
              id: res.data[i].id,
              sender: "ai",
              answer: res.data[i].qaAnswers,
              deny: res.data[i].qaEvaluate,
              question: res.data[i].qaQuery,
            });
          }
        });
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer;
      this.denyformShow = false;
      console.log(this.denyForm);
      console.log(this.submitForm);
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map((message) => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组

      this.$axios
        .put(
          "/api/qa/update",
          {
            id: this.submitForm.id,
            qaEvaluationContent: this.denyForm.answer,
            qaAnswers: this.denyForm.answer,
            qaQuery: this.submitForm.primary,
            qaEvaluate: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question;
      this.denyForm.answer = this.submitForm.primary;
    },
    changeWho() {
      this.showPopover = false;
    },
    resizeTextarea() {
      const textarea = document.getElementById('text');
      textarea.style.height = 'auto'; // 重置高度，使其自动调整
      textarea.style.height = textarea.scrollHeight + 'px'; // 设置为内容高度
      if (this.newMessage.trim() === '') {
        textarea.style.height = '12px'; // 当内容为空时，设置为最小高度
      }
      textarea.scrollTop = 0; // 将滚动条置顶
      if (this.newMessage.length >= 3000) {
        this.$emit('input', this.newMessage.slice(0, 3000));
        this.$message.warning(`最多可以输入3000个字符`);
      }
    },
    sendMessage() {

      const textarea = document.getElementById('text');
      textarea.style.height = 'auto'; // 重置高度，使其自动调整
      textarea.style.height = textarea.scrollHeight + 'px'; // 设置为内容高度
        textarea.style.height = '12px'; // 当内容为空时，设置为最小高度
      // 找到所有满足条件的消息
      const messagesToUpdate = this.messages.filter(
          (message) => message.id === "00000" && message.sender === "ai" && message.ok === 0
      );

// 遍历所有找到的消息并更新它们的ok属性
      messagesToUpdate.forEach((message) => {
        this.$set(message, "ok", 2);
      });

      // 找到所有满足条件的消息
      const mes = this.messages.filter(
          (message) => message.id === "11111" && message.sender === "ai" && message.ok === 0
      );

// 遍历所有找到的消息并更新它们的ok属性
      mes.forEach((message) => {
        this.$set(message, "ok", 2);
      });



      // 清除之前的定时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
        this.messages = [];
        this.count = 0;
        this.isSendingMessage = false;
        // this.historylist();
        this.$notify.warning({
          title: "提示",
          message: "当前超出30min未进行对话，请刷新重试",
          showClose: true,
        });
      }, 1800000); // 等于 60,000*30 毫秒

      // 禁用发送按钮
      this.isSendingMessage = true;
      this.count++;
      if (this.uniqueId === "") {
        this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
      }

      if (this.newMessage) {
        // 问题
        this.question = this.newMessage;
        this.messages.push({
          id: this.uniqueId,
          sender: "user",
          text: this.newMessage,
        });

        // 先添加“稍后再试”消息
        this.messages.push({
          id: "",
          ok: 0,
          sender: "ai",
          answer: "正在生成，请稍候...",
          resource:"",
          evaluationI: "0",
          copy: 0,
          question: this.question,
        });

        if (this.count === 21) {
          this.uniqueId = "id-" + Math.random().toString(36).substr(2, 9);
          this.messages = [];
          this.count = 0;
          this.isSendingMessage = false;
          // this.historylist();
          this.$notify.warning({
            title: "提示",
            message:
              "当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。",
            showClose: false,
          });
        } else {
          this.newMessage = "";
          setTimeout(() => {
            var vm = this; // 保存this
            this.$axios
              .post(
                "system/qa/get_answer",
                {
                  question: this.question,
                  round_id: this.uniqueId,
                  userId: this.userId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: this.token,
                  },
                }
              )
              .then(async function (response) {
                var resindex = [];
                for (var i = 0; i < response.data.answer.length; i++) {
                  resindex.push({
                    index: i + 1,
                    title: response.data.answer[i],
                  });
                }
                vm.isSendingMessage = false;
                // 查找索引以替换“稍后再试”消息
                const index = vm.messages.findIndex(
                  (message) => message.id === "" && message.sender === "ai"
                );
                // 若存在这样的信息
                if (index !== -1) {
                  // 逐字显示回答
                  vm.$set(vm.messages[index], "id", response.data.id); // 更改ID
                  // 主子输出

                  await vm.printAnswerMessage(
                    response.data.answer,
                    vm,
                    index,
                    resindex
                  );
                  // vm.$set(vm.messages[index], 'recourse', resindex);
                  this.$nextTick(() => {
                    // 在下一个tick时滚动到底部
                    const container = this.$refs.messageContainer;
                    container.scrollTop = container.scrollHeight;
                  });
                  // console.log("huidu ")
                  // console.log(vm.messages)
                }
              });
          }, 2000); // 2000毫秒（2秒）
        }
      } else {
        this.$notify.warning({
          title: "提示",
          message: "请输入你的问题",
          showClose: false,
        });
      }

      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async printAnswerMessage(message, vm, index, resindex) {
      let currentIndex = 0;
      let printedMessage = "";

      const printFunction = () => {
        if (currentIndex < message.length) {

          // if (message[currentIndex] === '*' && currentIndex + 1 < message.length && message[currentIndex + 1] === '*') {
          //   printedMessage += '\n'; // 添加换行符
          //   currentIndex += 2; // 跳过 "**"
          // } else {
          //   printedMessage += message[currentIndex];
          //   currentIndex++;
          // }


          printedMessage += message[currentIndex];
          vm.messages[index].answer = printedMessage;
          currentIndex++;
          setTimeout(printFunction, 50);
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        } else {
          vm.$set(vm.messages[index], "recourse", resindex); // 更改ID
          vm.$set(vm.messages[index], "ok", 1); // 更改ID
          // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
          this.isTextDisplayed = true;
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        }
      };
      printFunction();
    },
    // 复制
    copy(message) {
      if (message.copy === 0) {
        message.copy = 1
      }
      // console.log(message)
      const textToCopy = message.answer;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    // 点赞
    gray(message) {
      console.log(message.evaluationI)
      console.log("message")
      console.log(message)
      if (message.evaluationI === "1") {

        this.$axios.post(
            // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
            "http://localhost:8080/system/dialogue/internal_evaluation",
            {
              id: message.id,
              evaluationI: "0",
            },
            {
              headers: {
                "Content-Type": "application/json",
                // Authorization: this.token,
              },
            }
        ).then((res) => {
          this.getNo()
          this.getAppreciate()
          this.$set(message, "evaluationI", "0"); // 更改ID
          console.log(res);
        });


      } else {

        // console.log("end")
        // console.log(message.evaluationI)
        this.$axios
            .post(
                // "http://11.89.147.1:8080/system/dialogue/external_evaluation",
                "http://localhost:8080/system/dialogue/internal_evaluation",
                {
                  id: message.id,
                  evaluationI: "1",
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: this.token,
                  },
                }
            ).then((res) => {
          this.$set(message, "evaluationI", "1"); // 更改ID
          this.getNo()
          this.getAppreciate()
          console.log(res);
        });
      }
    },
    // 点否点击
    denyChange(event) {
      // 获取按钮自带的 data-info 参数
      if (event.deny === 0) {
        this.denyformShow = true;
        this.submitForm.question = event.question;
        this.submitForm.answer = event.answer;
        this.submitForm.primary = event.answer;
        this.submitForm.id = event.id;
        this.denyForm.question = this.submitForm.question;
        this.denyForm.answer = this.submitForm.primary;
      } else {
        this.messages = this.messages.map((message) => {
          if (message.id === this.submitForm.id) {
            message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
          }
          return message; // 返回更新后的 message
        });

        console.log(this.messages); // 打印更新后的 messages 数组
      }
    },
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo.avatar!==null);
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");
    // this.token = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",

    //  this.userId =1

    // this.historylist();
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气人才",
        text: "全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  watch: {
    optionvalue(newValue) {
      console.log("Selected value:", newValue);
      if (newValue === "1") {
        this.newContent();
      }

      // 在这里可以处理选项变化后的逻辑
    },
  },
};
</script>

<style scoped lang='scss'>
.notify-custom-class {
  /* 在这里放置自定义通知样式 */
  background-color: #f0ad4e; /* 设置背景颜色为黄色 */
  color: #fff; /* 设置文本颜色为白色 */
  font-size: 16px; /* 设置字体大小 */
  /* 添加其他样式设置 */
}
.notLogin {
  text-align: center;
  color: #666666;
  font-size: 16px;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 100px 0px;

  img {
    width: 306px;
    margin: 0 auto;
  }
}

.buttons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.deleteStyle {
  display: none; /* 初始状态下隐藏删除按钮 */
}

.historyCard:hover .deleteStyle {
  margin-top: 5%;
  margin-left: 40px;
  display: inline-block; /* 鼠标悬停在.historyCard上时显示删除按钮 */
}
.questioninput {
  //overflow: auto; /* 添加滚动条 */
  max-height: 8vh;
  width: 32vw;
  font-size: 16px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  border: black 2px solid;

  .el-textarea__inner {
    height: 5vh;
  }
}

.ansinput {
  width: 32vw;
  font-size: 16px;
  margin-left: 6px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  min-height: 13vh;
  max-height: 13vh;
  overflow: auto; /* 添加滚动条 */
}

::v-deep .el-dialog__headerbtn {
  cursor: pointer;
  font-size: 25px;
  font-weight: 800;
}
.phone {
  position: fixed;

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;
      top: 35vh;
    }
    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;
      a {
        display: block;
      }
    }
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    color: #fff;
  }

}
.fload {
  width: 15px;
  height: 75vh;
  background-image: url("../../assets/fload.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
}

::v-deep .el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #f8f8f8 !important;
}
::v-deep .el-notification__group {
  /* 修改宽度和字体 */
  width: 250px !important; /* 设置宽度为 300px */
  font-size: 8px; /* 设置字体大小为 16px */
}

::v-deep el-dialog__close el-icon el-icon-close {
  color: white;
  background-image: url("../../assets/exit.png") !important;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: 20px;
  padding: 0;
  border: none;
  outline: 0;
  color: white;
  background-image: url("../../assets/exit.png") !important;
  cursor: pointer;
  font-size: 25px;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0px !important;
  background: #fff;
  border-radius: 2px;
  box-shadow: 8px 5px 8px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 100%;
}

::v-deep .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-dialog__header {
  padding: 0;
}

.con_que {
  color: #1c88ff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}

.horizontal-line2 {
  margin-top: 2vh;
  width: 88%; /* 设置分界线的宽度 */
  height: 1px; /* 设置分界线的高度 */
  margin-left: 5%;
  text-align: center;
  color: gray;
  background-color: rgba(128, 128, 128, 0.2); /* 设置分界线的颜色 */
}

.denyForm {
  overflow-y: hidden;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: white;
  width: 48vw;
  height: 48vh;
  border-radius: 5px;
  position: absolute;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.deny {
  border: none;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../../assets/deny.png");
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: -30px;
  background-position: center center;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  100% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
}

// .deny:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(1.2); /* 放大图片 */
// }

// .denyReally:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(0.8); /* 放大图片 */
// }

.denyReally {
  border: none;
  background-color: #f7f7f9;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../../assets/denyReally.png");
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -25px;
  right: 10px;
  background-position: center center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white !important;
  align-content: center;
}

.loading-message {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border-radius: 5px;
  color: #000;
  width: 100%;
  padding: 5px 10px;
  word-wrap: break-word;
  position: relative; /* Add this */
}

.hover-text {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -160%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

li {
  list-style-type: none;
}

.historycontend {
  border: none;
  font-size: 2vh;
  font-weight: bold;
  min-height: 54vh;
  max-height: 54vh;
  height: auto;
  overflow-x: hidden;
  text-align: center; /* 文本水平居中对齐 */
  bottom: 0;
  width: 90%;
  margin: 2vh 1vh 2vh 2vh;
}

.historyCard {
  height: 58px;
  border-radius: 5px;
  border: rgba(44, 43, 43, 0.2) 2px solid;
  margin-bottom: 5px;
  width: 98%;
  display: flex;
}

.historyCard:hover {
  color: var(--main-color);
  font-size: 16px;
}

.active {
  color: var(--main-color);
  font-size: 14px;
  background-color: rgba(139, 0, 0, 0.1);
  border: var(--main-color) 2px solid;
}

table.no-border {
  border-collapse: collapse !important;
  border: none !important;
}

/* 自定义滚动条样式 */
.historycontend::-webkit-scrollbar {
  width: 1vh; /* 滚动条宽度 */
}

.historycontend::-webkit-scrollbar-track {
  background-color: rgb(236, 184, 192);
  border-radius: 3vh;
}

.historycontend::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 3vh;
}

::v-deep .Historyimg {
  padding-left: 5px;
  height: 32px;
  width: 32px;
  border: none;
  background-image: url("../../assets/comelogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.HistoryCon {
  margin: 8px 2px 0 2px;
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.historyTime {
  margin: 8px 2px 0 2px;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.historyCard:hover .historyTime {
  display: none;
}
.hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px; /* 箭头宽度的一半 */
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.deny:hover .hover-text {
  display: block;
}

.newhistory {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  border: 5px solid #be0022;
  background-color: #be0022;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;
}

.clock {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  background-color: #fdf8f8;
  color: #bababa;
  font-size: 18px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;
}

/*小图标样式位置**/
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: rgb(86, 215, 255);
}

/*更换背景颜色**/
::v-deep .el-input__wrapper {
  font-size: 25px;
  width: 88px;
  height: 88px;
  background: url("../../assets/button.png") 50% center / cover no-repeat;
}

::v-deep .el-select .el-input__inner {
  border: none !important;
  color: #fff !important;
  font-size: 18px;
  width: 100px;
  height: 22px;
}
::v-deep .el-select-dropdown__item {
  font-size: 8px !important;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 20px;
}
::v-deep .el-input {
  font-size: 8px;
}

/*小图标隐藏**/

::v-deep .el-input__inner::placeholder {
  color: #fff;
  font-size: 14px;
}

::v-deep .el-input__wrapper {
  box-shadow: none !important;
}

.newlogo {
  background-image: url("../../assets/new.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.newClock {
  background-image: url("../../assets/clock.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.userPhoto {
  //background-image: url("../../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
}

.timeStyle {
  font-size: 12px;
  color: #2c2b2b;
  margin-right: 12px;
}

.news {
  background-color: white;
}

.communicate::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}
.communicate::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}
.communicate::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}
.communicate::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.communicate::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

.talk_con {
  background-image: url("../../assets/aiBg.png");
  //background: rgba(240, 76, 76, 0.1);
  border-radius:5px;
  //border: #000000 2px solid;
  height: 74vh;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  //border: #1c0101 5px solid;
}

.talk_show {
  width: 60%;
  height: 56vh;
  margin: 0 20% 0 20%;
  padding-top: 2%;
  //border:black 2px solid;
  /* overflow:auto; */
  overflow-y: scroll;
  /* overflow: hidden; */
}

.content {
  background-color: white;
  width: 100%;
  margin: 15px auto 0 auto;
  height: 95%;
  border-radius: 5px;
  //border: 5px solid black;
  .tip{
    margin-top: 15px;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    column-gap: 25px;
  }
  .tips{
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    background:linear-gradient(to right, rgb(230, 250, 250) 0%,  #f1e4d3 100%);
    height: fit-content;
    padding: 15px;
    .circle{
      width: 20px;
      height: 20px;
      background-image:url("../../assets/Circle.png");
      background-size:contain;
      background-position:center;
      background-repeat:no-repeat;
      background-color: transparent; /* 这里设置背景颜色为透明 */
    }
    .highlight{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      margin:-2px 10px;
    }
    .title{
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      margin:10px 0 0 0;
      text-align: justify;
    }
  }
}

.communicate {
  height: 88%;
  padding: 2% 5% 1% 5%;
  border-top: #f8f8f8 2px solid;
  width: 100%;
  background: white;
  font-size: 14px;
  overflow-y: auto;
  overflow-x: hidden;
  //border: 5px solid black;
}

//.arrow-content {
//  padding: 10px;
//  margin:5px 1% auto auto;
//  position: relative;
//  display: inline-block;
//  text-align: left;
//  color: #000;
//  border-radius: 5px;
//  background-color: rgba(46, 128, 5, 0.6);
//}
.triangle1 {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
  border-color: transparent transparent transparent rgba(46, 128, 5, 0.6); /* 调整边框颜色 */
  top: 15px; /* 控制三角形位置 */
  right: -14px; /* 控制三角形位置 */
  transform: translateY(-50%); /* 让三角形垂直居中 */
}
.right {
  text-align: left;
}

.talk_sub {
  position: absolute;
  cursor: pointer; /* 鼠标变成手型 */
  width: 6vh;
  height: 25px;
  float: left;
  margin-top: -4vh;
  bottom: 15px;
  right:5px;
  padding: 8px 10px;
  margin-left: 88%;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
  background-image: url("../../assets/send.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #050505;
}

.talk {
  display: inline-block;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  color: #000;
  margin: 8px 10% auto 5px;
  width: 100%;
  padding: 5px 10px;
  word-wrap: break-word;
  position: relative; /* Add this */
}
/* 添加尖嘴（三角形） */
.triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 8px; /* 调整边框宽度，0表示无顶部边框 */
  border-color: transparent #fffcfc transparent transparent; /* 调整边框颜色 */
  top: 15px; /* 控制三角形位置 */
  left: -15px; /* 控制三角形位置 */
  transform: translateY(-50%); /* 让三角形垂直居中 */
}
.talk_word {
  border: none;
  //min-height: 120px;
  height: fit-content;
  padding: 15px 7vh 5px 5px;
  overflow: auto;
  font-size: 16px;
  position: absolute;
  outline: none;
  width: 88%;
  bottom: 5px;
  float: left;
  text-indent: 10px;

  box-shadow: 2px -6px 0 2px #f8f8f8;
  //border: #F8F8F8 3px solid;
  border-top: #646262 2px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.select {
  background-image: url("../../assets/button.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  margin-top: auto;
  bottom: 5px;
  border: none;
}

.select1 {
  width: 25px;
  height: 20px;
  margin-top: auto;
  bottom: 0px;
  border: none;
}

::v-deep .talkcontent .el-input__inner {
  width: 100%;
  background-color: #e8e8e8;
  text-align: center;
  border-color: #c0c4cc;
  color: #000;
}

::v-deep .el-textarea .el-input__count {
  color: #909399;
  background: #fff;
  position: absolute;
  font-size: 18px;
  bottom: 5px;
  left: 10px;
}

::v-deep .el-textarea__inner {
  border: none !important;
  font-size: 18px !important;
  height: 86px;
  width: 98%;
}

.talkcontent {
  margin-left: 1%;
  min-height: 82px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 98%;
  max-height: 82px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 5px;
  overflow: auto;
  //border: black 5px solid; background-color: white; /* 半透明白色，alpha值为0.5 */
}

.atalk {
  margin: 5px;
  padding: 5px;
  display: flex;
  //background-color: white;
  position: relative;
  align-items: flex-start; /* Align items at the top */
}

.btalk {
  margin: 2px;
  right: 0 !important;
  display: flex;
  flex-direction: column;
  flex-grow: 8;
}

.AIphotoMove {
  min-width: 35px;
  background-image: url("../../assets/AIphoto.png");
  width: 35px;
  height: 35px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20%;
  margin-top: 5px;
  font-size: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.1); /* 放大到120% */
  }
  100% {
    transform: scale(1); /* 恢复到原始大小 */
  }
}

.AIphoto {
  min-width: 35px;
  background-image: url("../../assets/AiphotoNo.png");
  width: 35px;
  height: 35px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20%;
  margin-top: 5px;
  font-size: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

}


.userPhoto {
  //background-image: url("../../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
  width: 35px;
  height: 35px;
  margin-left: 8px;
  font-size: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  margin-top: 5px;
}

.arrow-content {
 background-color: #0267ff;
  border-radius: 5px;
  color: white;
  margin: 8px 5px auto auto;
  padding: 5px 10px;
  display: inline-block;
  min-height: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.title {
  color: black;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.whotalk {
  min-height: 88px;
  outline: none;
  max-height: 25px;
  padding: 5px;
  width: 80% !important;
  border-radius: 5px;
  border: none;
  //border-right: black 1px solid; width: 10%;
}

.talk_input {
  overflow: visible;
  display: flex; /* 启用Flexbox布局 */
  flex-direction: column; /* 子元素垂直排列 */
  align-items: center; /* 拉伸子元素以填充容器 */
  justify-content: center;;
 left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  border-radius: 5px;
  //box-shadow: 0 2px 0 2px rgba(100, 98, 98, 0.1);
  //border: rgba(44, 62, 80, 0.1) 1px solid;
  background-color: white;
  position: absolute;
  bottom: -5%;
  width:95%;
}

.input_container {
  height: fit-content;
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  width: 100%;
}

.talk_word {
  border: #111111 1px solid !important;
  border-radius: 5px !important;
  resize: vertical; /* 允许垂直调整大小 */
  min-height: 22px;
  height: auto;
  overflow-y: hidden;
  max-height: 200px;
  outline: none;
  width: 95%;
  padding: 10px;
  text-indent: 10px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;

}

.talk_button {
  display: flex;
  justify-content: space-between; /* 子元素之间的间隔平均分布 */
  cursor: pointer; /* 鼠标变成手型 */
  width: fit-content;

  padding: 15px;
  margin: auto 0 0 0;
}
.news1 {
  background-color: white;
  height: 65vh;
  .denyForm {
    overflow-y: hidden;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: white;
    width: 320px !important;
    height: 350px;
    border-radius: 5px;
    position: absolute;
    padding-bottom: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    ::v-deep .el-input {
      font-size: 14px;
    }
    .questioninput {
      //overflow: auto; /* 添加滚动条 */
      max-height: 8vh;
      width: 40vw;
      height: auto;
      border-radius: 5px;
      padding: 5px;
      margin-left: 5px;
      font-size: 14px;
      border: black 2px solid;

      .el-textarea__inner {
        height: 5vh;
      }
    }

    .ansinput {
      width: 40vw;
      margin-left: 6px;
      height: auto;
      border-radius: 5px;
      padding: 5px;
      min-height: 5vh;
      overflow: auto; /* 添加滚动条 */
    }
  }

  .talk_sub {
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 3.5vh;
    float: left;
    margin-top: -5vh;
    bottom: 5px;
    padding: 8px;
    margin-left: 81%;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-image: url("../../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #0468ff;
  }

  .content {
    width: 100%;
    margin: 1% auto;
    padding: 2% 2% 1% 2%;
    max-height: 65vh;
    min-height: 65vh;
    border-radius: 0;
    background: #f7f7f9;
  }

  .communicate {
    border-radius: 0;
    width: 100%;
    position: absolute;
    min-height: 65vh;
    max-height: 65vh;
    padding-bottom: 5vh;
    background: #f7f7f9;
    font-size: 14px;
    overflow-y: auto;
    //border: 5px solid black;
  }

  .atalk {
    margin: 2px;
    padding: 5px;
    display: flex;
    background-color: white;
    position: relative;
    align-items: flex-start; /* Align items at the top */
  }

  .btalk {
    margin: 2px;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 8;
  }

  .AIphoto {
    min-width: 25px;
    background-image: url("../../assets/AIphoto.png");
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .talk {
    display: inline-block;
    background-color: rgba(255, 255, 255);
    color: #000;
    margin-right: 0;
    //border: #2c3e50 1px solid; width: 100%; padding: 5px 10px; word-wrap: break-word; position: relative; /* Add this */
  }

  .userPhoto {
    //background-image: url("../../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin-top: 5px;
  }

  .arrow-content {
    padding: 10px;
    margin-left: 1%;
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-align: left;
    color: #000;
    font-weight: bold;
  }

  .con_que {
    color: #1c88ff;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
  }

  .czkj-question-msg {
    float: left;
    font-size: 14px;
    color: #3163c5;
  }

  .deny {
    border: none;
    background-color: #f7f7f9;
    margin-left: auto;
    position: absolute;
    display: block;
    cursor: pointer;
    background-image: url("../../assets/deny.png");
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -15px;
    right: 10px;
    background-position: center center;
  }

  .denyReally {
    border: none;
    background-color: #f7f7f9;
    margin-left: auto;
    position: absolute;
    display: block;
    cursor: pointer;
    background-image: url("../../assets/denyReally.png");
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -15px;
    right: 10px;
    background-position: center center;
  }

  .talk_input {
    position: absolute;
    width: 100%;
    height: 10vh;
    bottom: 110px;
  }

  .talk_word {
    min-height: 150px;
    outline: none;
    width: 80%;
    max-height: 30px;
    margin: 10px auto;
    text-indent: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 0 2px #8b0000;
    border: rgb(190, 0, 34) 3px solid;
    background-color: white; /* 半透明白色，alpha值为0.5 */
  }
  .notify-success {
    top: 1.7rem !important;
    right: 0.32rem !important;
    width: 150px !important;
    height: 0.96rem !important;
    background: rgba(131, 213, 134, 0.1) !important;
    border-radius: 0.04rem 0px 0px 0.04rem !important;
  }
}
</style>

