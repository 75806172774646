<template>
  <div>
    <div v-if="isMobile === false">
      <el-dialog
          title="请选择城市"
          :visible.sync="dialogVisible"
          width="45vw"
          :before-close="handleClose"
          top="15vh"
      >
        <div class="flexBox">
          <div class="searchBox">
            <!--              <el-dropdown>-->
            <!--                <span class="el-dropdown-link">-->
            <!--                  职位<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
            <!--                </span>-->
            <!--              </el-dropdown>-->
            <!--              <span class="spanStyle"> </span>-->

            <i style="margin-left: 15px" class="el-icon-search"></i>
            <el-input
                v-model="seachInput"
                class="searchContent"
                placeholder="请输入省份名称进行搜索"
            ></el-input>
          </div>
      </div>

       <div style="display: flex;height: 60vh">
         <div style="width: 25%;padding:10px 0;list-style-type:none;height:97%;overflow-y: auto" class="province">
           <li  v-for="(citys, index) in filteredResults" :key="index">
                  <div
                      :class="[citys.selected ? 'active' : '', 'citys']"
                      class="cityName"
                      @click="selectCity(citys)"
                  >{{ citys.name }}</div
                  >
           </li>
         </div>
         <div style="height: 100%;width: 1px;background-color: #ebebeb;"></div>
         <div style="width: 75%;padding:10px 0;list-style-type:none;height:97%;overflow-y: auto">
           <div class="countys">
             <li  v-for="(citys, index) in citys" :key="index">
               <div v-if="citys.selected===2" class="activenew countysName" @click="selectCounty(citys)">{{ citys.name }}</div>

               <div v-else :class="[citys.selected ? 'active' : '', 'citys']" class="countysName" @click="selectCounty(citys)">{{ citys.name }}</div>

             </li>
           </div>
           <div class="confirm" @click="confirm">确定</div>

         </div>

       </div>
      </el-dialog>

    </div>
    <div v-if="isMobile === true" style="font-size: 14px" class="phone">
      <el-dialog
          title="请选择城市"
          :visible.sync="dialogVisible"
          width="75%"
          :before-close="handleClose"
          top="35vh"
      >
        <span>欢迎使用油气人才，注册登录后体验完整服务~</span>
        <span slot="footer" class="dialog-footer">
          <el-button>
            <view @click="loginMember"></view>登录
          </el-button>
          <el-button type="primary">
            <view @click="loginMember"></view>注册
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getAuthorize } from "@/api/user";
import {proCityList} from "@/api/city"
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      cityOther:"",
      isMobile: false,
      languageSelect: "",
      seachInput:"",
      provinces: proCityList,
      citys: [
        {
          name: "北京",
        },
        {
          name: "上海",
        },
        {
          name: "天津",
        },
        {
          name: "浙江",
        },
        {
          name: "深圳",
        },
        {
          name: "杭州",
        }, {
          name: "西安",
        }, {
          name: "重庆",
        },



      ],
      select:'',

    };
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;

  },
  created() {
  },
  methods: {

    confirm(){
      this.$emit("cityOther",this.select);
      if(this.cityOther==="")
      {
        this.$message('请选择您的入职意向城市');
      }
      else{

        this.handleClose()
      }
    },
    selectCity(item){
      this.provinces.forEach((item) => {
        this.$set(item, 'selected', false);
      });
      // 使用 Vue.set 来更新响应式对象的属性
      this.$set(item, 'selected', true);
      item.selected = true;
      this.citys=item.children
      this.select=item.name
      console.log(item)
    },
    selectCounty(item){
      this.citys.forEach((item) => {
        this.$set(item, 'selected', false);
      });
      // 使用 Vue.set 来更新响应式对象的属性
      this.$set(item, 'selected', true);
      item.selected = true;

      this.cityOther=item.name
      this.select+="-"+item.name
      console.log( this.cityOther)
    },
    handleClose() {
      this.seachInput=""
      this.select=""
      this.provinces.forEach((item) => {
        this.$set(item, 'selected', false);
        item.children.forEach((child) => {
          this.$set(child, 'selected', false);
        });
      });
      this.provinces[0].selected=true
      this.selectCity(this.provinces[0])

      this.$emit("hideDialog");
    },
    loginMember() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
    },
  },
  computed: {
    filteredResults()
    {
      this.provinces.forEach((item) => {
        this.$set(item, 'selected', false);
      });

      if (!this.seachInput){
        this.$set(this.provinces[0], 'selected', false)
        this.selectCity(this.provinces[0]);
        return this.provinces;
      }
      // 先筛选出匹配项

      var filteredProvinces = this.provinces.filter(province =>
          province.name.toLowerCase().includes(this.seachInput.toLowerCase())
      );
      // 检查筛选结果是否为空
      if (filteredProvinces.length > 0) {
        filteredProvinces[0].selected = true;
        this.selectCity(filteredProvinces[0]);
      } else {
        for (var i=0;i<this.provinces.length;i++){
          for (const city of this.provinces[i].children) {
            if (city.name.toLowerCase().includes(this.seachInput.toLowerCase())) {
              filteredProvinces.push(this.provinces[i])
              this.$set(city, 'selected', 2);
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.cityOther=city.name
            }
          }
        }
        filteredProvinces[0].selected = true;
        this.selectCity(filteredProvinces[0]);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.cityOther=this.seachInput

      }

      return filteredProvinces;


    }
,


  },
  watch: {

  },
};
</script>
<style scoped lang="scss">
.phone {
  position: fixed;
  z-index: 9999999999;

  ::v-deep {
    .el-dialog__headerbtn{
      position: absolute;
      top:10px;
    }
    .el-dialog {
      margin: auto !important;
      font-size: 14px;

      top: 35vh;
    }
    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 5px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;
      a {
        display: block;
      }
    }
  }
}
.flexBox {
  position: absolute;
  top:-2px;
  left:30%;
  display: flex;
  border-radius: 4px;
  width: 60%;
  margin: 2.9vh auto 0 auto;
  font-size: 14px;
  background: #ffffff;
  opacity: 1;
  height: 30px;

  .spanStyle {
    width: 1px;
    font-size: 16px;
    height:25px;
    background: #d9d9d9;
  }

  .el-dropdown {
    width: 5.63vw;
    margin-left: 15px;
  }

  .el-dropdown-link {
    margin-left: 0.7vw;
    cursor: pointer;
    color: #333333;
  }

  .el-icon-arrow-down {
    font-size: 16px;
  }

  .searchBox {
    border: 1px solid #efefef;
    border-radius: 105px;
    width: 78%;
    height: 30px;
    margin: -4px 0;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid var(--main-color);
    ::v-deep .el-button {
      padding: 0.8vh 0.7vw !important;
    }

    .searchContent {
      //background-color: #efefef;
      //margin-left:15px;
      border-radius: 0;
      height:25px;

      ::v-deep .el-input__inner {
        height: 100%;
        width: 95%;
        background-color: #efefef;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #999;
      }
    }

    .searchTo {
      width: 6.7vw;
      height: 5.2vh;
      color: white;
      font-size: 14px;
      border: none;
      margin-right: 4px;
      background: #afafaf;
      border-radius:  105px;

      img {
        width: 1.1vw;
        height: 1.9vh;
      }
    }
  }

  .searchBox:hover {
    border: 1px solid #efefef;}

  .scanEr {
    top:3px;
    position: absolute;
    right: 25px;
    display: flex;
    background: #feffff;
  }
}
.cityName{
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  margin: 6px 6px 6px auto;
  color: #000;
  cursor: pointer;
}
.province::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}
.province::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}
.province::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}
.province::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.province::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}

.cityName:hover{
  font-weight: bold;
  color: var(--main-color);
}
.active{
  font-weight: bold;
  border: #ffefe5 1px solid;
  background-color: #ffefe5;
  color: var(--main-color);
}
.countys{

  display: flex; /* 使用 Flexbox 布局 */
  flex-wrap: wrap; /* 允许子元素换行 */
  padding: 5px 25px;
  .countysName{
    width: fit-content;
    background: #f8f9fb;
    border: 1px solid #f8f9fb;
    color: #333;
    border-radius: 8px;
    line-height: 20px;
    padding: 6px 10px;
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 15px;
    transition: none;
    align-items: center;
    cursor: pointer;
  }
  .countysName:hover{
    font-weight: bold;
    color: var(--main-color);}
  .active{
    font-weight: bold;
    border: #ffefe5 1px solid;
    background-color: #ffefe5;
    color: var(--main-color);
  }
  .activenew{
    font-weight: bold;
    border: var(--main-color) 1px solid;
    //background-color: #ffefe5;
    //color: var(--main-color);
  }
}
.confirm{
  position: absolute;
  bottom:25px;
  right:25px;
  background-color: #406cfb;
  padding: 10px 20px;
  border-radius: 5px;
  color:white;
  cursor: pointer;
}
::v-deep {
  .el-dialog{
    border-radius: 8px;
  }
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding: 0 15px;
    color: #606266;
    font-size: 14px;

    word-break: break-all;
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
</style>
