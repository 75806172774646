<template>

  <div>
    <div>
      <Header></Header>
      <!--  意见反馈-->
      <div v-if="isMobile === false">
        <div class="feedbackPc">
          <div class="left">
            <div class="title">
              意见反馈
            </div>
            <div class="information">
              <!--                      反馈内容  -->
              <div style="display: flex">
                <div class="photo"><img src="@/assets/important.png"></div>
                <div class="title">
                  反馈内容</div>
                <div class="titleInput" >
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 8, maxRows: 10 }"
                      placeholder="请协助填写问题描述，将有助于我们更快的发现和解决问题"
                      v-model="form.feedbackContent"
                  >
                  </el-input>
                </div>
              </div>
              <!--                       联系姓名-->
              <div style="display: flex">
                <div class="photo"><img src="@/assets/important.png"></div>

                <div class="title">联系姓名</div>
                <div class="titleInput">
                  <el-input
                      v-model="form.userName"
                      max-length="20"
                      placeholder="请输入您的姓名"
                  ></el-input>
                </div>
              </div>
              <!--                       联系电话-->
              <div style="display: flex">
                <div class="photo"><img src="@/assets/important.png"></div>
                <div class="title">联系电话</div>
                <div class="titleInput">
                  <el-input
                      v-model="form.phoneNum"
                      max-length="20"
                      placeholder="请务必输入手机号码，后续将通过该电话与您联系"
                  ></el-input>
                </div>
              </div>
              <!--                       联系邮箱-->
              <div style="display: flex">
                <div class="title" style="margin-left: 5px">联系邮箱</div>
                <div class="titleInput">
                  <el-input
                      v-model="form.email"
                      max-length="20"
                      placeholder="请输入您的邮箱"
                  ></el-input>
                </div>
              </div>

              <div>
                <div class="submit" @click="submit">提交</div>
              </div>
            </div>
          </div>
          <div style="margin-top: -18px" >
            <div class="jianli" v-if="userId">
              <PersonalLittle></PersonalLittle>
            </div>
            <div class="recommend2" v-else>
              <div @click="showLoginIn1()" class="advertisement">
                <div class="title">
                </div>
              </div>
              <div @click="showLoginIn()" class="advertisement2">
              </div>
            </div>
          </div>


        </div>
        <Footer></Footer>

      </div>
      <div v-else>
        <div class="feedback">
          <div class="title">
            意见反馈
          </div>
          <div class="information">
            <!--                      反馈内容  -->
            <div>
              <div style="display: flex"><div class="photo"><img src="@/assets/important.png"></div>
                <div class="title">反馈内容</div>
              </div>
              <div class="titleInput" >
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 5 }"
                    placeholder="请协助填写问题描述，将有助于我们更快的发现和解决问题"
                    v-model="form.feedbackContent"
                >
                </el-input>
              </div>
            </div>
            <!--                       联系姓名-->
            <div>
              <div style="display: flex"><div class="photo"><img src="@/assets/important.png"></div>
                <div class="title">联系姓名</div>
              </div>
              <div class="titleInput">
                <el-input
                    v-model="form.userName"
                    max-length="20"
                    placeholder="请输入您的姓名"
                ></el-input>
              </div>
            </div>
            <!--                       联系电话-->
            <div>
              <div style="display: flex"><div class="photo"><img src="@/assets/important.png"></div>
                <div class="title">联系电话</div>
              </div>

              <div class="titleInput">
                <el-input
                    v-model="form.phoneNum"
                    max-length="20"
                    placeholder="请务必输入手机号码，后续将通过该电话与您联系"
                ></el-input>
              </div>
            </div>
            <!--                       联系邮箱-->
            <div>
              <div class="title" style="margin-left: 5px">联系邮箱</div>
              <div class="titleInput">
                <el-input
                    v-model="form.email"
                    max-length="20"
                    placeholder="请输入您的邮箱"
                ></el-input>
              </div>
            </div>

            <div>
              <div class="submit" @click="submit">提交</div>
            </div>
          </div>

        </div>
        <bottomMobile></bottomMobile>
      </div>

    </div>

  </div>

</template>


<script>

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import bottomMobile from "@/components/bottomMobile.vue";
import PersonalLittle from "@/components/PersonalLittle.vue";
import {getAuthorize} from "@/api/user";
export default {

  name: "Feedback",

  props: [],

  components: {PersonalLittle, Header, bottomMobile,Footer},

  data() {

    return {
      form:[],
      isMobile: false,
      loginIn:false,
      userId: localStorage.getItem("userId"),

    }

  },

  methods: {
    showLoginIn1() {
      // this.$router.push("/login")
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        console.log(res, "ress");
        window.location.replace(res.data.url);
      });
    },
    showLoginIn() {
      this.loginIn = true;
    },
    submit() {
      var formJob = {
        userName: this.form.userName,
        phoneNum: this.form.phoneNum,
        feedbackContent: this.form.feedbackContent,
        email: this.form.email,
            };
      if (!formJob.feedbackContent) {
        this.$message.error("请输入您的反馈意见");
        return false;
      }
      if (!formJob.phoneNum) {
        this.$message.error("请输入您的联系号码");
        return false;
      }
      if (!formJob.userName) {
        this.$message.error("请输入您的姓名");
        return false;
      }

        this.$axios
            .post( "/system/job_feedback", {
              userName: this.form.userName,
              phoneNum: this.form.phoneNum,
              feedbackContent: this.form.feedbackContent,
              email: this.form.email,
            }, {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
              },
            })
            .then((res) => {
              console.log(res.data)
              this.$message({
                message: '提交成功',
                type: 'success'
              });

            })
            .catch((err) => {
              console.log(err);
            });

    },
  },

  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;


  }

}

</script>


<style type="text/scss" lang="scss" scoped>

.feedbackPc{
  width: 1200px;
  margin:18px auto;
  display: flex;
  position: relative;
  .left{
    width: 920px;
    background-color: white;
    padding: 0 35px;
    .title{
      margin-top: 20px;
      text-align: center;
      font-size:22px;
      padding: 15px 0 10px 0;
      //border-top: 1px solid gray;

      //border-bottom: 1px solid #dcdcdc;
    }
    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      row-gap: 25px;
      padding:5px 15px;
      .photo{
        width: 5px;
        height: 5px;
      }
      .title{
        height: fit-content;
        width: fit-content;
        font-size: 14px;
        display: flex;
        margin-top: 5px;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: #000000;
        border:none;
        line-height: 14px;
        -webkit-background-clip: text;
      }
      .titleInput{
        margin-top:5px;
        width:85%;
        margin-left: 25px;
        ::v-deep .el-input__inner{
          border: 1px solid #DCDFE6 !important;
        }
      }
      .submit{
        cursor: pointer;
        width: 50%;
        color: white;
        text-align: center;
        padding: 15px 0;
        margin:35px auto 15px auto;
        border-radius: 53px;
        background-color: #0101fd;
      }
    }
  }

  .jianli {
    margin: 20px 0 0 20px;
    width: 260px;
    height: 267px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .recommend2 {
    right: 0;
    position: absolute;
    width: 280px;
    height: 340px;
    top: 0px;
    //box-shadow: 0px 0px 1.4vh 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    .item {
      width: 100%;
      cursor: pointer;
      padding: 2.3vh 1vw;
      font-size: 18px;
      color: #333333;
    }
    .item:hover {
      background: var(--main-color);
      color: #fff;
    }
    .item:not(:last-child) {
      border-bottom: 1px dotted #cecece;
    }
  }
  .advertisement {
    position: absolute;
    right: 0;
    width: 260px;
    height: 170px;
    border-radius: 5px;
    cursor: pointer;
    background-image: url("../assets/login.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: black 10px solid;
    .logo {
      opacity: 12;
      width: 50%;
      height: 100%;
      //background-image: url("../../assets/login.png");
      //background-size: contain;
      //background-position: center;
      //background-repeat: no-repeat;
      //background-color: transparent; /* 这里设置背景颜色为透明 */
    }
    .title {
      line-height: 30px;
      color: white;
      text-align: right;

      top: 15px;
      position: absolute;
      right: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }
  .advertisement2 {
    top: 180px;
    margin-top: 8px;
    position: absolute;
    right: 0;
    width: 260px;
    height: 170px;
    border-radius: 5px;
    //background-color: #30539d;
    cursor: pointer;
    background-image: url("../assets/login2.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: black 10px solid;

    .title {
      line-height: 30px;
      color: white;
      text-align: right;

      top: 15px;
      position: absolute;
      right: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }
}

.feedback{
  margin-top: 45px;

  .title{
    margin-top: 45px;
    text-align: center;
    font-size: 16px;
    padding: 15px 0 10px 0;
    border-top: 1px solid gray;

    border-bottom: 1px solid #dcdcdc;
  }
  .information{
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: 0;
    padding:5px 15px;
    .photo{
      width: 5px;
      height: 5px;
    }
    .title{
      height: fit-content;
      width: fit-content;
      font-size: 14px;
      display: flex;
      margin-top: 5px;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      color: #000000;
      border:none;
      line-height: 14px;
      -webkit-background-clip: text;
    }
    .titleInput{
      margin-top:5px;
      ::v-deep .el-input__inner{

      }
      .sexStyle1{
        width: 50px;
        height: 25px;
        background: #F8E9ECFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #BF0022;
        font-size: 16px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .sexStyle2{
        width: 50px;
        height: 25px;
        background: #FDFDFDFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #f5f5f5;
        opacity: 1;
        font-size: 16px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: gray;
        cursor: pointer;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .submit{
      width: 90%;
      color: white;
      text-align: center;
      padding: 10px 0;
      margin:35px auto 15px auto;
      border-radius: 53px;
      background-color: #0101fd;
    }
  }
}


</style>
