<template>

  <div>
    <div class="MySubscriptions wrapper">

<!--      <transition name="mask-fade">-->
<!--        <div v-if="showMask" class="mask">-->

<!--          <div style="text-align: center">-->
<!--            <dv-loading>Loading...</dv-loading>-->
<!--            打开GPT简历小助手，开启AI之旅吧-->
<!--          </div>-->

<!--        </div>-->
<!--      </transition>-->
      <div>
        <div  style="display: grid ;grid-template-columns:minmax(0,1.5fr) minmax(0,1fr);gap:1px;margin:0 auto 15px auto;position: relative">
          <div class="box" >

            <!--      个人信息-->
            <div class="box1">
              <div style="display: flex;position:relative;">
                <div class="Photo">
                  <!--            <el-upload-->
                  <!--                class="avatar-uploader boxCardUploadImg"-->
                  <!--                :action="baseURL + '/common/upload'"-->
                  <!--                :show-file-list="false"-->
                  <!--                :on-success="handleAvatarSuccess"-->
                  <!--                :before-upload="beforeAvatarUpload"-->
                  <!--                :on-error="handleAvatarError"-->
                  <!--                accept=".png,.jpg,.jpeg"-->
                  <!--            >-->
                  <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />
                  <!--            </el-upload>-->
                </div>
                <div style="margin-left: 16px">
                  <div v-if="form.userName">
                    <div class="userName" v-if="form.nickName">
                      {{ form.nickName }}({{ form.userName }})
                    </div>
                    <div class="userName" v-else>油气人才({{ form.userName }})</div>
                  </div>
                  <div v-else>
                    <div class="userName" v-if="form.nickName">
                      {{ form.nickName }}
                    </div>
                    <div class="userName" v-else>油气人才</div>
                  </div>
                  <div style="display: flex;margin-top: 20px">
                    <div class="iconStyle"><img loading="lazy" src="../../assets/sex.png"/></div>
                    <div v-if="form.sex!==null">
                      <div class="userSex" v-if="form.sex==0">男</div>
                      <div class="userSex" v-if="form.sex==1">女</div>
                    </div>
                    <div class="userSex" v-else>---</div>
                    <div class="iconStyle"><img loading="lazy" src="../../assets/workHour.png" /></div>
                    <div class="userSex" v-if="form.workHour !== null">
                      {{ form.workHour }}
                    </div>
                    <div v-else class="userSex">---</div>
                    <div class="iconStyle"><img loading="lazy" src="../../assets/yesr.png"/></div>
                    <div class="userSex" v-if="form.birthday!==null">{{ form.birthday }}岁</div>
                    <div v-else class="userSex">---</div>
                    <div class="iconStyle"><img loading="lazy" src="../../assets/work.png"/></div>
                    <div class="userSex" v-if="form.jobStatus!==null">{{ form.jobStatus}}</div>
                    <div v-else class="userSex">---</div>
                  </div>
                  <div style="display: flex;margin-top: 21px">
                    <div class="iconStyle"><i class="el-icon-mobile-phone"></i></div>
                    <div class="userSex" v-if="form.phonenumber!==null">{{ form.phonenumber}}</div>
                    <div v-else class="userSex">---</div>
                    <div class="iconStyle"><i class="el-icon-message"></i></div>
                    <div class="userSex" v-if="form.email!==null">{{ form.email }}</div>
                    <div v-else class="userSex">---</div>
                  </div>
                </div>
              </div>
              <div style=" margin-top: 15px;padding: 0 14px 0 0;">
                <div class="jobName">优势亮点：</div>
                <div>
                  <div class="jobClass" style="margin:16px 15px 0 0; width: 100%;font-size: 14px;
                  color:gray" v-if="form.advantages !== null">
                    {{form.advantages}}
                  </div>
                  <div
                      style="margin:16px 15px 0 15px; width: 100%;"
                      v-else
                  >
                    <div
                        style="
                  width: 250px;
                  height: auto;
                  font-size: 14px;
                  color:gray
                "
                    >
                      编辑优势亮点，展示个人态度!
                    </div>
                  </div>

                </div>


              </div>
            </div>
            <!--      求职期望-->
            <div class="box2" >
              <div>
                <div class="jobName">求职期望</div>
                <div class="jobClass" v-if="form.expectations.length!==0">
                  <li v-for="(jobs,index) in form.expectations" :key="index">
                    <div class="jobStyle">
                      <div class="workName">{{jobs.position}}</div>
                      <div class="span"></div>
                      <div class="workName">{{jobs.salary}}</div>
                      <div class="span"></div>
                      <div class="workName">{{jobs.industry}}</div>
                      <div class="span"></div>
                      <div class="workName">{{jobs.city}}</div>

                      <div class="actions">
                      </div>
                    </div>
                  </li>

                </div>
                <div style="margin-top: 21px;width: 100%;text-align: center" v-else>
                  <div style="width: 250px;height: auto;font-size: 16px;margin:0 auto">
                    <img loading="lazy" src="../../assets/none.png" alt="" />
                    暂无数据，快去添加吧
                  </div>
                </div>
              </div>



            </div>
            <!--      工作经历-->
            <div class="box2">
              <div>
                <div class="jobName">工作经历</div>
                <div class="workClass" v-if="form.workExperience.length!==0">
                  <li v-for="(works,index) in form.workExperience" :key="index">
                    <div class="workStyle">
                      <div style="display: flex;justify-content:space-between">
                        <div v-if="works.workExperienceOverseas=='0'">
                          <div class="workCompanyName">
                            {{ works.workCompanyName }}(海外工作经历)
                          </div>
                        </div>
                        <div v-else>
                          <div class="workCompanyName">
                            {{ works.workCompanyName }}
                          </div>
                        </div>
                        <div class="workTime" style="margin-right: 15px">{{works.workStartTime}}至{{works.workEndTime}}</div>
                      </div>
                      <div style="margin-top: 16px;display: flex">
                        <div class="industry">所在行业：</div>
                        <div class="indus">{{ works.workIndustry }}</div>
                      </div>
                      <div style="margin-top: 16px;display: flex">
                        <div class="industry2">工作内容：</div>
                        <div class="indusContent">{{ works.workExperienceContent }}</div>
                      </div>


                    </div>
                  </li>

                </div>

                <div style="margin-top: 21px;width: 100%;text-align: center" v-else>
                  <div style="width: 250px;height: auto;font-size: 16px;margin:0 auto">
                    <img loading="lazy" src="../../assets/none.png" alt="" />
                    暂无数据，快去添加吧
                  </div>
                </div>
              </div>
            </div>
            <!--      教育经历-->
            <div class="box2">
              <div>
                <div class="jobName">教育经历</div>
                <div class="workClass" v-if="form.eduExperience.length!==0">
                  <li v-for="(edus,index) in form.eduExperience" :key="index">
                    <div class="workStyle">
                      <div style="display: flex;width: 100%">
                        <div class="photo">
                          <img src="@/assets/education.png">
                        </div>
                        <div style="width: 100%">
                          <div style="display: flex">
                            <div class="workCompanyName">
                              {{ edus.eduExperienceLocation }}
                            </div>
                            <div v-if="edus.eduRally==='0'" style="background-color: #bd0303;color:white;font-size: 8px;border-top-left-radius: 9px;border-bottom-right-radius: 9px;height: fit-content;padding: 2px 5px;margin: -5px 0 auto 5px">
                              统招
                            </div>
                            <div class="workTime">
                              {{ edus.eduStartTime }}至{{ edus.eduEndTime }}
                            </div>
                          </div>
                          <div style="margin-top: 16px; display: flex">
                            <div class="workName">{{ edus.eduSpeciality }}</div>
                            <div class="span"></div>
                            <div class="workName">{{ edus.eduEducation }}</div>
                          </div>
                          <div style="margin-top: 16px; display: flex;margin-left: -80px">
                            <div class="industry">论文/毕设描述：</div>
                            <div class="indusLunwen">{{ edus.eduPaper }}</div>
                          </div>
                          <div style="margin-top: 16px; display: flex;margin-left: -80px">
                            <div class="industry2">所获荣誉：</div>
                            <div class="indusContent">{{ edus.eduHonor }}</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </li>
                </div>
                <div style="margin-top: 21px;width: 100%;text-align: center" v-else>
                  <div style="width: 250px;height: auto;font-size: 16px;margin:0 auto">
                    <img loading="lazy" src="../../assets/none.png" alt="" />
                    暂无数据，快去添加吧
                  </div>
                </div>
              </div>
            </div>
            <!--      项目经历-->
            <div class="box2">
              <div>
                <div class="jobName">项目经历</div>
                <div class="workClass" v-if="form.projectExperience.length!==0">
                  <li v-for="(pros,index) in form.projectExperience" :key="index">
                    <div class="workStyle">
                      <div style="display: flex;justify-content:space-between">
                        <div class="workCompanyName">{{pros.projectName}}</div>
                        <div class="workCompanyName">{{pros.projectRole}}</div>
                        <div class="workTime" style="margin-right: 15px">{{pros.projectStartTime}}至{{pros.projectEndTime}}</div>
                      </div>
                      <div style="margin-top: 16px;display: flex">
                        <div class="industry">项目描述：</div>
                        <div class="indus">{{ pros.projectContent }}</div>
                      </div>
                      <div style="margin-top: 16px;display: flex">
                        <div class="industry2">项目业绩：</div>
                        <div class="indusContent">{{pros.projectPerformance }}</div>
                      </div>

                    </div>
                  </li>
                </div>
                <div style="margin-top: 21px;width: 100%;text-align: center" v-else>
                  <div style="width: 250px;height: auto;font-size: 16px;margin:0 auto">
                    <img loading="lazy" src="../../assets/none.png" alt="" />
                    暂无数据，快去添加吧
                  </div>
                </div>
              </div>
            </div>
            <!--      <div class="contain">-->
            <!--        <el-form-->
            <!--            :label-position="labelPosition"-->
            <!--            label-width="100px"-->
            <!--            :model="form"-->
            <!--        >-->
            <!--          <el-form-item label="头像" class="avatorBox" required>-->
            <!--            <el-upload-->
            <!--                class="avatar-uploader boxCardUploadImg"-->
            <!--                :action="baseURL + '/common/upload'"-->
            <!--                :show-file-list="false"-->
            <!--                :on-success="handleAvatarSuccess"-->
            <!--                :before-upload="beforeAvatarUpload"-->
            <!--                :on-error="handleAvatarError"-->
            <!--                accept=".png,.jpg,.jpeg"-->
            <!--            >-->
            <!--              <img :src="form.avatar ? baseURL + form.avatar : avator" class="avatar" />-->
            <!--              <div class="wrap">-->
            <!--                <div>上传头像</div>-->
            <!--              </div>-->
            <!--            </el-upload>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="昵称" required>-->
            <!--            <el-input v-model="form.username" max-length="20" placeholder="请输入昵称，最多可输入20字"></el-input>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="姓名" required>-->
            <!--            <el-input v-model="form.realname" placeholder="请输入姓名"></el-input>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="性别" required>-->
            <!--            <el-select v-model="form.sex" placeholder="请选择性别">-->
            <!--              <el-option-->
            <!--                  v-for="item in options"-->
            <!--                  :key="item.value"-->
            <!--                  :label="item.label"-->
            <!--                  :value="item.value"-->
            <!--              >-->
            <!--              </el-option>-->
            <!--            </el-select>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="出生年月" required>-->
            <!--            <el-date-picker-->
            <!--                v-model="form.birthday"-->
            <!--                type="date"-->
            <!--                placeholder="选择日期"-->
            <!--            >-->
            <!--            </el-date-picker>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="所在行业" required>-->
            <!--            <el-input-->
            <!--                v-model="form.industry"-->
            <!--                placeholder="请输入所在行业"-->
            <!--            ></el-input>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="所属公司" required>-->
            <!--            <el-input-->
            <!--                v-model="form.company"-->
            <!--                placeholder="请输入所属公司"-->
            <!--            ></el-input>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="职务" required>-->
            <!--            <el-input v-model="form.duty" placeholder="请输入职务"></el-input>-->
            <!--          </el-form-item>-->
            <!--          <el-form-item label="阅读兴趣" required>-->
            <!--            <el-input-->
            <!--                v-model="form.interest"-->
            <!--                placeholder="请输入阅读兴趣"-->
            <!--            ></el-input>-->
            <!--          </el-form-item>-->
            <!--          <div class="submit" @click="submit()">确认</div>-->
            <!--        </el-form>-->
            <!--      </div>-->
          </div>

          <div>
            <AIAnswer></AIAnswer>
          </div>



        </div>
      </div>
      <LoginIn
          :dialogVisible="loginIn"
          @hideDialog="hideLoginIn"
          @register="showRegister"
          @forget="forget"
          @logsuccess="logsuccess"
      ></LoginIn>
      <RegisterIn
          :dialogVisible="registerIn"
          @hideDialog="hideregisterIn"
          @login="login"
      ></RegisterIn>
      <forgetIn
          :dialogVisible="forgetIn"
          @hideDialog="hidereforgetIn"
          @login="login"
      ></forgetIn>
    </div>
  </div>

</template>
<script>
import {eduUpdate, jobUpdate, proUpdate, userUpdate, workUpdate} from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import AIAnswer from "@/views/PrimaryClassfy/AIAnswer.vue";
import LoginIn from "@/views/userlog/LoginIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
export default {
  name: "AIresume",
  components: { RegisterIn, forgetIn, LoginIn,AIAnswer},
  data() {
    return {
      dialogVisibleInfo: false,
      registerIn:false,
      forgetIn:false,
      loginIn:false,
      showMask: true, // 控制是否显示蒙版
      isMobile: false,
      editInfo:0,
      jobAdd:1,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      labelPosition: "left",
      avator: require("@/assets/avator.png"),
      options: [],
      optionsCity:[],
      optionsType:[],
      optionsIndustry:[],
      optionsSalary:[],
      optionsEdu:[],
      file: {},
      form: {},
      userId: localStorage.getItem("userId"),
      // userId:2,
      token: localStorage.getItem("access_token" ),
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",

    };
  },
  mounted() {
    setTimeout(() => {
      this.showMask = false; // 在一段时间后隐藏蒙版，触发过渡效果
    }, 1000); // 设置一个延迟时间，例如1秒后隐藏蒙版
    window.scrollTo(0, 0); // 在页面加载时滚动到页面顶部
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  computed: {},
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    this.getUser();
    this.jobStatus()
    this.jobCity()
    this.jobType()
    this.jobIndustry()
    this.jobSalary()
    this.jobEdu()
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    hideDialog() {
      this.dialogVisible = false;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideLoginIn(){
      this.loginIn=false

    },
    logsuccess(){
      this.loginIn=false
      this.userId= this.$store.state.userId
      window.location.reload();

    },
    showRegister(){
      this.loginIn=false
      this.registerIn=true
    },
    forget(){
      this.loginIn=false
      this.forgetIn=true
    },
    hideregisterIn(){
      this.registerIn=false
    },
    hidereforgetIn(){
      this.forgetIn=false
    },
    login(){
      this.loginIn=true
      this.registerIn=false
    },
    pushLogin(){
      this.loginIn=true
    },
    // 修改性别
    sexChange(item){
      this.form.sex=item
      console.log("sex",this.form.sex)
    },
    // 获取期望职位状态
    jobStatus(){
      this.$axios.get(
          "/system/status/list"
      ).then((res) => {
        this.options=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取城市
    jobCity(){
      this.$axios.get(
          "/system/city/list"
      ).then((res) => {
        this.optionsCity=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取职位状态
    jobType(){
      this.$axios.get(
          "/system/type/list"
      ).then((res) => {
        this.optionsType=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取行业
    jobIndustry(){
      this.$axios.get(
          "/system/industry/list"
      ).then((res) => {
        this.optionsIndustry=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取薪资
    jobSalary(){
      this.$axios.get(
          "/system/salary/list"
      ).then((res) => {
        this.optionsSalary=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取学历
    jobEdu(){
      this.$axios.get(
          "/system/education/list"
      ).then((res) => {
        this.optionsEdu=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 基础信息
    edit(){
      this.editInfo=1
    },

    // 期望求职
    addJob(){
      this.form.expectations.push({
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        id:Date.now(),
        userId: parseInt(this.userId)
      }
      ]
      this.$axios.post(
          "/system/expectations",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    editJob(item){
      item.jobedit=1
      this.$forceUpdate();
    },
    deleteJob(item){
      this.$axios.delete(
          "/system/expectations/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            // this.$router.go();
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });

    },

    //工作经历
    addwork(){
      this.form.workExperience.push({
        workCompanyName: "",
        workPositionName: "",
        workIndustry: "",
        workStartTime: "",
        workEndTime: "",
        workExperienceContent: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        id:Date.now(),
        userId: parseInt(this.userId)
      }
      ]
      this.$axios.post(
          "/system/work_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    deleteWork(item){
      this.$axios.delete(
          "/system/work_experience/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 教育经历
    addedu(){

      this.form.eduExperience.push({
        eduEducation: "",
        eduEndTime: "",
        eduExperienceLocation: "",
        eduHonor: "",
        eduPaper: "",
        eduSpeciality: "",
        eduStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        eduEducation: "",
        eduEndTime: "",
        eduExperienceLocation: "",
        eduHonor: "",
        eduPaper: "",
        eduSpeciality: "",
        eduStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      }
      ]
      this.$axios.post(
          "/system/edu_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    editEdu(item){
      console.log(item)
      item.jobedit=1
      this.$forceUpdate();
    },
    deleteEdu(item){
      this.$axios.delete(
          "/system/edu_experience/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 项目经历
    addPro(){

      this.form.projectExperience.push({
        projectContent: "",
        projectEndTime: "",
        projectName: "",
        projectPerformance: "",
        projectRole: "",
        projectStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        projectContent: "",
        projectEndTime: "",
        projectName: "",
        projectPerformance: "",
        projectRole: "",
        projectStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      }
      ]
      this.$axios.post(
          "/system/project_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });
    },
    editPro(item){
      console.log(item)
      item.jobedit=1
      this.$forceUpdate();
    },
    deletePro(item){
      this.$axios.delete(
          "/system/project_experience"+item.id
      ).then((res) => {
        this.$message({
          message: res.data, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取简历
    getUser() {
      this.$axios.get(
          "/system/job_user/"+this.userId
      ).then((res) => {
        this.form=res.data.data
        console.log(this.form)
        var birthDate = new Date(this.form.birthday);
        // 获取当前日期
        var today = new Date();
        // 获取当前年份
        var currentYear = today.getFullYear();

        // 计算年龄
        var age = currentYear - birthDate.getFullYear();

        // 如果生日还没有到，年龄需要减去1
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }

        this.form.birthday=age
        console.log(this.form.workExperience.workStartTime)
        // 将日期字符串转换为Date对象
        var start = new Date(this.form.workHour);
        var end = new Date();

        // 计算年份差
        var yearsDiff = end.getFullYear() - start.getFullYear();

        // 如果开始年份的日期大于结束年份的日期，则年份差减1
        if (start.getMonth() > end.getMonth() ||
            (start.getMonth() === end.getMonth() && start.getDate() > end.getDate())) {
          yearsDiff--;
        }

        // 计算月份差
        var monthsDiff = end.getMonth() - start.getMonth();

        // 如果月份差为负数，说明开始年份的日期在结束年份的日期之后，需要调整
        if (monthsDiff < 0) {
          monthsDiff += 12; // 加上12个月
          yearsDiff--;
        }
        console.log("gongzuo",yearsDiff)
        if(yearsDiff>0&&monthsDiff>0){
          this.form.workHour="工作"+yearsDiff+"年零"+monthsDiff+"个月"
        }else if(yearsDiff==0){
          this.form.workHour="工作"+monthsDiff+"个月"
        }
        else if(monthsDiff==0){
          this.form.workHour="工作"+yearsDiff+"年"
        }


        this.form.expectations.forEach(function(element) {
          element.jobedit=0

        });
        this.form.workExperience.forEach(function(element) {
          element.jobedit=0
        });
        this.form.eduExperience.forEach(function(element) {
          element.jobedit=0
        });
        this.form.projectExperience.forEach(function(element) {
          element.jobedit=0
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 期望求职
    cancleSubmit(){
      this.getUser()
    },
    confirmSubmit() {
      if (!this.form.userName) {
        this.$message.error("请输入您的昵称");
        return false;
      }

      if (this.form.sex===null) {
        this.$message.error("请选择您的性别");
        return false;
      }
      if (!this.form.birthday) {
        this.$message.error("请选择您的出生年月");
        return false;
      }
      if (!this.form.phonenumber) {
        this.$message.error("请输入您的手机号码");
        return false;
      }
      if (!this.form.email) {
        this.$message.error("请输入您的邮箱");
        return false;
      }

      // this.form.status=this.form.jobStatus
      userUpdate(this.form).then((res) => {
        localStorage.setItem("typeInfo", 1);
        this.$store.state.typeInfo = 1;
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    // 期望
    confirmSubmitJob(jobs){
      var formJob={
        id: jobs.id,
        userId: jobs.userId,
        type: jobs.type,
        position: jobs.position,
        salary: jobs.salary,
        industry:jobs.industry,
        city: jobs.city
      }
      if(!formJob.type){
        this.$message.error("请输入您期望的职位类型");
        return false;
      }
      if(!formJob.position){
        this.$message.error("请输入您期望的职位");
        return false;
      }
      if(!formJob.salary){
        this.$message.error("请输入您期望的薪资范围");
        return false;
      }
      if(!formJob.industry){
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if(!formJob.city){
        this.$message.error("请输入您期望的工作地点");
        return false;
      }

      jobUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitJob(){
      this.getUser()
    },
    // 工作
    confirmSubmitWork(jobs){
      var formJob={
        id: jobs.id,
        userId: jobs.userId,
        workCompanyName: jobs.workCompanyName,
        workEndTime: jobs.workEndTime,
        workExperienceContent: jobs.workExperienceContent,
        workIndustry:jobs.workIndustry,
        workPositionName: jobs.workPositionName,
        workStartTime: jobs.workStartTime
      }
      if(!formJob.workCompanyName){
        this.$message.error("请输入您任职的公司名称");
        return false;
      }
      if(!formJob.workStartTime){
        this.$message.error("请输入您的入职时间");
        return false;
      }
      if(!formJob.workEndTime){
        this.$message.error("请输入您的离职时间");
        return false;
      }
      if(!formJob.workIndustry){
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if(!formJob.workExperienceContent){
        this.$message.error("请输入您期望的工作内容");
        return false;
      }
      workUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitWork(){
      this.getUser()
    },
    // 教育
    confirmSubmitEdu(jobs){
      var formJob={
        eduEducation: jobs.eduEducation,
        eduEndTime: jobs.eduEndTime,
        eduExperienceLocation:jobs.eduExperienceLocation,
        eduHonor:jobs.eduHonor,
        eduPaper:jobs.eduPaper,
        eduSpeciality: jobs.eduSpeciality,
        eduStartTime: jobs.eduStartTime,
        id: jobs.id,
        userId: jobs.userId
      }
      if(!formJob.eduExperienceLocation){
        this.$message.error("请输入您的学校名称");
        return false;
      }
      if(!formJob.eduStartTime){
        this.$message.error("请输入您的入学时间");
        return false;
      }
      if(!formJob.eduEndTime){
        this.$message.error("请输入您的毕业时间");
        return false;
      }

      if(!formJob.eduHonor){
        this.$message.error("请输入您在校期间所获荣誉");
        return false;
      }
      eduUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitEdu(){
      this.getUser()
    },
    // 项目
    confirmSubmitPro(jobs){
      var formJob={

        id: jobs.id,
        projectContent: jobs.projectContent,
        projectEndTime:jobs.projectEndTime,
        projectName: jobs.projectName,
        projectPerformance: jobs.projectPerformance,
        projectRole: jobs.projectRole,
        projectStartTime:jobs.projectStartTime ,
        userId: jobs.userId
      }
      if(!formJob.projectName){
        this.$message.error("请输入项目名称");
        return false;
      }
      if(!formJob.projectStartTime){
        this.$message.error("请输入项目开始时间");
        return false;
      }
      if(!formJob.projectEndTime){
        this.$message.error("请输入项目结束时间");
        return false;
      }

      if(!formJob.projectContent){
        this.$message.error("请简要介绍您的项目");
        return false;
      }
      if(!formJob.projectPerformance){
        this.$message.error("请简要介绍您的项目成果");
        return false;
      }
      proUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitPro(){
      this.getUser()
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 文件上传失败（http）
    handleAvatarError() {
      this.$message.error("上传失败! （http失败）");
    },

  },
};
</script>
<style scoped lang="scss">
.mask {
  color: #8B0000;
  font-size: 85px;
  line-height: 100px;
  position: fixed;
  z-index: 9999999999999;
  top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffdfd;
  opacity: 1;
  transition: transform 15s, opacity 12s;
  animation: rotateScale 3s linear infinite;
}

@keyframes rotateScale {

  /* Add more keyframes as needed for additional circles */
}

.mask-fade-enter-active {
  transition: opacity 1s, transform 1s;
}

.mask-fade-leave-active {
  transition: opacity 1s, transform 1s;
}

.mask-fade-enter, .mask-fade-leave-to {
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

.mask-fade-enter-to, .mask-fade-leave {
  opacity: 0;
  transform: rotate(360deg) scale(0);
}
.box {
  position: relative;
  width: 708px;
  border-radius: 5px;
  overflow: auto;
  background: #fff;
  .box1{
    ::v-deep .el-input__prefix {
      left: 250px;
      transition: all .3s;
    }
    height: fit-content;
    padding: 24px 30px 26px 30px ;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    .cancleStyle{
      margin-top: 46px;
      width: 128px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color:  #BF0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle{

      margin-top: 46px;
      margin-left: 20px;

      border: none;
      width: 128px;
      height: 44px;
      background-color: #BF0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
      position: relative;
    }

    ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    ::v-deep .el-input__suffix {
      height: 100%;
      left: 240px;
      transition: all .3s;
      pointer-events: none;
    }

    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 48px;
      row-gap: 20px;
      padding-top: 32px;
      .title{
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
        -webkit-background-clip: text;
      }
      .titleInput{
        margin-top: 12px;
        .sexStyle1{
          width: 145px;
          height: 44px;
          background: #F8E9ECFF;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #BF0022;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #BF0022;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;

        }
        .sexStyle2{
          width: 145px;
          height: 44px;
          background: #FDFDFDFF;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border:none;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: gray;

          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .editStyle{
      position: absolute;
      top:0;
      display: flex;
      right:0;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;

      .editLogo{
        width: 13px;
        height: 12px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .editTitle{
        width: 28px;
        height: 16px;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 16px;
        margin-left:7px ;
      }
    }

    .Photo{
      width: 80px;
      height: 80px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .userName{
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #333333;
      line-height: 24px;

    }
    .userName1{
      margin-top: 9px;
      width: 80px;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 24px;
      -webkit-background-clip: text;

    }
    .userName2{
      margin-top: 14px;
      width: 720px;
      color: gray;
      height: 24px;
      opacity: 1;

    }
    .userSex{
      height: 16px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-left: 6px;
      margin-right: 32px;
    }
    .iconStyle{
      width: 14px;
      height: 15px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }
  .box2{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-top: 16px;
    height: fit-content;
    padding: 23px 14px 16px 14px ;
    .cancleStyle{
      margin-top: 20px;
      width: 128px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color:  #BF0022;
      line-height: 20px;
      -webkit-background-clip: text;
    }
    .confirmStyle{

      margin-top: 20px;
      margin-left: 20px;

      border: none;
      width: 128px;
      height: 44px;
      background-color: #BF0022;
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .information{
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 48px;
      row-gap: 20px;

    }
    .title{
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
      -webkit-background-clip: text;
    }
    .titleInput{
      margin-top: 12px;
      .sexStyle1{
        width: 145px;
        height: 44px;
        background: #F8E9ECFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #BF0022;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

      }
      .sexStyle2{
        width: 145px;
        height: 44px;
        background: #FDFDFDFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border:none;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: gray;

        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .addStyle{

      position: absolute;
      display: flex;
      right: 30px;

    }
    .addStyle2{
      position: absolute;
      display: flex;
      right: 16px;
    }
    .addStyle1{
      position: absolute;
      display: flex;
      right: 96px;

    }
    .workStyle2{
      position: absolute;
      display: flex;
      right: 16px;
      top:19px;
    }
    .workStyle1{
      position: absolute;
      display: flex;
      right: 96px;
      top:19px;

    }
    .addLogo{
      width: 16px;
      height: 16px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .addTitle{
      width: 28px;
      height: 16px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 16px;
      margin-left:7px ;
    }
    .jobName{
      margin-left: 16px;
      width: 80px;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }
    .jobName1{
      width: 80px;
      height: 24px;
      font-size: 20px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: #222222;
      line-height: 24px;
    }
    .jobClass{

      margin-top: 21px;
      width: 100%;
      list-style: none;
      .jobStyle{
        position: relative;
        display: flex;
        height: fit-content;
        padding:8px 16px ;
      }
      .jobStyle1{
        position: relative;
        height: fit-content;
        width: 100%;
        margin-top: 12px;
      }
      .actions {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      .jobStyle:hover{
        background: #F8F8F8;
      }
      .jobStyle:hover .actions {
        opacity: 1;
        visibility: visible;
      }
      .workName{
        height: 24px;
        font-size: 14px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #222222;
        line-height: 24px;
      }
      .span{
        width: 1px;
        height: 12px;
        background: #CCCCCC;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 6px 10px;
      }



    }
    .workClass{

      width: 100%;
      list-style: none;
      .workStyle{
        margin-top:21px ;
        position: relative;
        width: 100%;
        height:auto;
        padding: 19px 0 19px 16px;
        background:  #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        .photo{
          width:70px;
          padding:0 5px;
          background-color: #e9eaea;
          margin:0 15px 0 0;
          border-radius: 5px;
          height: fit-content;
          border: white 1px solid;


        }
        .workName{
          height: 24px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
        .span{
          width: 1px;
          height: 12px;
          background: #CCCCCC;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          margin: 6px 10px;
        }
        .workCompanyName{
          margin-top:4px;
          height: 16px;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .workTime{
          height: 24px;
          font-size: 12px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-left: 16px;
        }
        .industry{
          margin-top: 4px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }
        .indus{
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 87%;
          height: fit-content;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .indusLunwen{
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 80%;
          height: fit-content;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .industry2{
          width: 70px;
          height: 28px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
        .indusContent{
          word-wrap: break-word; /* 允许在单词内换行 */
          width: 85%;
          height: auto;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
      }
      .workActions {
        opacity: 0;

        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
      .workStyle:hover{
        background: #F8F8F8;
      }
      .workStyle:hover .workActions {
        opacity: 1;
        margin-right:-55px;
        visibility: visible;
      }

    }

  }

  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-input__suffix {
    height: 100%;
    left: 240px;
    transition: all .3s;
    pointer-events: none;
  }
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    margin: 0 30px;
    padding-bottom: 64px;
    padding-top: 30px;
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      border-radius: 28px;
      background: var(--main-color);
      margin-left: 100px;

      margin-top: 56px;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar,
  .boxCardUploadImg,
  .box .avatar-uploader-icon,
  .el-upload {
    width: 80px !important;
    height: 80px !important;
    display: block !important;
    border-radius: 36px;
  }
  .el-upload {
    position: relative;
    overflow: hidden;
  }

  .boxCardUploadImg .el-upload div.wrap {
    // display: none;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    div {
      width: 72px;
      height: 24px;
      line-height: 20px;
      color: #ffffff;
      font-size: 10px;
      background: rgba($color: #000000, $alpha: 0.4);
      overflow: hidden !important;
    }
  }
}

.box::-webkit-scrollbar-track-piece {
  background-color: #f8f8f800;
}
.box::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}
.box::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 100px;
}
.box::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.box::-webkit-scrollbar-corner {
  background-color: rgba(255, 255, 255, 0);
}


::v-deep {
  .contain {
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      line-height: 50px;
      height: 50px;
      font-size: 16px;
      background: inherit;
      color: #333;
    }
    .el-input__inner::placeholder {
      color: #bbbbbb;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #dcdfe6;
    }
    .el-form-item {
      width: fit-content;
      margin-bottom: 36px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-form-item__content {
      width: 400px;
      background: #f5f5f7;
      border-radius: 4px;
    }
    .avatorBox .el-form-item__content {
      width: auto;
      background: none;
      border-radius: 100%;
    }
    .el-input__prefix {
      left: auto;
      right: 5px;
      .el-input__icon.el-icon-date {
        transform: rotate(180deg);
        font-size: 20px;
        &:before {
          content: "\e6e1";
        }
      }
    }
    .el-input--prefix .el-input__inner {
      padding-left: 15px;
    }
    .el-select__caret {
      font-size: 20px;
    }
  }
}
input {
  border: none;
  outline: none;
}
.box .avatar-uploader-icon {
  width: 72px !important;
  height: 72px !important;
  display: block !important;
  border-radius: 36px;
  line-height: 72px;
}
</style>
