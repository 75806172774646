<template>
  <div>
    <div class="MySubscriptions wrapper">
      <keep-alive>
        <Header></Header>
      </keep-alive>
      <div>
        <div>
          <div class="bgAI">
            <div ref="animationContainer">
              <div class="talk_con">
                <div class="content">
                  <div class="communicate" ref="messageContainer">
                    <div class="atalk">
                      <div class="AIlogo">
                        <img  ref="avatarElement" src="../../assets/ai.png" alt="头像" />
                      </div>
                      <span id="asay" style="display: flex">
              <div v-if="languageSelect==='zh'" class="talk" id="whose1">
                您好，我是您的面试小助手小安，我会根据您的简历给您提供几个面试问题，请用心回答，回答结束之后我会给您此次面试结果打分
              </div>
            </span>
                    </div>

                    <div class="talk-show">
                      <div v-for="message in messages" :key="message.id" class="message"
                           :class="message.sender === 'user' ? 'user' : 'ai'">
                        <div v-if="message.sender === 'user'">
                          <div class="btalk">
                            <div style="display: flex;max-width: 80%">

                              <div style="width: 100%">
                                <span><div class="arrow-content"> {{ message.text }}</div></span>
                              </div>
                              <div><img class="userPhoto" :src="userInfo.avatar ? baseURL + userInfo.avatar : avator" alt="User Avatar"/></div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="atalk">
                            <div class="AIlogo">
                              <img  ref="avatarElement" src="../../assets/ai.png" alt="头像" />
                            </div>
                            <span id="asay" style="">
                      <div class="talk" id="whose1">
                        {{ message.answer }}
                      </div>
                              <!--                      <div v-for="(item2, index) in message.recourse" :key="index">-->
                              <!--                        <div class="con_que" @click="clickRobot(item2)"-->
                              <!--                        >-->
                              <!--                          <div class="czkj-question-msg">-->
                              <!--                            {{ item2.index }}-->
                              <!--                            {{ item2.title }}-->
                              <!--                          </div>-->
                              <!--                        </div>-->
                              <!--                      </div>-->
                    <button v-if="isTextDisplayed && message.deny === 0" @click="denyChange(message)" data-info="info"
                            class="deny" id="deny">
                     <span class="hover-text" style="display: none"></span>
                    </button>
                    <button v-if="isTextDisplayed && message.deny === 1" @click="denyChange(message)"
                            data-info="{'change': 1}"
                            class="denyReally" id="deny">
                      <span class="hover-text" style="display: none"></span>
                    </button>
                    </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div >

                  </div>

                  <div class="talk_input">
                    <div>
                    <textarea
                        v-if="languageSelect==='zh'"
                        placeholder="请输入需要咨询的问题"
                        class="talk_word"
                        style="border:none;min-height:40px;height:auto;padding:5px 7vh 5px 5px;overflow:auto;font-size: 14px"
                        maxlength="3000"
                        id="text"
                        :rows="1"
                        v-model="newMessage"
                        @keyup.enter="sendMessage()"
                    ></textarea>
                      <el-button class="talk_sub" :disabled="isSendingMessage" @keydown.enter="sendMessage()"
                                 @click="sendMessage()" type="primary"></el-button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

<!--          <textarea v-model="textToSpeak" placeholder="输入要转换为语音的文本"></textarea>-->
<!--          <button @click="speakText">转换并播放语音</button>-->
        </div>
      </div>
    </div>


    <Footer v-if="isMobile === false"></Footer>
    <BottomBanner v-if="isMobile === true"></BottomBanner>、
  </div>

</template>


<script>
import gsap from 'gsap'; // 导入 GSAP 库
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {BottomBanner, Footer,Header},

  data() {

    return {
      textToSpeak: '',
      dialogVisible: false,
      isMobile: false,
      titleEn: '',
      contentEn: '',
      languageSelect: '',
      isClicked: false,
      userInfo: '',
      isLogin: '',
      isNEWs: 0,
      isTextDisplayed: false,
      isSendingMessage: false,
      messages: [],
      newMessage: '',
      Message: '',
      token: '',
      baseURL: this.$utils.baseURL,
      avator: require("@/assets/avator.png"),
      userId: '',
      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: '',
        question: '',
        answer: '',
      },
      primary: '',
      submitForm: {
        id: '',
        question: '',
        answer: '',
        primary: ''
      },
      textarea: '',
      deny: 0,
      count: 0,
      uniqueId: '',
      question: '',
      answer: '',
      options: [{
        value: '1',
        label: '新建对话'
      }, {
        value: '2',
        label: '历史记录'
      }, ],
      optionvalue: '1',
      showPopover: false,
      timer: null,
      articles: [],

    }

  },

  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    this.playAnimation();
    window.scrollTo(0, 0); // 在页面加载时滚动到页面顶部
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    playAnimation() {
      const textElement = this.$refs.textElement;
      const avatarElement = this.$refs.avatarElement;

      gsap.to(textElement, {
        opacity: 0,
        duration: 0.5,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut' // 更换为你想要的缓动函数
      });

      gsap.to(avatarElement, {
        opacity: 0,
        duration: 0.5,
        yoyo: true,
        repeat: -1,
        scale: 1.1,
        boxShadow: '0 0 20px 5px rgba(255, 255, 255, 0.8)', // 5px 的白色光圈
        ease: 'power1.inOut' // 更换为你想要的缓动函数
      });

    },
    speakText() {
      const message = new SpeechSynthesisUtterance(this.textToSpeak);

      // 语音设置（可选）
      message.volume = 1; // 音量（0 到 1）
      message.rate = 1; // 语速（默认是 1）
      message.pitch = 1; // 语调（默认是 1）

      // 播放语音
      const speechSynthesis = window.speechSynthesis;
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
      speechSynthesis.speak(message);

    },
    handleClose() {
      this.denyformShow = true
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, 'showDeleteButton', true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, 'showDeleteButton', false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item){
      console.log('Selected value:', item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },
    // 文章索引
    clickRobot(item) {
      this.isNEWs = 1
      this.$router.push({
        path: "/articalDetail",
        query: {
          Typeid: item.primaryTypeid,
          newId: item.id },
      });
      this.$axios.get('/api/news/' + item.id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token
        }
      },)
          .then(res => {
            this.articles = res.data.data
            const currentDate = new Date(this.articles.createTime);
            const year = currentDate.getFullYear();
            let month = this.dateIfAddZero(currentDate.getMonth() + 1)
            let day = this.dateIfAddZero(currentDate.getDate());
            const formattedDate = `${year}-${month}-${day}`;
            this.articles.dateTime = formattedDate

          })
          .catch(error => {
            console.log(error)
          })

      var vm = this
      vm.$axios.post("/systemenes/trans", {
        // params: {
        newsId: item.id,
        // },
        headers: {
          Authorization: vm.token,
        },
      })
          .then((res) => {
            // console.log(res.data.data.enTitle)
            vm.titleEn = res.data.data.enTitle
            vm.contentEn = res.data.data.enContent
          })
          .catch((error) => {
            console.log(error);
          });

    },
    // 新建对话
    newContent() {
      this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
      this.messages = []
      this.count = 0
      this.isSendingMessage = false;
      this.historylist();
      if (this.languageSelect === "zh") {
        this.$notify.warning({
          title: '提示',
          message: '新建对话成功，我们将重新开始新一轮对话。',
          showClose: false,
          customClass:'notify-success',

        });
      } else {
        this.$notify.warning({
          title: 'Tips',
          message: 'If the new dialogue is successful, we will start a new round of dialogue',
          showClose: false,
          customClass:'notify-success'
        });
      }

    },
    // 历史记录轮次
    historylist() {
      // console.log("ddd")
      // console.log(parseInt(this.userId))
      this.$axios.get('api/qa/selectAll', {
        params: {
          userId: this.userId
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': parseInt(this.userId)
        }
      },)
          .then(res => {
            this.communicateHistory = res.data
            for (var i = 0; i < this.communicateHistory.length; i++) {

              const currentDate = new Date(this.communicateHistory[i].createTime)
              const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
              const day = ('0' + currentDate.getDate()).slice(-2)
              const hours = ('0' + currentDate.getHours()).slice(-2)
              const min = ('0' + currentDate.getMinutes()).slice(-2)
              const formattedDate = `${month}-${day} ${hours}:${min}`
              this.communicateHistory[i].createTime = formattedDate
              this.communicateHistory[i].isClicked = false
            }

          })
          .catch(error => {
            console.log(error)
          })

    },
    // 删除历史
    deleteHistory(item){
      console.log(item.roundId)

      console.log("删除")
    },
    // 详细的历史记录
    HisCommunicate(communicateHistory) {
      for (var i = 0; i < this.communicateHistory.length; i++) {
        this.communicateHistory[i].isClicked = false
      }

      communicateHistory.isClicked = true
      console.log(communicateHistory);
      this.$axios.get('/api/qa/selectByRoundId/' + communicateHistory.roundId, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.token
        }
      })
          .then(res => {
            console.log("详细")
            console.log(res.data)
            this.isTextDisplayed = true;
            this.messages = []
            for (var i = 0; i < res.data.length; i++) {
              this.uniqueId = communicateHistory.roundId
              this.messages.push({
                id: res.data[i].id,
                sender: 'user',
                text: res.data[i].qaQuery,
              })
              if (res.data[i].qaEvaluate) {
                res.data[i].qaEvaluate = 1
              } else {
                res.data[i].qaEvaluate = 0
              }
              this.messages.push({ // 使用保存的vm
                id: res.data[i].id,
                sender: 'ai',
                answer: res.data[i].qaAnswers,
                deny: res.data[i].qaEvaluate,
                question: res.data[i].qaQuery,
              });
            }
          })
    },
    // 点否提交
    submitDeny() {
      this.submitForm.answer = this.denyForm.answer
      this.denyformShow = false
      console.log(this.denyForm)
      console.log(this.submitForm)
      // 假设您有一个要更新的 message 对象
      this.messages = this.messages.map(message => {
        if (message.id === this.submitForm.id) {
          message.deny = 1; // 将匹配条件下的 message 的 deny 属性设置为 1
        }
        return message; // 返回更新后的 message
      });

      console.log(this.messages); // 打印更新后的 messages 数组


      this.$axios.put('/api/qa/update',
          {
            'id': this.submitForm.id,
            "qaEvaluationContent": this.denyForm.answer,
            "qaAnswers": this.denyForm.answer,
            "qaQuery": this.submitForm.primary,
            "qaEvaluate": 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': this.token
            }
          }).then(res => {
        console.log(res)
      })


    },
    // 点否重置
    resetDeny() {
      this.denyForm.question = this.submitForm.question
      this.denyForm.answer = this.submitForm.primary
    },
    changeWho() {
      this.showPopover = false
    },

    sendMessage() {

      // 清除之前的定时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
        this.messages = []
        this.count = 0
        this.isSendingMessage = false;
        this.historylist();
        this.$notify.warning({
          title: '提示',
          message: '当前超出30min未进行对话，请刷新重试',
          showClose: true,
        });

      }, 1800000); // 等于 60,000*30 毫秒

      // 禁用发送按钮
      this.isSendingMessage = true;
      this.count++;
      if (this.uniqueId === "") {
        this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
      }

      if (this.newMessage) {
        // 问题
        this.question = this.newMessage;
        this.messages.push({
          id: this.uniqueId,
          sender: 'user',
          text: this.newMessage,
        });

        // 先添加“稍后再试”消息
        this.messages.push({
          id: '',
          sender: 'ai',
          answer: "正在生成，请稍候...",
          deny: 0,
          question: this.question,
        });

        if (this.count === 21) {
          this.uniqueId = 'id-' + Math.random().toString(36).substr(2, 9);
          this.messages = []
          this.count = 0
          this.isSendingMessage = false;
          this.historylist();
          this.$notify.warning({
            title: '提示',
            message: '当前对话轮次超出小安的能力限制啦，我们将重新开始新一轮对话。',
            showClose: false,
          });

        } else {
          this.newMessage = '';
          setTimeout(() => {
            var vm = this; // 保存this
            this.$axios
                .post('system/qa/get_answer', {
                  question: this.question,
                  round_id: this.uniqueId,
                  userId: this.userId,
                }, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token
                  },
                })
                .then(async function (response) {

                  var resindex = []
                  for (var i = 0; i < response.data.answer.length; i++) {
                    resindex.push({
                      index: i + 1,
                      title: response.data.answer[i]
                    })
                  }
                  vm.isSendingMessage = false;
                  // 查找索引以替换“稍后再试”消息
                  const index = vm.messages.findIndex((message) => message.id === '' && message.sender === 'ai');
                  // 若存在这样的信息
                  if (index !== -1) {
                    // 逐字显示回答
                    vm.$set(vm.messages[index], 'id', response.data.id); // 更改ID
                    // 主子输出
                    await vm.printAnswerMessage(response.data.answer, vm, index, resindex);
                    // vm.$set(vm.messages[index], 'recourse', resindex);
                    this.$nextTick(() => {
                      // 在下一个tick时滚动到底部
                      const container = this.$refs.messageContainer;
                      container.scrollTop = container.scrollHeight;
                    });
                    // console.log("huidu ")
                    // console.log(vm.messages)

                  }
                });
          }, 2000); // 2000毫秒（2秒）

        }
      } else {
        this.$notify.warning({
          title: '提示',
          message: '请输入你的问题',
          showClose: false,
        });

      }

      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async printAnswerMessage(message, vm, index, resindex) {
      let currentIndex = 0;
      let printedMessage = '';

      const printFunction = () => {
        if (currentIndex < message.length) {
          printedMessage += message[currentIndex];
          vm.messages[index].answer = printedMessage;
          currentIndex++;
          setTimeout(printFunction, 50);
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        } else {
          vm.$set(vm.messages[index], 'recourse', resindex); // 更改ID
          // 当消息逐字显示完成后，设置 isTextDisplayed 为 true
          this.isTextDisplayed = true;
          this.$nextTick(() => {
            const container = this.$refs.messageContainer;
            container.scrollTop = container.scrollHeight;
          });
        }
      };
      printFunction();
    },


    // 点否点击
    denyChange(event) {
      // 获取按钮自带的 data-info 参数
      if (event.deny === 0) {
        this.denyformShow = true
        this.submitForm.question = event.question
        this.submitForm.answer = event.answer
        this.submitForm.primary = event.answer
        this.submitForm.id = event.id
        this.denyForm.question = this.submitForm.question
        this.denyForm.answer = this.submitForm.primary
      } else {
        this.messages = this.messages.map(message => {
          if (message.id === this.submitForm.id) {
            message.deny = 0; // 将匹配条件下的 message 的 deny 属性设置为 1
          }
          return message; // 返回更新后的 message
        });

        console.log(this.messages); // 打印更新后的 messages 数组
      }


    },
  },
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    this.token = localStorage.getItem('access_token');
    this.userId = localStorage.getItem("userId");
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(this.userInfo.avatar)
    this.historylist()
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },

  watch: {
    optionvalue(newValue) {
      console.log('Selected value:', newValue);
      if(newValue==='1'){
        this.newContent()
      }

      // 在这里可以处理选项变化后的逻辑
    }
  }

}

</script>


<style scoped lang='scss'>

.bgAI{
  width: 1200px;
  height: 85vh;
  margin:15px auto;
  padding:20px;
  border-radius: 15px;
  background-color: black;
  .AIlogo{
    width: 30px;
    height: 30px;
    margin-left: 25px;
    min-width: 30px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
textarea {
  width: 300px;
  height: 100px;
  resize: none;
}
.notify-custom-class {
  /* 在这里放置自定义通知样式 */
  background-color: #f0ad4e; /* 设置背景颜色为黄色 */
  color: #fff; /* 设置文本颜色为白色 */
  font-size: 16px; /* 设置字体大小 */
  /* 添加其他样式设置 */
}
.notLogin {
  text-align: center;
  color: #666666;
  font-size: 18px;
  display: flex;
  align-content: center;
  flex-direction: column;
  padding: 100px 0px;

  img {
    width: 306px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.buttons-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.deleteStyle {
  display: none; /* 初始状态下隐藏删除按钮 */
}

.historyCard:hover .deleteStyle {
  margin-top: 5%;
  margin-left: 40px;
  display: inline-block; /* 鼠标悬停在.historyCard上时显示删除按钮 */
}
.questioninput {
  //overflow: auto; /* 添加滚动条 */
  max-height: 8vh;
  width: 32VW;
  font-size: 16px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  border: black 2px solid;

  .el-textarea__inner {
    height: 5vh;
  }
}

.ansinput {
  width: 32VW;
  font-size: 16px;
  margin-left: 6px;
  height: auto;
  border-radius: 5px;
  padding: 5px;
  min-height: 13vh;
  max-height: 13vh;
  overflow: auto; /* 添加滚动条 */
}

::v-deep .el-dialog__headerbtn {
  cursor: pointer;
  font-size: 25px;
  font-weight: 800;
}
.phone{
  position: fixed;

  ::v-deep {
    .el-dialog{
      margin:auto !important;
      font-size: 14px;
      top: 35vh;
    }
    .el-dialog__header {
      padding:5px;
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        padding:15px;
        font-size: 18px;

      }

    }
    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height:28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;
      a {
        display: block;
      }
    }

  }

}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
    a {
      display: block;
    }
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
.fload {
  width: 15px;
  height: 75vh;
  background-image: url("../../assets/fload.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
}

::v-deep .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: #f8f8f8 !important;
}
::v-deep .el-notification__group {
  /* 修改宽度和字体 */
  width: 250px !important; /* 设置宽度为 300px */
  font-size: 8px; /* 设置字体大小为 16px */
}

::v-deep el-dialog__close el-icon el-icon-close {
  color: white;
  background-image: url("../../assets/exit.png") !important;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0px;
  right: 20px;
  padding: 0;
  border: none;
  outline: 0;
  color: white;
  background-image: url("../../assets/exit.png") !important;
  cursor: pointer;
  font-size: 25px;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0px !important;
  background: #FFF;
  border-radius: 2px;
  box-shadow: 8px 5px 8px 3px rgba(0, 0, 0, .3);
  box-sizing: border-box;
  width: 100%;
}

::v-deep .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-dialog__header {
  padding: 0;
}

.con_que {
  color: #1c88ff;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.czkj-question-msg {
  float: left;
  font-size: 14px;
  color: #3163c5;
}

.horizontal-line2 {
  margin-top: 2vh;
  width: 88%; /* 设置分界线的宽度 */
  height: 1px; /* 设置分界线的高度 */
  margin-left: 5%;
  text-align: center;
  color: gray;
  background-color: rgba(128, 128, 128, 0.2); /* 设置分界线的颜色 */
}

.denyForm {
  overflow-y: hidden;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: white;
  width: 48vw;
  height: 48vh;
  border-radius: 15px;
  position: absolute;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.deny {
  border: none;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../../assets/deny.png");
  background-color: transparent;
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: -30px;
  background-position: center center;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  100% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
}

// .deny:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(1.2); /* 放大图片 */
// }

// .denyReally:hover {
// //animation: shake 0.5s; //animation-iteration-count: 2; transform: scale(0.8); /* 放大图片 */
// }

.denyReally {
  border: none;
  background-color: #f7f7f9;
  margin-left: auto;
  position: absolute;
  display: block;
  cursor: pointer;
  background-image: url("../../assets/denyReally.png");
  width: 25px;
  height: 25px;
  margin-top: auto;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -25px;
  right: 10px;
  background-position: center center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white !important;
  align-content: center;
}

.loading-message {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border-radius: 5px;
  color: #000;
  width: 100%;
  padding: 5px 10px;
  word-wrap: break-word;
  position: relative; /* Add this */
}

.hover-text {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -160%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

li {
  list-style-type: none;
}

.historycontend {
  border: none;
  font-size: 2vh;
  font-weight: bold;
  min-height: 54vh;
  max-height: 54vh;
  height: auto;
  overflow-x: hidden;
  text-align: center; /* 文本水平居中对齐 */
  bottom: 0;
  width: 90%;
  margin: 2vh 1vh 2vh 2vh;
}

.historyCard {
  height: 58px;
  border-radius: 15px;
  border: rgba(44, 43, 43, 0.2) 2px solid;
  margin-bottom: 5px;
  width: 98%;
  display: flex;
}

.historyCard:hover {
  color: var(--main-color);
  font-size: 16px;
}

.active {
  color: var(--main-color);;
  font-size: 14px;
  background-color: rgba(139, 0, 0, 0.1);
  border: var(--main-color) 2px solid;
}

table.no-border {
  border-collapse: collapse !important;
  border: none !important;
}


/* 自定义滚动条样式 */
.historycontend::-webkit-scrollbar {
  width: 1vh; /* 滚动条宽度 */
}

.historycontend::-webkit-scrollbar-track {
  background-color: rgb(236, 184, 192);
  border-radius: 3vh;
}

.historycontend::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 3vh;
}

::v-deep .Historyimg {
  padding-left: 5px;
  height: 32px;
  width: 32px;
  border: none;
  background-image: url("../../assets/comelogo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.HistoryCon {
  margin: 8px 2px 0 2px;
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis
}

.historyTime {
  margin: 8px 2px 0 2px;
  width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}
.historyCard:hover .historyTime{
  display: none;
}
.hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px; /* 箭头宽度的一半 */
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.deny:hover .hover-text {
  display: block;
}

.newhistory {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  border: 5px solid #be0022;;
  background-color: #be0022;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;

}

.clock {
  margin: 1vw;
  width: 87%;
  height: 5vh;
  background-color: #fdf8f8;
  color: #bababa;
  font-size: 18px;
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  text-align: center; /* 文本水平居中对齐 */
  border-radius: 25px;
}

/*小图标样式位置**/
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: rgb(86, 215, 255);
}

/*更换背景颜色**/
::v-deep .el-input__wrapper {
  font-size: 25px;
  width: 88px;
  height: 88px;
  background: url('../../assets/button.png') 50% center / cover no-repeat
}

::v-deep .el-select .el-input__inner {

  border: none !important;
  color: #fff !important;
  font-size: 18px;
  width: 100px;
  height: 22px;
}
::v-deep .el-select-dropdown__item{
  font-size: 8px!important;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
::v-deep .el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all .3s;
  line-height: 20px;
}
::v-deep .el-input {

  font-size: 8px;
}

/*小图标隐藏**/

::v-deep .el-input__inner::placeholder {
  color: #fff;
  font-size: 14px;
}


::v-deep .el-input__wrapper {
  box-shadow: none !important;
}



.newlogo {
  background-image: url("../../assets/new.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.newClock {
  background-image: url("../../assets/clock.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.userPhoto {
  //background-image: url("../../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;

}

.timeStyle {
  font-size: 12px;
  color: #2c2b2b;
  margin-right: 12px;
}

.news {
  background-color: white;
}

.talk_con {

  height: 45vh;
  //position: absolute;/;

}


.talk_show {
  width: 60%;
  height: 60vh;
  margin: 0 20% 0 20%;
  padding-top: 2%;
  //border:black 2px solid;
  /* overflow:auto; */
  overflow-y: scroll;
  /* overflow: hidden; */
}

.content {
  width: 100%;
  margin:0 auto 0 auto;
  height: 98%;
  border-radius: 15px;
  background: transparent;
  //border: 5px solid black;
}

.communicate {

  height:60vh;
  padding: 2% 10% 1% 10%;
  font-size: 14px;
  overflow-y: auto;
  //border: 5px solid black;
}

.arrow-content {
  border-radius: 5px;
  margin-right: 15px;
  padding:5px 10px;
  position: relative;
  display: inline-block;
  text-align: left;
  color: #000;
  background-color: white;
}

.right {
  text-align: left;
}


.talk_sub {
  cursor: pointer; /* 鼠标变成手型 */
  width: 6vh;
  height: 3.5vh;
  float: left;
  margin-top: -4vh;
  bottom: 0px;
  padding: 8px;
  margin-left: 81%;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
  background-image: url("../../assets/send.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #bd0022;
}

.talk {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border-radius: 5px;
  color: #000;
  margin-left: 12px;
  margin-right: 10%;
  width: 100%;
  padding: 5px 10px;
  word-wrap: break-word;
  position: relative; /* Add this */
}

.talk_word {
  min-height: 150px;
  outline: none;
  width: 71%;
  margin-left: 10%;
  max-height: 30px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 0 2px #8B0000;
  border: rgb(190, 0, 34) 3px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.select {
  background-image: url("../../assets/button.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  margin-top: auto;
  bottom: 5px;
  border: none
}

.select1 {
  width: 25px;
  height: 20px;
  margin-top: auto;
  bottom: 0px;
  border: none
}

::v-deep .talkcontent .el-input__inner {
  width: 100%;
  background-color: #e8e8e8;
  text-align: center;
  border-color: #c0c4cc;
  color: #000
}

::v-deep .el-textarea .el-input__count {
  color: #909399;
  background: #FFF;
  position: absolute;
  font-size: 18px;
  bottom: 5px;
  left: 10px;
}

::v-deep .el-textarea__inner {
  border: none !important;
  font-size: 18px !important;
  height: 86px;
  width: 98%;
}

.talkcontent {
  margin-left: 1%;
  min-height: 82px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 98%;
  max-height: 82px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  //border: black 5px solid; background-color: white; /* 半透明白色，alpha值为0.5 */
}

.atalk {
  margin: 10px;

  display: flex;
  position: relative;
  align-items: flex-start; /* Align items at the top */
}

.btalk {
  margin: 20px;
  right: 0 !important;
  display: flex;
  flex-grow: 8;
  /* 设置为水平排列 */
  justify-content: flex-end; /* 将项目靠右对齐 */
}


.AIphoto {
  min-width: 30px;
  background-image: url("../../assets/AIphoto.png");
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 20%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.talk {
  display: inline-block;
  background-color: rgba(255, 255, 255);
  border-radius: 5px;
  color: #000;
  margin-right: 0;
  //border: #2c3e50 1px solid; width: 100%; padding: 5px 10px; word-wrap: break-word; position: relative; /* Add this */
}

.title {
  color: black;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center
}

.whotalk {
  min-height: 88px;
  outline: none;
  max-height: 25px;
  padding: 5px;
  width: 80% !important;
  border-radius: 8px;
  border: none;
  //border-right: black 1px solid; width: 10%;
}

.talk_input {
  width: 100%;
  height: 30px;
  margin: 10px auto 0;
}


.news1 {
  background-color: white;
  height: 65vh;
  .denyForm {
    overflow-y: hidden;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    background-color: white;
    width: 320px !important;
    height: 350px;
    border-radius: 15px;
    position: absolute;
    padding-bottom: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    ::v-deep .el-input{
      font-size: 14px;
    }
    .questioninput {
      //overflow: auto; /* 添加滚动条 */
      max-height: 8vh;
      width: 40VW;
      height: auto;
      border-radius: 5px;
      padding: 5px;
      margin-left: 5px;
      font-size: 14px;
      border: black 2px solid;

      .el-textarea__inner {
        height: 5vh;
      }
    }

    .ansinput {
      width: 40VW;
      margin-left: 6px;
      height: auto;
      border-radius: 5px;
      padding: 5px;
      min-height: 5vh;
      overflow: auto; /* 添加滚动条 */
    }

  }

  .talk_sub {
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 3.5vh;
    float: left;
    margin-top: -5vh;
    bottom: 0px;
    padding: 8px;
    margin-left: 81%;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-image: url("../../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #bd0022;
  }

  .content {
    width: 100%;
    margin: 1% auto;
    padding: 2% 2% 1% 2%;
    max-height:65vh;
    min-height: 65vh;
    border-radius:0;
    background: #f7f7f9;
  }

  .communicate {
    border-radius:0;
    width: 100%;
    position: absolute;
    min-height: 65vh;
    max-height: 65vh;
    padding-bottom: 5vh;
    background: #f7f7f9;
    font-size: 14px;
    overflow-y: auto;
    //border: 5px solid black;
  }

  .atalk {
    margin: 2px;
    padding: 5px;
    display: flex;
    background-color: white;
    position: relative;
    align-items: flex-start; /* Align items at the top */
  }

  .btalk {
    margin: 2px;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 8;
  }


  .AIphoto {
    min-width: 25px;
    background-image: url("../../assets/AIphoto.png");
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .talk {
    display: inline-block;
    background-color: rgba(255, 255, 255);
    color: #000;
    margin-right: 0;
    //border: #2c3e50 1px solid; width: 100%; padding: 5px 10px; word-wrap: break-word; position: relative; /* Add this */
  }

  .userPhoto {
    //background-image: url("../../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin-top: 5px;

  }

  .arrow-content {
    padding: 10px;
    margin-left: 1%;
    font-size: 14px;
    position: relative;
    display: inline-block;
    text-align: left;
    color: #000;
    font-weight: bold
  }

  .con_que {
    color: #1c88ff;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
  }

  .czkj-question-msg {
    float: left;
    font-size: 14px;
    color: #3163c5;
  }

  .deny {
    border: none;
    background-color: #f7f7f9;
    margin-left: auto;
    position: absolute;
    display: block;
    cursor: pointer;
    background-image: url("../../assets/deny.png");
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -15px;
    right: 10px;
    background-position: center center;
  }

  .denyReally {
    border: none;
    background-color: #f7f7f9;
    margin-left: auto;
    position: absolute;
    display: block;
    cursor: pointer;
    background-image: url("../../assets/denyReally.png");
    width: 15px;
    height: 15px;
    margin-top: auto;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -15px;
    right: 10px;
    background-position: center center;
  }

  .talk_input {
    position: absolute;
    width: 100%;
    height: 10vh;
    bottom:110px;
  }

  .talk_word {
    min-height: 150px;
    outline: none;
    width: 80%;
    max-height: 30px;
    margin: 10px auto;
    text-indent: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 0 2px #8B0000;
    border: rgb(190, 0, 34) 3px solid;
    background-color: white; /* 半透明白色，alpha值为0.5 */
  }
  .notify-success{
    top: 1.7rem !important;
    right: 0.32rem !important;
    width: 150px !important;
    height: 0.96rem !important;
    background: rgba(131, 213, 134, 0.1) !important;
    border-radius: 0.04rem 0px 0px 0.04rem !important;
  }

}
</style>
<style type="text/scss" lang="scss" scoped>


</style>
