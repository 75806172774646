<template>
  <div style="overflow-x: hidden">
    <div v-if="isMobile === false">
      <div class="MySubscriptions wrapper">
        <keep-alive>
          <Header></Header>
        </keep-alive>
        <div class="top">
          <div class="detail">
            <div style="display: flex">
              <div class="positionName">
                {{position.positionName}}
              </div>
              <div class="positionSalary">
                {{position.positionSalary}}
              </div>
            </div>

            <div class="positionAddress">
              <div>
                <i  class="el-icon-location"></i>
                <span class="name">{{position.positionAddress}}</span>
              </div>
              <div style="margin-left: 15px">
                <i  class="el-icon-s-cooperation"></i>
                <span class="name">{{position.positionTime}}</span>
              </div>
              <div style="margin-left: 15px">
                <i  class="el-icon-s-management"></i>
                <span class="name">{{position.positionEducation}}</span>
              </div>
              <div style="margin-left: 15px" v-if="position.positionOverseas==='0'">
                <i  class="el-icon-s-marketing"></i>
                <span class="name">海外工作经历</span>
              </div>

            </div>
            <div class="positionAddress">
              <div style="display: flex" v-for="item in position.positionWelfare" :key="item.id">
                <div class="welfare">{{ item }}</div>
              </div>
              <!--            <div class="welfare"> {{position.positionWelfare[0]}}</div>-->
              <!--            <li v-for="index in position.positionWelfare.length" :key="index" style="list-style-type: none">-->
              <!--              <div class="welfare"> {{position.positionWelfare[index]}}</div>-->
              <!--          </li>-->

            </div>
            <div class="buttonStyle">
              <el-button v-if="position.positionCollect==='0'" icon="el-icon-star-off" @click="collectPosition()" class="collect">收藏职位</el-button>
              <el-button v-if="position.positionCollect==='1'" icon="el-icon-star-on" @click="collectPosition()" class="collect">已收藏</el-button>
              <el-button v-if="position.deliverStatus==='0'" class="push" @click="collectPass()">职位投递</el-button>
              <el-button v-if="position.deliverStatus==='1'" class="push" style="background-color: gray;border: none" @click="collectPass()">已投递</el-button>
            </div>

          </div>
        </div>
        <div class="description">
          <div >
            <div class="position" style="display: flex">

              <!--            头像-->
              <div style="margin-right: 15px">
                <el-avatar :size="55" :src="baseURL+hr.interviewAvatar"></el-avatar>
              </div>
              <!--            名称-->
              <div>
                <div style="display: flex">
                  <div style="margin:-1px 8px 0 0;vertical-align: middle;font-size: 16px;color: #07132b;line-height: 32px;">{{hr.interviewName}}</div>
                  <div class="identify" v-if="hr.interviewStatus==='0'">已认证</div>
                  <div class="identify" v-else >未认证</div>
                </div>
                <div style="margin:2px 8px 0 0;vertical-align: middle;font-size: 14px;color: #666;">
                  {{hr.interviewDuties}}
                </div>
              </div>
            </div>
            <div class="position" style="margin-top: 15px">
              <div class="introduce">岗位介绍</div>

              <div class="positionIntroduce" style="display: flex">


                <div style="display: flex" v-for="item in position.positionTag" :key="item.id">
                  <div class="welfare">{{ item }}</div>
                </div>
                <!--              <div class="welfare"> {{position.positionTag[0]}}</div>-->
                <!--              <li v-for="index in position.positionTag.length" :key="index" style="list-style-type: none">-->
                <!--                <div class="welfare"> {{position.positionTag[index]}}</div>-->
                <!--              </li>-->
              </div>

              <div style="display: flex">
                <div class="introduce1" >岗位描述</div>
                <div class="spanStyle"></div>
              </div>
              <div class="positionIntroduce">
                {{position.positionIntroduce}}
              </div>
              <div style="display: flex">
                <div class="introduce1" >岗位要求</div>
                <div class="spanStyle"></div>
              </div>
              <div class="positionIntroduce">
                {{position.positionAsk}}
              </div>
              <div style="display: flex">
                <div class="introduce1" >其他信息</div>
                <div class="spanStyle"></div>
              </div>
              <div class="positionIntroduce" v-if="position.positionLanguage!==''">
                语言要求： {{position.positionLanguage}}
              </div>
              <div class="positionIntroduce" v-if="position.positionLanguage!==''" style="margin-top: 0">
                所属部门： {{position.positionSection}}
              </div>
              <div class="positionIntroduce" v-else >
                所属部门： {{position.positionSection}}
              </div>
            </div>
            <div class="position" style="margin-top: 15px" id="enterprise-info">
              <div class="introduce" >公司信息</div>
              <div style="display: flex">
                <div class="introduce1" >公司介绍</div>
                <div class="spanStyle"></div>
              </div>
              <div class="positionIntroduce">
                {{company.companyIntroduce}}
              </div>
              <div style="display: flex">
                <div class="introduce1" >公司地址</div>
                <div class="spanStyle"></div>
              </div>
              <div class="positionIntroduce">


                <!--              <div class="contain">-->
                <!--                <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult">-->
                <!--                </el-amap-search-box>-->
                <!--                <el-amap ref="map" vid="amapDemo" :center="center" :zoom="zoom" class="amap-demo">-->
                <!--                  <el-amap-marker :position="center" key="100"></el-amap-marker>-->
                <!--                </el-amap>-->
                <!--                <div v-if="searchResult">-->
                <!--                  搜索:{{content}},详细地址为:{{searchResult.locationName}},经度:{{searchResult.longitude}},纬度:{{searchResult.latitude}}-->
                <!--                </div>-->
                <!--              </div>-->

                <div id="index"><baidu-map class="map" style="width: 100%; height: 280px" :center="center" :zoom="zoom" @ready="handler" />
                </div>
              </div>
            </div>
            <div class="guss">
              <div class="name">猜你喜欢
              </div>
              <div class="middle">
                <li v-for="(lists,index) in list" :key="index">
                  <div v-if="index<=2"  class="listTotle" @click="personal(lists)">
                    <div class="detail">
                      <div>
                        <div>
                      <span class="positionName">
                        {{ lists.positionName }}【{{ lists.positionAddress }}】</span
                      >
                          <span class="salaryName">
                        {{ lists.positionSalary }}</span
                          >
                        </div>
                        <div class="jobStyle">
                          <span class="jobStyle1">{{ lists.positionTime }}</span>
                          <span class="jobStyle2">{{lists.positionEducation }}</span>
                          <span class="jobStyle2">{{lists.positionCategory}}</span>
                        </div>
                      </div>

                      <div class="comButton">
                        <div>
                          <el-avatar :size="45" :src="baseURL +lists.interviewAvatar"></el-avatar>
                        </div>
                        <el-button class="companyBut"
                        >立即沟通</el-button
                        >
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="comLogo">
                        <img :src="baseURL + lists.companyAvatar" alt="" />
                      </div>
                      <div class="companyName">
                        <div class="name1">{{ lists.companyName }}</div>
                        <div class="spanthis" style="margin-right: 12px"></div>
                        <div style="display: flex" v-for="(item, index) in lists.companyIndustry" :key="item.id">
                          <div class="name3" >{{ item }}</div>
                          <div class="name3" style="margin:0;" v-if="index < lists.companyIndustry.length - 1">、</div>
                        </div>

                        <div class="name2">{{ lists.companyScale }}</div>
                      </div>
                    </div>
                  </div>

                </li>
              </div>

            </div>
          </div>

          <div>
            <div class="company" >
              <div class="back"></div>
              <div class="detail">
                <div class="companyDetail">
                  公司信息
                </div>
                <div class="companyLogo">
                  <div class="comLogo">
                    <img :src="baseURL + company.companyAvatar" alt="" />
                  </div>
                  <div class="comName">
                    {{company.companyName}}
                  </div>
                </div>
                <div class="comIntro">
                  <div style="display: flex;margin-top: 15px;font-size: 14px">
                    <i style="margin-top: 3px;margin-right: 5px" class="el-icon-suitcase-1"></i>
                    <div class="titleName">企业行业：</div>
                    <div class="titleDetail" style="width: 140px;line-height: 22px">
                   <span  v-for="(item, index) in company.companyIndustry" :key="item.id">
                     <span  style="color: #666666;width: fit-content">{{ item }}</span>
                     <span  v-if="index < company.companyIndustry.length - 1">、</span>
                   </span>
                    </div>
                  </div>
                  <div style="display: flex; margin-top: 15px; font-size: 14px; overflow-wrap: break-word;">
                    <i style="margin-top: 3px;margin-right: 5px" class="el-icon-school"></i>
                    <div class="titleName">融资阶段：</div>
                    <div class="titleDetail" style="width: 100px;color: #666666;overflow-wrap: break-word;">{{ company.companyFinancing }}</div>
                  </div>
                  <div style="display: flex;margin-top: 15px;font-size: 14px">
                    <i style="margin-top: 3px;margin-right: 5px" class="el-icon-user"></i>
                    <div class="titleName">企业规模：</div>
                    <div class="titleDetail" style="width: 100px;color: #666666;">{{ company.companyScale }}</div>
                  </div>

                </div>
                <div class="scan" style="margin:5px auto 15px auto">
                  <el-button @click="japOther" class="scanButton" style="margin:20px 20px 20px 20px;width: 180px;height: 36px;border-radius: 4px 4px 4px 4px;opacity: 1;border: 1px solid #BF0022;opacity: 1;font-size: 14px;font-weight: 400;color: #BF0022;line-height: 16px;">查看详情</el-button>
                </div>

              </div>
            </div>
            <div class="jianli" v-if="userId" >
              <PersonalLittle></PersonalLittle>
            </div>
            <div class="recommend2" v-else>
              <div @click="showLoginIn1()" class="advertisement">
                <div class="title">
                </div>
              </div>
              <div @click="showLoginIn()" class="advertisement2">
              </div>
            </div>
          </div>

        </div>

        <div>
          <div v-if="isMobile===false">
            <el-dialog
                title="立即沟通"
                :visible.sync="cancleVisible"
                width="680px"
                :before-close="handleClose"
                top="40vh"
            >
            <span>
              <div class="box1">
        <div style="display: flex;position:relative;" >
           <div style="display: flex;position:relative;">
          <div class="Photo">
              <img :src="positionData.interviewAvatar ? baseURL + positionData.interviewAvatar : avator" class="avatar" />
          </div>
          <div style="margin-left: 16px">
            <div class="userName">{{positionData.interviewName}}</div>
            <div style="display: flex;margin-top: 20px">
              <div class="iconStyle"><img loading="lazy" src="../../assets/sex.png"/></div>
              <div v-if="positionData.sex!==null">
                <div class="userSex" >{{ positionData.sex }}</div>
              </div>
              <div class="userSex" v-else>---</div>
              <div class="iconStyle"><img loading="lazy" src="../../assets/work.png"/></div>
              <div class="userSex" v-if="positionData.interviewDuties!==null">{{ positionData.interviewDuties}}</div>
              <div v-else class="userSex">---</div>
            </div>
            <div style="display: flex;margin-top: 21px">
              <div class="iconStyle"><i class="el-icon-mobile-phone"></i></div>
              <div class="userSex" v-if="positionData.interviewPhone!==null">{{ positionData.interviewPhone}}</div>
              <div v-else class="userSex">---</div>
              <div class="iconStyle"><i class="el-icon-message"></i></div>
              <div class="userSex" v-if="positionData.interviewEmail!==null">{{ positionData.interviewEmail }}</div>
              <div v-else class="userSex">---</div>
            </div>
          </div>

        </div>

        </div>


      </div>

            </span>
              <span slot="footer" class="dialog-footer">
    </span>
            </el-dialog>
          </div>
          <div v-if="isMobile===true" style="font-size: 14px" class="phone">
            <el-dialog
                title="温馨提示"
                :visible.sync="cancleVisible"
                width="75%"
                :before-close="handleClose"
                top="35vh"
            >
              <span>欢迎使用油气人才，注册登录后体验完整服务~</span>
              <span slot="footer" class="dialog-footer">
      <el-button>
        <router-link to="/login">登录</router-link>
      </el-button>
      <el-button type="primary">
        <router-link to="/register">注册</router-link>
      </el-button>
    </span>
            </el-dialog>

          </div>
        </div>
      </div>
      <Footer v-if="isMobile === false"></Footer>
      <BottomBanner v-if="isMobile === true"></BottomBanner>
      <LoginTip
          :dialogVisibleLogin="dialogVisibleLogin"
          @hideDialog="hideDialogin"
      ></LoginTip>
    </div>
    <div v-else class="mobile">
      <div class="MySubscriptions wrapper">
        <keep-alive>
          <Header></Header>
        </keep-alive>
        <!--      问答-->
        <div class="flexBox">
          <div class="searchBox" @click="search">
            <el-input
                class="searchContent"
                placeholder="请输入职位关键词进行搜索"
            ></el-input>
            <el-button class="searchTo">搜索 </el-button>
          </div>
        </div>
        <div class="top">
          <div class="detail">
            <div style="display: flex">
              <div class="positionName">
                {{position.positionName}}
              </div>
            </div>

            <div class="positionAddress">
              <div>
                <span class="name">{{position.positionAddress}}</span>
              </div>
              <div style="margin-left: 8px">
                <span class="name">{{position.positionTime}}</span>
              </div>
              <div style="margin-left: 8px" v-if="position.positionOverseas==='0'" >
                <span class="name" >{{position.positionEducation}}</span>
              </div>
              <div style="margin-left: 8px" v-else>
                <span style="border-right:none"  class="name">{{position.positionEducation}}</span>
              </div>
              <div style="margin-left: 8px" v-if="position.positionOverseas==='0'">
                <span class="name1">海外工作经历</span>
              </div>

            </div>
            <div style="display: flex">
              <div class="positionSalary">
                {{position.positionSalary}}
              </div>

            </div>
<!--            <div class="buttonStyle">-->
<!--              <el-button v-if="position.positionCollect==='0'" icon="el-icon-star-off" @click="collectPosition()" class="collect">收藏职位</el-button>-->
<!--              <el-button v-if="position.positionCollect==='1'" icon="el-icon-star-on" @click="collectPosition()" class="collect">已收藏</el-button>-->
<!--              <el-button v-if="position.deliverStatus==='0'" class="push" @click="collectPass()">职位投递</el-button>-->
<!--              <el-button v-if="position.deliverStatus==='1'" class="push" style="background-color: gray;border: none" @click="collectPass()">已投递</el-button>-->
<!--            </div>-->

          </div>
        </div>
        <div class="description">
          <div >
            <div class="position" style="display: flex">
              <!--            头像-->
              <div style="margin-top: 10px">
                <el-avatar :size="35" :src="baseURL+hr.interviewAvatar"></el-avatar>
              </div>
              <!--            名称-->
              <div>
                <div style="display: flex">
                  <div style="margin:0 8px;vertical-align: middle;font-size: 14px;color: #07132b;line-height: 32px;">{{hr.interviewName}}</div>
                  <div class="identify" v-if="hr.interviewStatus==='0'">已认证</div>
                  <div class="identify" v-else >未认证</div>
                </div>
                <div style="margin:4px 8px;vertical-align: middle;font-size: 12px;color: #666;">
                  {{hr.interviewDuties}}&nbsp; {{company.companyName}}
                </div>
              </div>
            </div>
            <div class="position" style="margin-top: 10px">
              <div class="introduce">岗位介绍</div>
              <div class="positionIntroduce" style="display: flex">
              <div style="display: flex;word-break: break-all" v-for="item in position.positionWelfare" :key="item.id">
                <div class="welfare">{{ item }}</div>
              </div>
              </div>

              <div class="positionIntroduce" style="display: flex">


                <div style="display: flex" v-for="item in position.positionTag" :key="item.id">
                  <div class="welfare">{{ item }}</div>
                </div>
                <!--              <div class="welfare"> {{position.positionTag[0]}}</div>-->
                <!--              <li v-for="index in position.positionTag.length" :key="index" style="list-style-type: none">-->
                <!--                <div class="welfare"> {{position.positionTag[index]}}</div>-->
                <!--              </li>-->
              </div>

              <div style="display: flex">
                <div class="introduce1" >岗位描述</div>
              </div>
              <div class="positionIntroduce">
                {{position.positionIntroduce}}
              </div>
              <div style="display: flex">
                <div class="introduce1" >岗位要求</div>
              </div>
              <div class="positionIntroduce">
                {{position.positionAsk}}
              </div>
              <div style="display: flex">
                <div class="introduce1" >其他信息</div>
              </div>
              <div class="positionIntroduce" v-if="position.positionLanguage!==''">
                语言要求： {{position.positionLanguage}}
              </div>
              <div class="positionIntroduce" v-if="position.positionLanguage!==''" style="margin-top: 0">
                所属部门： {{position.positionSection}}
              </div>
              <div class="positionIntroduce" v-else >
                所属部门： {{position.positionSection}}
              </div>
            </div>
            <div class="position" style="margin-top: 10px" id="enterprise-info">
              <div class="introduce" >公司信息</div>
              <div style="display: flex;margin-top: 10px">
                <!--            头像-->
                <div style="margin-top: 10px">
                  <el-avatar :size="35" :src="baseURL+company.companyAvatar"></el-avatar>
                </div>
                <!--            名称-->
                <div>
                  <div style="display: flex">
                    <div style="margin:0 8px;vertical-align: middle;font-size: 14px;color: #07132b;line-height: 32px;">{{company.companyName}}</div>
                  </div>
                  <div style="margin:4px 8px;vertical-align: middle;font-size: 12px;color: #666;">
                     <span  v-for="(item, index) in company.companyIndustry" :key="item.id">
                     <span  style="color: #666666;width: fit-content">{{ item }}</span>
                     <span  v-if="index < company.companyIndustry.length - 1">、</span>
                   </span>&nbsp;&nbsp;|&nbsp;&nbsp;{{company.companyScale}}&nbsp;&nbsp;|&nbsp;&nbsp;{{company.companyFinancing}}&nbsp;
                  </div>
                </div>
              </div>

            </div>
            <div class="position" style="margin-top: 10px" id="enterprise-info">
              <div class="introduce" >工作地点</div>
              <div style="display: flex;margin-top: 10px">
                <div class="introduce1" > <i style="color:#BF0022;margin-right: 5px;font-size: 16px" class="el-icon-location"></i>{{ company.companyAddress }}</div>
              </div>
            </div>
            <div class="guss">
              <div class="name">猜你喜欢
              </div>
              <div class="middle">
                <li v-for="(lists,index) in list" :key="index">
                  <div  class="listTotle" @click="personal(lists)">
                    <div class="detail">
                      <div>
                        <div>
                      <span class="positionName">
                        {{ lists.positionName }}</span
                      >
                          <span class="salaryName">
                        {{ lists.positionSalary }}</span
                          >
                        </div>
                        <div style="margin-top: 5px">
                      <span class="positionName" style="font-weight: 400;color: gray;font-size: 12px">
                       {{ lists.companyName }}</span
                      >
                        </div>
                        <div class="jobStyle">
                          <span class="jobStyle1">{{ lists.positionTime }}</span>
                          <span class="jobStyle2">{{lists.positionEducation }}</span>
                          <span class="jobStyle2">{{lists.positionCategory}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="comLogo">
                        <img :src="baseURL + lists.interviewAvatar" alt="" />
                      </div>
                      <div class="companyName">
                        <div class="name1">{{ lists.interviewName }} {{ lists.interviewDuties }} </div>

                      </div>
                    </div>
                  </div>

                </li>
              </div>
            </div>
          </div>


        </div>
        <div style="margin-bottom: 60px">
          <bottomMobile></bottomMobile>
        </div>
        <div class="fixed">
          <div style="width: 30%;text-align: center">
            <div v-if="position.positionCollect==='0'" @click="collectPosition()" class="collect"><div>
              <i style="font-size: 30px;margin-top: -5px;color: #666666" class="el-icon-star-off"></i>
            </div><div style="margin-top: 0px;font-size: 12px;color: #666666">收藏职位</div></div>
            <div v-if="position.positionCollect==='1'" @click="collectPosition()" class="collect"><div>
              <i style="font-size: 30px;margin-top: -5px;color: #666666" class="el-icon-star-on"></i>
            </div><div style="margin-top: 0px;font-size: 12px;color: #666666">已收藏</div></div>

          </div>
           <div style="margin-top: 5px;color: gray">|</div>

          <div style="width: 70%;text-align: center">
            <div v-if="position.deliverStatus==='0'" @click="collectPass()" class="push">
              <div style="border-radius:4px;font-size: 12px;color: white;padding:10px;background-color: #3163c5;margin:0 10px ">职位投递</div>
            </div>
            <div v-if="position.deliverStatus==='1'" @click="collectPass()" class="push">
              <div style="border-radius:4px;font-size: 12px;color: white;padding:10px;background-color: #7e7e7e;margin:0 10px ">已投递</div>
            </div>

          </div>
        </div>

      </div>
      <LoginTip
          :dialogVisibleLogin="dialogVisibleLogin"
          @hideDialog="hideDialogin"
      ></LoginTip>
    </div>
  </div>



</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import LoginTip from "@/components/LoginTip.vue";
import {getAuthorize} from "@/api/user";
import PersonalLittle from "@/components/PersonalLittle.vue";
import bottomMobile from "@/components/bottomMobile.vue";
export default {

  name: "",

  props: [],

  components: {bottomMobile, PersonalLittle, BottomBanner, Footer, Header,LoginTip},

  data() {

    return {
      center: "",
      zoom: 0,
      searchOption: {
        // 限制搜索城市的范围
        citylimit: false
      },
      content: '',
      inputValue: '',
      searchResult: {
        address: '',
        latitude: '',
        longitude: '',
        name: '',
        type: '',
        country: '',
        province: '',
        city: '',
        area: '',
        township: '',
        street: '',
        neighborhood: '',
        locationName: ''
      },
      dialogVisibleLogin:false,
      avator: require("@/assets/avator.png"),
      cancleVisible: false,
      isMobile: false,
      positionId:'',
      token: localStorage.getItem('access_token'),
      userId:  localStorage.getItem('userId'),
      userInfo:  localStorage.getItem('userInfo'),
      position:'',
      page: 1,
      size: 8,
      list:'',
      company:'',
      companyId:'',
      baseURL: this.$utils.baseURL,
      positionInterviewId:'',
      positionData:'',
      positionName:'',
      positionAddress:'',
      load:false,
      hr:"",
    }

  },
  created() {
    this.positionId = this.$route.query.positionId
    this.getPosition()
    // this.positionlist()
    // this.getAPI()
  },
  beforeRouteUpdate(to, from, next) {
    // window.location.reload()
    window.scrollTo(0, 0,
    ); // 每次路由变化时滚动到页面顶部
    next();
  },
  methods: {
    search(){
      this.$router.push("/recruit")
    },
    // 浏览新增
    scanList(){
      // console.log("liulan", this.positionName)
      // 创建一个Date对象，传入时间戳（注意JavaScript时间戳是毫秒级的）
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      var second=("0" + date.getSeconds()).slice(-2);
      var today=year + "-" + month + "-" + day + " " + hours + ":" + minutes+":" + second;
      this.$axios.post(`/system/brow`, {
        userId:this.userId,
        positionAddress:  this.positionAddress,
        positionName:   this.positionName,
        positionId: this.positionId,
        time:today
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            console.log(res.data);
          })
          .catch(error => {
            console.log(error);
          });
    },
    showLoginIn() {
      // this.loginIn = true;

      window.open("http://54.222.201.29:18888/login", "_blank");
    },
    showLoginIn1() {
      // this.$router.push("/login")
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
    },
    hideDialogin() {
      this.dialogVisibleLogin = false;
    },
    handler({ BMap, map }) {
      console.log(BMap, map)
      this.center = this.company.positionAddress;

      // 使用百度地图的地址解析服务获取位置的经纬度
      const geoc = new BMap.Geocoder();
      geoc.getPoint(this.center, (point) => {
        if (point) {
          // 添加标注并设置自定义图标
          const marker = new BMap.Marker(point);
          map.addOverlay(marker);

          // 将地图中心点设置为标注位置，并调整缩放级别
          map.centerAndZoom(point, 25); // 调整缩放级别为 16
        } else {
          console.error("Failed to get coordinates for the location:", this.center);
        }
      }, "北京市");

    },
    japOther() {
      // 使用 JavaScript 滚动到目标位置
      const targetElement = document.getElementById('enterprise-info');

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth', // 可以选择平滑滚动
        });
      }
    },
      handleClose()
    {
      this.cancleVisible = false;
    },
    hide() {
      this.cancleVisible = false;
    },
    hideDialog(){
      this.cancleVisible = false;
    },
    // 详情
    personal(list){
      this.positionId=list.positionId
      const routeInfo = this.$router.resolve({
        path: '/positionDetail',
        query: { positionId: list.positionId },
      });
      window.open(routeInfo.href, '_blank');


    },
    getPosition() {
      this.$axios.get(`/system/position/detail/`+this.positionId, {
        params: {
          userId: this.userId,
          positionId: this.positionId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then(async (res) => {

            this.position = res.data.data;
            this.positionlist()
            this.companyId = res.data.data.companyId;
            this.positionInterviewId = res.data.data.positionInterviewId;

            this.position.positionWelfare = JSON.parse(this.position.positionWelfare)

            var strpositionTag = this.position.positionTag
            this.position.positionTag = strpositionTag.split(",");
            this.positionName = this.position.positionName
            this.positionAddress = this.position.positionAddress

            this.position.positionTime = await this.getTime(this.position.positionTime);
            this.position.positionEducation = await this.getEducation(this.position.positionEducation);
            // this.position.positionCategory = await this.getCategory(this.position.positionCategory);
            this.scanList()
            this.getInterview()
            this.getCompany();



          })
          .catch(error => {
            console.log(error);
          });

    },
    getTime(item) {
      return this.$axios
          .get("/system/time/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getEducation(item) {
      return this.$axios
          .get("/system/education/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCategory(item) {
      return this.$axios
          .get("/system/position_category/" + item, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            // console.log(res.data.data.content);
            return res.data.data.content;
          })
          .catch((error) => {
            console.log(error);
            throw error; // 抛出错误，这将导致Promise被reject
          });
    },
    getCompany() {

      this.$axios.get("/system/company/" + this.companyId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            this.company=res.data.data
             this.company.companyIndustry=JSON.parse(this.company.companyIndustry)

          }).catch(error => {
        console.log(error)
      })
    },
    getInterview(){

      this.$axios.get("/system/interview/" + this.positionInterviewId, {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      })
          .then((res) => {
            this.hr=res.data.data
            if(this.hr.sex==='0'){
              this.hr.interviewName=this.hr.interviewName[0]+"女士"
            }else{
              this.hr.interviewName=this.hr.interviewName[0]+"先生"
            }
            // console.log("页面里的")
            // console.log(this.hr)

          }).catch(error => {
        console.log(error)
      })
    },
    positionlist() {
      console.log(typeof (this.position.positionId))
      this.$axios
          .get("/system/position/recommend_list", {
            params: {
              pageSize:this.size,
              pageNum: this.page,
              positionId: this.position.positionId,
              positionName: this.position.positionName,
              positionIndustry: this.position.positionIndustry,
              positionStatus:0,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then(async (res) => {
            // console.log(res.data)
            this.list = res.data.rows;
            for (var i = 0; i < this.list.length; i++) {
              // console.log(this.list[i].companyIndustry);
              this.list[i].companyIndustry = JSON.parse(this.list[i].companyIndustry)

              if (this.list[i].positionTime !== null) {
                this.list[i].positionTime = await this.getTime(this.list[i].positionTime);
              }
              if (this.list[i].positionEducation !== null) {
                this.list[i].positionEducation = await this.getEducation(this.list[i].positionEducation);
              }
              if (this.list[i].positionCategory !== null) {
                this.list[i].positionCategory = await this.getCategory(this.list[i].positionCategory);
              }

              // console.log(this.list[i].companyIndustry[0]);
              if (this.list[i].sex !== null) {
                if (this.list[i].sex === '0') {
                  this.list[i].interviewName = this.list[i].interviewName[0] + "女士"
                } else {
                  this.list[i].interviewName = this.list[i].interviewName[0] + "先生"
                }
              }


            }

          })
          .catch((error) => {
            console.log(error);
          });




    },
  //   收藏
    collectPosition(){
      // console.log("shoucang",this.userId===null||this.userId===undefined)
      // 登录
      if(this.userId===null||this.userId===undefined){
        console.log("555")
        this.dialogVisibleLogin=true
      }
    //   未登录

      else{
        if(this.position.positionCollect==="0"){
          this.$axios.post(`/system/position_collect`, {
            userId:this.userId,
            positionAddress:  this.positionAddress,
            positionName:   this.positionName,
            positionId: this.positionId,
          }, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
              .then((res) => {
                console.log(res.data);
                this.getPosition()
                this.$forceUpdate();
              })
              .catch(error => {
                console.log(error);
              });
        }
        else{
          this.$axios.delete(`/system/position_collect/${this.positionId}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
            params: {
              userId: this.userId,
              positionId: this.positionId,
            },
          })
              .then((res) => {
                console.log(res.data);
                this.getPosition();
                this.$forceUpdate();
              })
              .catch(error => {
                console.log(error);
              });

        }

      }





    },

    collectPush(){
      console.log(parseInt(this.userId))
      if(this.position.positionCollect==="0"){
        this.$axios.post(`/system/position_collect`, {
          userId:this.userId,
          positionId: this.positionId,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
            .then((res) => {
              console.log(res.data);
              this.getPosition()
              this.$forceUpdate();
            })
            .catch(error => {
              console.log(error);
            });
      }
      else{
        this.$axios.delete(`/system/position_collect/${this.positionId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
          params: {
            userId: this.userId,
            positionId: this.positionId,
          },
        })
            .then((res) => {
              console.log(res.data);

              this.getPosition();
              this.$forceUpdate();
            })
            .catch(error => {
              console.log(error);
            });

      }

    },
  //   一键沟通
    collectPass(){

      if(this.userId===null||this.userId===undefined){
        console.log("555")
        this.dialogVisibleLogin=true
      }
      else{
        this.cancleVisible = true;
        this.$axios.get(`/system/interview/`+parseInt(this.positionInterviewId), {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
            .then((res) => {
              // console.log("弹框里的")
              // console.log(res.data.data)
              this.positionData=res.data.data
              if(this.hr.sex==='0'){
                this.positionData.interviewName=this.positionData.interviewName[0]+"女士"
              }else{
                this.positionData.interviewName=this.positionData.interviewName[0]+"先生"
              }
            })
            .catch(error => {
              console.log(error);
            });
        var userName=JSON.parse(this.userInfo).userName
        this.$axios.post(`/system/deliveryStatus`, {
          positionId:  this.positionId,
          userId: this.userId,
          userName:userName,
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
            .then((res) => {
              console.log(res.data);
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.getPosition();
              this.$forceUpdate();
            })
            .catch(error => {
              console.log(error);
            });

      }
      }


  },

  mounted() {
    window.scrollTo(0, 0); // 在页面加载时滚动到页面顶部
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;

  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.amap-demo {
  width: 100%;
  height: 600px;
  position: relative;
}

.search-box {
  height: 35px;
  margin: 10px auto;
  width: calc(100% - 20px);
  // border-radius:16px;
  box-shadow: none;
  background: #ffff;
  border: 1px solid #e6e6e6;

  .search-box-wrapper {
    input {
      background: #fff;
      padding-left: 20px;
    }

    .search-btn {
      color: #2A67FE;
      width: 90px;
      height: 20px;
      box-sizing: border-box;
      border-left: 1px solid #D7D7D7;
    }
  }
}

::v-deep .BMap_Marker img {
  width: 40px; /* 修改图标宽度 */
  height: 25px; /* 修改图标高度 */
}

.top {
  width: 100vw;
  height: fit-content;
  background: linear-gradient(to right, rgb(252, 230, 234) 0%, rgba(255, 255, 255, 0) 100%);
  //box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.05);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .detail {
    margin: 34px auto 44px auto;
    width: 1200px;
    position: relative;

    .positionName {
      height: 28px;
      font-size: 24px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }

    .positionSalary {
      height: 28px;
      margin-left: 24px;
      font-size: 24px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #BF0022;
      line-height: 28px;
    }

    .positionAddress {
      display: flex;
      margin-top: 32px;
      height: 16px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #666666;
      line-height: 16px;

      .welfare {
        height: fit-content;
        margin-right: 12px;
        padding: 0 8px;
        color: #999;
        font-size: 14px;
        line-height: 26px;
        background: #f8f9fb;
        border-radius: 6px;
      }

      .name {
        margin-left: 9px;
        margin-top: -1px;
      }

    }

    .buttonStyle {
      position: absolute;
      bottom: 40px;
      right: 5px;

      .collect {
        width: fit-content;
        height: fit-content;
        border-radius: 50px;
        opacity: 1;
        border: 1px solid #BF0022;
        padding: 15px 35px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 16px;
      }

      .push {
        width: fit-content;
        padding: 15px 58px;
        height: fit-content;
        background: #BF0022;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 16px;
        opacity: 1;
      }

    }
  }

}

.box1 {
  height: fit-content;
  padding: 24px 30px 26px 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;

  .cancleStyle {
    margin-top: 46px;
    width: 128px;
    height: 44px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #BF0022;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
     font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #BF0022;
    line-height: 20px;
    -webkit-background-clip: text;
  }

  .confirmStyle {

    margin-top: 46px;
    margin-left: 20px;
    cursor: pointer;
    border: none;
    width: 128px;
    height: 44px;
    background-color: #BF0022;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
     font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
    position: relative;
  }

  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 15px;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-input__suffix {
    height: 100%;
    left: 240px;
    transition: all .3s;
    pointer-events: none;
  }

  .information {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 48px;
    row-gap: 20px;
    padding-top: 32px;

    .title {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
      -webkit-background-clip: text;
    }

    .titleInput {
      margin-top: 12px;

      .sexStyle1 {
        width: 145px;
        height: 44px;
        background: #F8E9ECFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #BF0022;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .sexStyle2 {
        width: 145px;
        height: 44px;
        background: #FDFDFDFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: none;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: gray;
        cursor: pointer;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .editStyle {
    position: absolute;
    top: 0;
    display: flex;
    right: 0;
    height: 12px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    cursor: pointer;

    .editLogo {
      width: 13px;
      height: 12px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .editTitle {
      width: 28px;
      height: 16px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 16px;
      margin-left: 7px;
    }
  }

  .Photo {
    width: 80px;
    height: 80px;
    border-radius: 45%;
    opacity: 1;
  }

  .userName {
    height: 24px;
    font-size: 20px;
     font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #333333;
    line-height: 24px;

  }

  .userName1 {
    margin-top: 9px;
    width: 80px;
    height: 24px;
    font-size: 20px;
     font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #BF0022;
    line-height: 24px;
    -webkit-background-clip: text;

  }

  .userName2 {
    margin-top: 14px;
    width: 720px;
    color: gray;
    height: 24px;
    opacity: 1;

  }

  .userSex {
    height: 16px;
    font-size: 16px;
     font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-left: 6px;
    margin-right: 32px;
  }

  .iconStyle {
    width: 14px;
    height: 15px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
}

.description {
  margin: 15px auto 30px auto;
  width: 1200px;
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1.6fr) minmax(0, 0.4fr);

  column-gap: 25px;

  .position {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding: 18px 30px;

    .identify {
      margin: -4px auto auto 4px;
      padding: 2px 8px;
      height: fit-content;
      background-color: #3163c5;
      color: white;
      font-size: 12px;
      border-top-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    .introduce {
      width: 72px;
      height: 20px;
      font-size: 18px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }

    .welfare {
      margin-right: 12px;
      padding: 0 8px;
      color: #999;
      font-size: 14px;
      line-height: 26px;
      background: #f8f9fb;
      border-radius: 6px;
    }

    .introduce1 {
      margin-top: 24px;
      height: 16px;
      font-size: 14px;
       font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #050505;
      line-height: 16px;
    }

    .positionIntroduce {
      margin-top: 24px;
      width: auto;
      height: fit-content;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
    }

    .positionIntroduce1 {
      margin-top: 24px;
      width: auto;
      height: auto;
      background-color: rgba(102, 102, 102, 0.1);
      padding: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      display: grid;
      //grid-template-columns:repeat(3,1fr);
      grid-template-columns:minmax(0, 1.5fr)minmax(0, 0.8fr)minmax(0, 1fr);
      column-gap: 5px;
      row-gap: 10px;
    }

    .spanStyle {
      margin-top: 32px;
      margin-left: 12px;
      width: 90%;
      height: 1px;
      background: #F5F5F5;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  ;
  }

  .guss {
    margin-top: 15px;

    .name {
      width: 72px;
      height: 20px;
      font-size: 18px;
      font-family: 'PingFang SC', 'PingFang SC';
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }

    .middle {
      overflow: hidden;
      width: 100%;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      list-style: none;

      .pagination-container {
        position: sticky;
        margin-top: 20px;
        padding: 10px;
        bottom: 0;
        width: 99%;
        text-align: center;
        background-color: #fff;
      }

      .listTotle {
        margin-top: 15px;
        width: 100%;
        height: fit-content;
        background: #ffffff;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;

        .detail {
          padding: 20px 20px 5px 20px;
          display: flex;
          cursor: pointer;
          position: relative;

          .positionName {
            width: 64px;
            height: 16px;
            font-size: 16px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 600;
            color: #333333;
            line-height: 16px;
          }

          .positionName:hover {
            color: #bf0022;
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }

          .salaryName {
            margin-left: 12px;
            width: 41px;
            height: 16px;
            font-size: 16px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 700;
            color: #bf0022;
            line-height: 16px;
          }

          .jobStyle {
            display: flex;
            margin-top: 16px;
            width: 18vw;

            .jobStyle1 {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              border-radius: 6px;
              background-color: #F2F4F7;
              padding: 3px 10px;
            }

            .jobStyle1:hover {
              color: #bf0022;
              transform: scale(1.2);
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
            }

            .jobStyle2 {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              background-color: #F2F4F7;
              border-radius: 6px;
              padding: 3px 10px;
              margin-left: 12px;
            }

            .jobStyle2:hover {
              color: #bf0022;
              transform: scale(1.2);
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
            }
          }


          .comButton {
            position: absolute;
            right: 45px;
            top: 25px;
            text-align: center;

            .companyBut {
              margin-top: 10px;
              width: 92px;
              height: 40px;
              font-size: 14px;
              font-weight: 400;
              border: #416dfd 1px solid;
              background: #fdfdfd;
              color: #416dfd;
              border-radius: 23px;
              opacity: 1;
            }

            .companyBut:hover {
              /* 此处是鼠标悬浮时的样式 */
              color: white;
              background: #416dfd;

              /* 你可以添加其他的样式以突出悬浮状态 */
            }

            ::v-deep .el-button {
              padding: 5px 10px;
            }
          }
        }

        .bottom {
          padding: 18px 20px;
          width: 100%;
          height: fit-content;
          opacity: 1;
          display: flex;
          cursor: pointer;

          .comLogo {
            width: 22px;
            height: 22px;
            border: rgba(100, 98, 98, 0.1) 1px solid;
            border-radius: 4px;
            opacity: 1;
          }

          .comLogo:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          }

          .companyName {
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            margin-left: 8px;
            display: flex;

            .spanthis {
              width: 1px;
              margin: 3px 0 auto 16px;
              height: 16px;
              background-color: #e0dddd;
            }

            .name1 {
              width: fit-content;
              height: 22px;
              font-size: 14px;
               font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color: #050505;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .name1:hover {
              color: #bf0022;
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
            }

            .name2 {
              height: 22px;
              margin-left: 12px;
              font-size: 13px;
               font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #666666;
              line-height: 22px;
            }

            .name2:hover {
              color: #bf0022;
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
            }

            .name3 {
              height: 22px;
              font-size: 13px;
               font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #666666;
              line-height: 22px;
            }

            .name3:hover {
              color: #bf0022;
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
            }
          }

        }
      }

      .listTotle:hover {
        box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
      }
    }
  }

  .company {
    right: 10px;
    width: 260px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    position: relative;

    .back {
      width: 260px;
      height: 76px;
      //background: linear-gradient(180deg, #F3002B 0%, rgba(255,255,255,0) 95%);
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px 4px 4px 4px;
      opacity: 0.1;
    }

    .detail {
      margin: -50px auto 15px 20px;

      .companyDetail {
        width: 64px;
        height: 16px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 600;
        color: #333333;
        line-height: 16px;
      }

      .companyLogo {
        margin-top: 24px;
        display: flex;

        .comLogo {
          width: 55px;
          height: 55px;
          border-radius: 9px;
          opacity: 1;
          border: 1px solid rgba(102, 102, 102, 0.2);
        }

        .comLogo:hover {
          transform: scale(1.2);
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */

        }

        .comName {
          margin: auto auto auto 10px;
          width: 140px;
          height: 55px;
          display: flex;
          align-items: center;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }

        .comIntro {
          margin-top: 16px;

        }

        .title {
          margin-top: 8px;
          display: flex;

        }

        .titleName {
          width: fit-content;
          height: 16px;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
          line-height: 16px;
        }

        .titleDetail {

          width: 100px;
          height: fit-content;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }


    }
  }

  .jianli {
    margin: 20px 0 0 -10px;
    width: 100%;
    height: 267px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }

  .advertisement {

    margin: 0 0 0 -10px;
    width: 260px;
    height: 170px;
    border-radius: 5px;
    cursor: pointer;
    background-image: url("../../assets/login.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: black 10px solid;
    .logo {
      opacity: 12;
      width: 50%;
      height: 100%;
      //background-image: url("../../assets/login.png");
      //background-size: contain;
      //background-position: center;
      //background-repeat: no-repeat;
      //background-color: transparent; /* 这里设置背景颜色为透明 */
    }

    .title {
      line-height: 30px;
      color: white;
      text-align: right;

      top: 15px;
      position: absolute;
      right: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }

  .advertisement2 {
    margin: 15px 0 0 -10px;
    right: 0;
    width: 260px;
    height: 170px;
    border-radius: 5px;
    //background-color: #30539d;
    cursor: pointer;
    background-image: url("../../assets/login2.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: black 10px solid;

    .title {
      line-height: 30px;
      color: white;
      text-align: right;

      top: 15px;
      position: absolute;
      right: 15px;
      font-weight: bold;
      font-size: 22px;
    }
  }

}

.phone {
  position: fixed;
  z-index: 9999999999;

  ::v-deep {
    .el-dialog {
      margin: auto !important;
      font-size: 14px;

      top: 35vh;
    }

    .el-dialog__header {
      padding: 5px;
      border-bottom: 1px solid #ebebeb;

      .el-dialog__title {
        font-weight: bold;
        padding: 15px;
        font-size: 18px;

      }

    }

    .el-dialog__body {
      padding: 10px 15px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }

    .el-button {
      width: 105px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      padding: 0;

      a {
        display: block;
      }
    }

  }

}

::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;

    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;

    a {
      display: block;
    }
  }

  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }

  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }

  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);
  }
}
  .mobile{

    .flexBox {
      border-radius: 4px;
      width: 100%;
      font-size: 14px;
      background: #ffffff;
      opacity: 1;
      height: 50px;
      padding: 10px 95px 10px 95px;


      .spanStyle {
        width: 1px;
        font-size: 16px;
        height: 1.48vh;
        background: #d9d9d9;
      }

      .el-dropdown {
        width: 20.63vw;
      }

      .el-dropdown-link {
        margin-left: 0.7vw;
        cursor: pointer;
        color: #333333;
      }

      .el-icon-arrow-down {
        font-size: 16px;
      }

      .searchBox {
        border: 1px solid rgb(225, 227, 232);
        width:45%;
        position: absolute;
        right:100px;
        left:80px;
        margin:3px auto 0 auto;
        height: 28px;
        border-radius: 53px;
        background-color: rgb(225, 227, 232);
        display: flex;
        align-items: center;
        justify-content: center;
        //border: 1px solid var(--main-color);
        ::v-deep .el-button {
          padding: 5px  !important;
        }

        .searchContent {
          border-radius: 0;
          height: 28px;

          ::v-deep .el-input__inner {
            height: 100%;
            font-size: 12px;
            border-radius: 53px;
            background-color: rgb(225, 227, 232);
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }

        .searchTo {
          width: 55px;
          height:28px;
          justify-content: center;
          align-items: center;
          display: flex;
          color: rgb(84, 136, 245);
          font-size: 12px;
          border: none;
          background-color: rgb(225, 227, 232);
          border-radius: 53px;

          img {
            width: 1.1vw;
            height: 1.9vh;
          }
        }
      }

      .scanEr {
        position: absolute;
        right: 25px;
        display: flex;
        background: #feffff;
      }
    }
    .amap-demo {
      width: 100%;
      height: 600px;
      position: relative;
    }

    .search-box {
      height: 35px;
      margin: 10px auto;
      width: calc(100% - 20px);
      // border-radius:16px;
      box-shadow: none;
      background: #ffff;
      border: 1px solid #e6e6e6;

      .search-box-wrapper {
        input {
          background: #fff;
          padding-left: 20px;
        }

        .search-btn {
          color: #2A67FE;
          width: 90px;
          height: 20px;
          box-sizing: border-box;
          border-left: 1px solid #D7D7D7;
        }
      }
    }

    ::v-deep .BMap_Marker img {
      width: 40px; /* 修改图标宽度 */
      height: 25px; /* 修改图标高度 */
    }

    .top {
      width: 100%;
      height: fit-content;
      padding: 10px 18px ;
      background: linear-gradient(to right, rgb(252, 230, 234) 0%, rgba(255, 255, 255, 0) 100%);
      //box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.05);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: relative;

      .detail {
        margin: 0 auto 0 auto;
        width: 100%;

        .positionName {
          height: 28px;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }

        .positionSalary {
          height: 28px;
          margin:8px 0 0 0;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #BF0022;
          line-height: 28px;
        }

        .positionAddress {
          display: flex;
          margin-top: 8px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 16px;

          .welfare {
            height: fit-content;
            margin-right: 12px;
            padding: 0 8px;
            color: #999;
            font-size: 14px;
            line-height: 22px;
            background: #f8f9fb;
            border-radius: 6px;
          }

          .name {
            border-right: 1px solid #b6b4b4;
            margin-left: 0;
            padding: 0 8px 0 0 ;
          }
          .name1 {
            margin-left: 0;
            padding: 0 8px 0 0 ;
          }

        }

        .buttonStyle {
          position: absolute;
          bottom: -25px;
          right: 5px;

          .collect {
            width: fit-content;
            height: fit-content;
            border-radius: 50px;
            opacity: 1;
            border: none;
            padding: 5px 10px;
            font-size: 14px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #BF0022;
            line-height: 16px;

          }

          .push {
            width: fit-content;
            padding: 5px 10px;
            height: fit-content;
            background: #BF0022;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            opacity: 1;
          }

        }
      }

    }

    .box1 {
      height: fit-content;
      padding: 24px 30px 26px 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;

      .cancleStyle {
        margin-top: 46px;
        width: 128px;
        height: 44px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #BF0022;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 20px;
        -webkit-background-clip: text;
      }

      .confirmStyle {

        margin-top: 46px;
        margin-left: 20px;
        cursor: pointer;
        border: none;
        width: 128px;
        height: 44px;
        background-color: #BF0022;
        border-radius: 4px 4px 4px 4px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
        position: relative;
      }

      ::v-deep .el-input--prefix .el-input__inner {
        padding-left: 15px;
      }

      ::v-deep .el-select {
        width: 100%;
      }

      ::v-deep .el-input__suffix {
        height: 100%;
        left: 240px;
        transition: all .3s;
        pointer-events: none;
      }

      .information {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 48px;
        row-gap: 20px;
        padding-top: 32px;

        .title {
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          line-height: 14px;
          -webkit-background-clip: text;
        }

        .titleInput {
          margin-top: 12px;

          .sexStyle1 {
            width: 145px;
            height: 44px;
            background: #F8E9ECFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #BF0022;
            font-size: 16px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #BF0022;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .sexStyle2 {
            width: 145px;
            height: 44px;
            background: #FDFDFDFF;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: none;
            font-size: 16px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: gray;
            cursor: pointer;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .editStyle {
        position: absolute;
        top: 0;
        display: flex;
        right: 0;
        height: 12px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        cursor: pointer;

        .editLogo {
          width: 13px;
          height: 12px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .editTitle {
          width: 28px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #BF0022;
          line-height: 16px;
          margin-left: 7px;
        }
      }

      .Photo {
        width: 80px;
        height: 80px;
        border-radius: 45%;
        opacity: 1;
      }

      .userName {
        height: 24px;
        font-size: 20px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #333333;
        line-height: 24px;

      }

      .userName1 {
        margin-top: 9px;
        width: 80px;
        height: 24px;
        font-size: 20px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #BF0022;
        line-height: 24px;
        -webkit-background-clip: text;

      }

      .userName2 {
        margin-top: 14px;
        width: 720px;
        color: gray;
        height: 24px;
        opacity: 1;

      }

      .userSex {
        height: 16px;
        font-size: 16px;
         font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        margin-left: 6px;
        margin-right: 32px;
      }

      .iconStyle {
        width: 14px;
        height: 15px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    .description {
      margin: 10px auto 0 auto;
      width: 100%;
      position: relative;
      display: block;
      //grid-template-columns: minmax(0, 1.6fr) minmax(0, 0.4fr);
      //
      //column-gap: 25px;

      .position {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        padding:15px 18px ;

        .identify {
          margin: -4px auto auto 4px;
          padding: 2px 8px;
          height: fit-content;
          background-color: #3163c5;
          color: white;
          font-size: 12px;
          border-top-left-radius: 9px;
          border-bottom-right-radius: 9px;
        }

        .introduce {
          width:fit-content;
          height: fit-content;
          font-size: 16px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 600;
          color: #333333;
          line-height: 20px;
          padding-bottom: 5px;
          border-bottom: #3163c5 3px solid;
        }

        .welfare {
          width: 100%;
          white-space:normal;;
          margin-right: 12px;
          padding: 0 8px;
          color: #999;
          font-size: 14px;
          line-height: 26px;
          background: #f8f9fb;
          border-radius: 6px;
        }

        .introduce1 {
          margin-top: 10px;
          height: 16px;
          font-size: 14px;
           font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          color: #666666;
          line-height: 16px;
        }

        .positionIntroduce {
          margin-top: 10px;
          width: auto;
          height: fit-content;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }

        .positionIntroduce1 {
          margin-top: 24px;
          width: auto;
          height: auto;
          background-color: rgba(102, 102, 102, 0.1);
          padding: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          display: grid;
          //grid-template-columns:repeat(3,1fr);
          grid-template-columns:minmax(0, 1.5fr)minmax(0, 0.8fr)minmax(0, 1fr);
          column-gap: 5px;
          row-gap: 10px;
        }

        .spanStyle {
          margin-top: 32px;
          margin-left: 12px;
          width: 90%;
          height: 1px;
          background: #F5F5F5;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      ;
      }

      .guss {
        margin-top:10px;
        padding: 15px 18px 0 18px;
        background-color: white;

        .name {
          width: 72px;
          height: 20px;
          font-size: 16px;
          font-family: 'PingFang SC', 'PingFang SC';
          font-weight: 600;
          color: #333333;
          line-height: 20px;
        }

        .middle {
          overflow: hidden;
          width: 100%;
          opacity: 1;
          list-style: none;
          background-color: white;

          .pagination-container {
            position: sticky;
            margin-top: 10px;
            bottom: 0;
            width:100%;
            text-align: center;
          }

          .listTotle {
            margin-top: 10px;
            width: 100%;
            height: fit-content;
            //background: #ffffff;
            box-shadow: none;
            opacity: 1;

            .detail {
              padding: 10px 0 0 0;
              display: flex;
              cursor: pointer;
              position: relative;

              .positionName {
                width: 64px;
                height: 16px;
                font-size: 14px;
                 font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 600;
                color: #333333;
                line-height: 16px;
              }

              .salaryName {
                position: absolute;
                right:15px;
                width: fit-content;
                height: 16px;
                font-size: 14px;
                 font-family: arial, sans-serif, "Microsoft Yahei";
                font-weight: 700;
                color: #bf0022;
                line-height: 16px;
              }

              .jobStyle {
                display: flex;
                margin-top: 10px;
                width: fit-content;

                .jobStyle1 {
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                  border-radius: 6px;
                  background-color: #F2F4F7;
                  padding: 3px 10px;
                }

                .jobStyle1:hover {
                  color: #bf0022;
                  transform: scale(1.2);
                  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
                }

                .jobStyle2 {
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                  background-color: #F2F4F7;
                  border-radius: 6px;
                  padding: 3px 10px;
                  margin-left: 12px;
                }

                .jobStyle2:hover {
                  color: #bf0022;
                  transform: scale(1.2);
                  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
                }
              }


              .comButton {
                position: absolute;
                right: 45px;
                top: 25px;
                text-align: center;

                .companyBut {
                  margin-top: 10px;
                  width: 92px;
                  height: 40px;
                  font-size: 14px;
                  font-weight: 400;
                  border: #416dfd 1px solid;
                  background: #fdfdfd;
                  color: #416dfd;
                  border-radius: 23px;
                  opacity: 1;
                }

                .companyBut:hover {
                  /* 此处是鼠标悬浮时的样式 */
                  color: white;
                  background: #416dfd;

                  /* 你可以添加其他的样式以突出悬浮状态 */
                }

                ::v-deep .el-button {
                  padding: 5px 10px;
                }
              }
            }

            .bottom {
              border-bottom: #F2F4F7 1px solid;
              padding:15px 0;
              width: 100%;
              height: fit-content;
              opacity: 1;
              display: flex;
              cursor: pointer;

              .comLogo {
                width: 30px;
                height: 30px;
                border: rgba(100, 98, 98, 0.1) 1px solid;
                border-radius: 50%;
                opacity: 1;
              }

              .companyName {

                opacity: 1;
                margin-left: 10px;
                display: flex;
                color:gray;
                justify-content: center;
                align-items: center;
                .name1{
                  font-size: 12px;
                }
              }

            }
          }

        }
      }

      .company {
        right: 10px;
        width: 260px;
        height: fit-content;
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        position: relative;

        .back {
          width: 260px;
          height: 76px;
          //background: linear-gradient(180deg, #F3002B 0%, rgba(255,255,255,0) 95%);
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px 4px 4px 4px;
          opacity: 0.1;
        }

        .detail {
          margin: -50px auto 15px 20px;

          .companyDetail {
            width: 64px;
            height: 16px;
            font-size: 16px;
             font-family: arial, sans-serif, "Microsoft Yahei";
            font-weight: 600;
            color: #333333;
            line-height: 16px;
          }

          .companyLogo {
            margin-top: 24px;
            display: flex;

            .comLogo {
              width: 55px;
              height: 55px;
              border-radius: 9px;
              opacity: 1;
              border: 1px solid rgba(102, 102, 102, 0.2);
            }

            .comLogo:hover {
              transform: scale(1.2);
              transition: transform 0.3s ease-in-out; /* 添加过渡效果 */

            }

            .comName {
              margin: auto auto auto 10px;
              width: 140px;
              height: 55px;
              display: flex;
              align-items: center;
              font-size: 14px;
               font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 500;
              color: #333333;
              line-height: 22px;
            }

            .comIntro {
              margin-top: 16px;

            }

            .title {
              margin-top: 8px;
              display: flex;

            }

            .titleName {
              width: fit-content;
              height: 16px;
              font-size: 12px;
              font-weight: 500;
              color: #333333;
              line-height: 16px;
            }

            .titleDetail {

              width: 100px;
              height: fit-content;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 16px;
            }
          }


        }
      }

      .jianli {
        margin: 20px 0 0 -10px;
        width: 100%;
        height: 267px;
        background: #ffffff;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
      }

      .advertisement {

        margin: 0 0 0 -10px;
        width: 260px;
        height: 170px;
        border-radius: 5px;
        cursor: pointer;
        background-image: url("../../assets/login.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        //border: black 10px solid;
        .logo {
          opacity: 12;
          width: 50%;
          height: 100%;
          //background-image: url("../../assets/login.png");
          //background-size: contain;
          //background-position: center;
          //background-repeat: no-repeat;
          //background-color: transparent; /* 这里设置背景颜色为透明 */
        }

        .title {
          line-height: 30px;
          color: white;
          text-align: right;

          top: 15px;
          position: absolute;
          right: 15px;
          font-weight: bold;
          font-size: 22px;
        }
      }

      .advertisement2 {
        margin: 15px 0 0 -10px;
        right: 0;
        width: 260px;
        height: 170px;
        border-radius: 5px;
        //background-color: #30539d;
        cursor: pointer;
        background-image: url("../../assets/login2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        //border: black 10px solid;

        .title {
          line-height: 30px;
          color: white;
          text-align: right;

          top: 15px;
          position: absolute;
          right: 15px;
          font-weight: bold;
          font-size: 22px;
        }
      }

    }

    .phone {
      position: fixed;
      z-index: 9999999999;

      ::v-deep {
        .el-dialog {
          margin: auto !important;
          font-size: 14px;

          top: 35vh;
        }

        .el-dialog__header {
          padding: 5px;
          border-bottom: 1px solid #ebebeb;

          .el-dialog__title {
            font-weight: bold;
            padding: 15px;
            font-size: 18px;

          }

        }

        .el-dialog__body {
          padding: 10px 15px;
          color: #606266;
          font-size: 14px;
          word-break: break-all;
        }

        .el-button {
          width: 105px;
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          border-radius: 5px;
          text-align: center;
          cursor: pointer;
          border: none;
          padding: 0;

          a {
            display: block;
          }
        }

      }

    }
    .fixed{
      position: fixed;
      bottom:0;
      display: flex;
      background-color: white;
      height:60px;
      padding: 10px;
      width: 100%;
    }
    ::v-deep {
      .el-dialog__header {
        border-bottom: 1px solid #ebebeb;

        .el-dialog__title {
          font-weight: bold;
          font-size: 16px;
        }
      }

      .el-button {
        width: 100%;
        height: 28px;
        line-height: 28px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        border: none;
        padding: 0;

        a {
          display: block;
        }
      }

      .el-button--primary {
        border: 1px solid var(--main-color);
        background: var(--main-color);
        color: #fff;
      }

      .el-button:focus,
      .el-button:hover {
        color: var(--main-color);
        border-color: var(--main-color);
        background-color: #fff;
      }

      .el-button--primary:focus,
      .el-button--primary:hover {
        background: var(--main-color);
        border-color: var(--main-color);
        color: #fff;
      }

      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: var(--main-color);
      }
    }
  }
</style>
